import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import * as dictsApi from './api';

const { name } = options;

export const getDatumList = createAsyncThunk(
  `${name}/getDatumList`,
  async (_, thunkApi) => {
    try {
      return await dictsApi.getDatumListApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getCylindricalProjectionList = createAsyncThunk(
  `${name}/getСylindricalProjectionList`,
  async (_, thunkApi) => {
    try {
      return await dictsApi.getCylindricalProjectionListApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getGeomagneticModelList = createAsyncThunk(
  `${name}/getGeomagneticModelList`,
  async (_, thunkApi) => {
    try {
      return await dictsApi.getGeomagneticModelListApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
