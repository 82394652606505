import React, { Component } from 'react';
import { IFunction } from 'interfaces';
import { withTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router';
import { InfoErrorBoundary } from './infoErrorBoundary';
import './style.scss';

interface IDecoratorsProps {
    navigate: NavigateFunction
}

interface IState {
    error: Error | null,
    errorInfo: { componentStack: string } | null
}

class ErrorBoundary extends Component<IDecoratorsProps, IState> {
  constructor(props: IDecoratorsProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error | null, errorInfo: { componentStack: string }) {
    this.setState({ error, errorInfo });
  }

    cn: IFunction = () => {};

    goToHomePage = () => {
      this.setState({ error: null, errorInfo: null });
      const { navigate } = this.props;
      navigate('/');
    };

    reloadPage = () => {
      this.setState({ error: null, errorInfo: null });
      const { navigate } = this.props;
      navigate(0);
    };

    render() {
      const { children } = this.props;
      const { errorInfo, error } = this.state;

      return (
        <>
          {errorInfo ? (
            <InfoErrorBoundary
              errorMessage={error?.message ?? ''}
              onHomeButtonClick={this.goToHomePage}
              onReloadButtonClick={this.reloadPage}
            />
          ) : children}
        </>
      );
    }
}

export default withTranslation('common')(ErrorBoundary);
