import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import * as stratApi from './api';
import { createStratUnitProp, IStratUnitBasic, IStratUnitReference } from './types';

const { name } = options;

export const getStratUnitsByWellUid = createAsyncThunk(
  `${name}/getStratUnitsByWellUid`,
  async (props: getStratUnitProp, thunkApi) => {
    try {
      return await stratApi.getStratUnitsByWellUidApi(
        props.wellUid, props.page, props.rows
      );
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteStratUnit = createAsyncThunk(
  `${name}/deleteStratUnit`,
  async (props: deleteStratUnitProp, thunkApi) => {
    try {
      return await stratApi.deleteStratUnitApi(
        props.wellUid, props.stratUnitUid
      );
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createStratUnit = createAsyncThunk(
  `${name}/createStratUnit`,
  async (props: createStratUnitProp, thunkApi) => {
    try {
      return await stratApi.createStratUnitApi(
        props.wellUid, props.stratUnit
      );
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const patchStratUnit = createAsyncThunk(
  `${name}/patchStratUnit`,
  async (props: patchStratUnitProp, thunkApi) => {
    try {
      return await stratApi.patchStratUnitApi(
        props.wellUid, props.stratUnitUid, props.stratUnit
      );
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getStratUnitReferences = createAsyncThunk(
  `${name}/getStratUnitReferences`,
  async (_, thunkApi) => {
    try {
      return await stratApi.getStratUnitReferencesApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getStratUnitReferenceByUid = createAsyncThunk(
  `${name}/getStratUnitReferenceByUid`,
  async (uid: string, thunkApi) => {
    try {
      return await stratApi.getStratUnitReferenceByUidApi(uid);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createStratUnitReference = createAsyncThunk(
  `${name}/createStratUnitReference`,
  async (stratUnitReference: IStratUnitReference, thunkApi) => {
    try {
      return await stratApi.createStratUnitReferenceApi(stratUnitReference);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteStratUnitReferenceByUid = createAsyncThunk(
  `${name}/deleteStratUnitReferenceByUid`,
  async (uid: string, thunkApi) => {
    try {
      return await stratApi.deleteStratUnitReferenceByUidApi(uid);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const patchStratUnitReferenceByUid = createAsyncThunk(
  `${name}/patchStratUnitReferenceByUid`,
  async (props: patchStratUnitReferenceProp, thunkApi) => {
    try {
      return await stratApi.patchStratUnitReferenceByUidApi(props.uid, props.stratUnitReference);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getStratUnitReferenceTypes = createAsyncThunk(
  `${name}/getStratUnitReferenceTypes`,
  async (_, thunkApi) => {
    try {
      return await stratApi.getStratUnitReferenceTypesApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getLithologyTypes = createAsyncThunk(
  `${name}/getLithologyTypes`,
  async (_, thunkApi) => {
    try {
      return await stratApi.getLithologyTypesApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

type getStratUnitProp = {
    wellUid: string
    page: number
    rows: number
};

type deleteStratUnitProp = {
    wellUid: string
    stratUnitUid: string
};

type patchStratUnitProp = {
    wellUid: string
    stratUnitUid: string
    stratUnit: IStratUnitBasic
};

type patchStratUnitReferenceProp = {
    uid: string
    stratUnitReference: IStratUnitReference
};
