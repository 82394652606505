import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { IBasicDictionary } from './types';
import { getCylindricalProjectionList, getDatumList, getGeomagneticModelList } from './actions';

const { name } = options;

type DictState = {
    isLoading: boolean
    datumList: IBasicDictionary[], // Система геодезических параметров
    cylindricalProjectionList: IBasicDictionary[], // Цилиндрическая проекция
    geomagneticModelList: IBasicDictionary[] // Геомагнитная модель
    error: string
};

const initialState: DictState = {
  isLoading: false,
  datumList: [],
  cylindricalProjectionList: [],
  geomagneticModelList: [],
  error: ''
};

export const dictSlice = createSlice({
  name,
  initialState,
  reducers: {
    cleaDictionaries(state) {
      state.datumList = [];
      state.cylindricalProjectionList = [];
      state.geomagneticModelList = [];
    }
  },
  extraReducers: {
    [getDatumList.pending.type]: (state) => {
      state.isLoading = true;
      state.datumList = [];
    },
    [getDatumList.fulfilled.type]: (state, { payload }: PayloadAction<IBasicDictionary[]>) => {
      state.isLoading = false;
      state.datumList = payload;
      state.error = '';
    },
    [getDatumList.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getCylindricalProjectionList.pending.type]: (state) => {
      state.isLoading = true;
      state.cylindricalProjectionList = [];
    },
    [getCylindricalProjectionList.fulfilled.type]: (state, { payload }: PayloadAction<IBasicDictionary[]>) => {
      state.isLoading = false;
      state.cylindricalProjectionList = payload;
      state.error = '';
    },
    [getCylindricalProjectionList.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getGeomagneticModelList.pending.type]: (state) => {
      state.isLoading = true;
      state.geomagneticModelList = [];
    },
    [getGeomagneticModelList.fulfilled.type]: (state, { payload }: PayloadAction<IBasicDictionary[]>) => {
      state.isLoading = false;
      state.geomagneticModelList = payload;
      state.error = '';
    },
    [getGeomagneticModelList.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export default dictSlice.reducer;
