import React from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'primereact/badge';

import { useClassName } from '../../../utils/cn';

type ProjectContentPropsType = {
    wellName: string
};

export const ProjectContent: React.FC<ProjectContentPropsType> = ({ wellName }) => {
  const cn = useClassName('header-notification');
  const { t } = useTranslation('common', { keyPrefix: 'header' });

  return (
    <>
      <div className={cn('content-title')}>
        <Badge severity="danger" className="mr-2" />
        <h4>
          {t('new-project')}
        </h4>
      </div>
      <div className={cn('content-body')}>
        {t('new-project-added')}
        {wellName}.
      </div>
    </>
  );
};
