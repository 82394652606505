import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import * as schedulerApi from './api';
import {
  ChangeInstallationStepResponseType,
  ChangeInstallationStepType,
  CreateWellCommentType,
  DrillingInfoType,
  EditPlaningWellType,
  GetInstallationInfoType,
  InstallationInfoType,
  IWorkType, PlaningWellType,
  SchedulerWellBackType
} from './types';
import { AreaFormType } from '../area/types';
import { FacilityFormType } from '../facility/types';
import { NotNullable } from '../../interfaces/utilityTypes';

const { name } = options;

type SchedulerSearchActionParamType = {
    area: AreaFormType[];
    facility: FacilityFormType[];
    scheduledEnd: Date;
    scheduledStart: Date;
    workTypeId: number[];
    size?: number;
    page?: number;
};

export const schedulerSearch = createAsyncThunk<SchedulerWellBackType[], SchedulerSearchActionParamType>(
  `${name}/schedulerSearch`,
  async (params: SchedulerSearchActionParamType, thunkApi) => {
    try {
      const res = await schedulerApi.schedulerSearchApi({
        area: params.area.map(area => ({ areaId: area.uid, areaType: area.areaType })),
        facility: params.facility.map(fac => ({ facilityId: fac.facilityId, facilityType: fac.facilityType })),
        page: params.page || 0,
        size: params.size || 100,
        workTypeId: params.workTypeId,
        scheduledStart: params.scheduledStart.getTime(),
        scheduledEnd: params.scheduledEnd.getTime()
      });
      return res.content;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getWorkTypes = createAsyncThunk<IWorkType[]>(
  `${name}/getWorkTypes`,
  async (_, thunkApi) => {
    try {
      return await schedulerApi.getWorkTypesApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getInstallationInfo = createAsyncThunk<InstallationInfoType[], GetInstallationInfoType>(
  `${name}/getInstallationInfo`,
  async (params, thunkApi) => {
    try {
      return await schedulerApi.getInstallationInfoApi(params);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getDrillingInfo = createAsyncThunk<DrillingInfoType, string>(
  `${name}/getDrillingInfo`,
  async (id, thunkApi) => {
    try {
      return await schedulerApi.getDrillingInfoApi(id);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const nextStepStage = createAsyncThunk<NotNullable<ChangeInstallationStepResponseType>, ChangeInstallationStepType>(
  `${name}/nextStepStage`,
  async (body, thunkApi) => {
    try {
      const res = await schedulerApi.nextStepStageApi(body);
      return {
        ...res,
        wellId: res.wellId || body.wellId,
        workStageTypeId: body.workStageTypeId
      };
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

// planing

export const planingSearch = createAsyncThunk<PlaningWellType[], Omit<SchedulerSearchActionParamType, 'workTypeId'>>(
  `${name}/planingSearch`,
  async (params, thunkApi) => {
    try {
      const res = await schedulerApi.planingSearchApi({
        area: params.area.map(area => ({ areaId: area.uid, areaType: area.areaType })),
        facility: params.facility.map(fac => ({ facilityId: fac.facilityId, facilityType: fac.facilityType })),
        page: params.page || 0,
        size: params.size || 20,
        scheduledStart: params.scheduledStart.getTime(),
        scheduledEnd: params.scheduledEnd.getTime()
      });
      return res.content;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const editPlaningWell = createAsyncThunk<unknown, EditPlaningWellType>(
  `${name}/editPlaningWell`,
  async (body, thunkApi) => {
    try {
      const res = await schedulerApi.editPlaningWellApi(body);
      return res.content;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createWellComment = createAsyncThunk<unknown, CreateWellCommentType>(
  `${name}/createWellComment`,
  async (body, thunkApi) => {
    try {
      const res = await schedulerApi.createWellCommentApi(body);
      return res.content;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
