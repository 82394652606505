import request from 'utils/http/request';
import {
  TechOperationCreateBodyType,
  TechOperationDetailType,
  TechOperationResponseType,
  TechOperationUpdateBodyType
} from './types';
import { getUrl } from '../utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getOperationsByWellboreIdApi = async (
  wellboreId: number | string,
  tubularId: number
): Promise<TechOperationResponseType[]> => request.post(url('findAllTechOperationsByWellboreId'), {
  wellboreId,
  tubularId
});

export const getOperationDetailByIdApi = async (
  id: number
): Promise<TechOperationDetailType> =>
  // работает через dd-api
  request.get(url(`info/${id}`));
export const createTechOperationApi = async (
  body: TechOperationCreateBodyType
): Promise<TechOperationResponseType> => request.post(url(''), body);

export const updateTechOperationApi = async (
  body: TechOperationUpdateBodyType
): Promise<TechOperationResponseType> => request.put(url('updateTechOperationById'), body);

export const deleteTechOperationApi = async (
  id: number
): Promise<{ techOperationId: number }> => request.delete(url(`${id}`));

export const deleteAllOperationApi = async (body: {
  wellboreId: string;
  tubularId?: number;
}) => request.post(
  url('/deleteTechOperationsByWellboreId'),
  body
);

const opers = [
  {
    techOperationId: 1,
    wellId: 'f08b2ffb-1fb1-4c5e-8a60-53031b7391e2',
    tubularId: 12,
    techOperationType: '1',
    techOperationTypeRusName: 'Бурение',
    verticalSpacingFrom: 10,
    verticalSpacingTo: 410,
    assemblyId: '069d4045-1f6d-4bdb-9bbc-572c2d23b9f1',
    turns: 22,
    load: 23,
    pumpFlow: 24,
    speed: 25,
    t: 26
  }
];

const operDetail = {
  id: 1,
  wellId: 'f08b2ffb-1fb1-4c5e-8a60-53031b7391e2',
  tubularId: 12,
  typeCode: '1',
  typeName: 'Бурение',
  tvdMinValue: 10,
  tvdMaxValue: 410,
  assemblyId: '069d4045-1f6d-4bdb-9bbc-572c2d23b9f1',
  n: 22,
  g: 23,
  q: 24,
  v: 25,
  time: 26,
  stratUnits: [
    {
      uid: '0666a4e8-735a-4480-a54a-3d651286e95b',
      referenceLongName: 'Гелазский',
      referenceFormationCode: 'Q1g'
    },
    {
      uid: 'adcfb500-6e1f-4d96-81f3-c982b6304d9b',
      referenceLongName: 'Мессинский',
      referenceFormationCode: 'N1m'
    }
  ],
  incidentsInfo: [
    {
      incidentId: '95',
      incidentDuration: 51720,
      supervisor: 'last first third',
      incidentKind: 'PLAN',
      incidentKindRusName: 'планируемый',
      tvdMinValue: 60,
      tvdMaxValue: 300,
      verticalDepth: 32,
      measuredDepth: 2,
      hseIncidentResponseList: [
        {
          incidentId: '95',
          responseObsNo: 0,
          responseType: '000000041',
          responseTypeName: 'Бурение на буровом растворе',
          responseResultName: '',
          hseIncidentSubstanceList: [
            {
              incidentId: '95',
              responseObsNo: 0,
              incidentSubstance: '2',
              substanceSeqNo: 0,
              volume: 32,
              substanceName: 'ПЦТ I-G'
            }
          ]
        }
      ],
      incidentDetail: [
        {
          incidentId: '95',
          detailObsNo: 0,
          incidentSetId: '2',
          incidentSetName: 'Геологические',
          incidentTypeId: '000000034',
          incidentTypeName: 'Кавернообразование',
          criticalityType: 'MIDDLE',
          criticalityTypeRusName: 'средний'
        }
      ],
      uwi: 'f08b2ffb-1fb1-4c5e-8a60-53031b7391e2'
    }
  ],
  fluidsInfo: [
    {
      id: 'cc98a4a1-d104-40b9-8ad7-1ddf7e902027',
      density: '2',
      equipmentType: '12',
      equipmentName: 'ГР-1 ПБМА',
      nominalViscosity: '3'
    }
  ]
};
