import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPagingSearch } from 'interfaces';
import { saveAs } from 'file-saver';
import options, { convergenceFactorGraphColors, trajectoryGraphColors } from './options';
import {
  EllipseDistGraphType,
  IAnticollisionCalculation,
  IDetailTargetData,
  IGeneralizedResult,
  IProfileCalc,
  IProfileCalcBack,
  ItrajectoryItemDataType,
  ITrajectoryTableData,
  JProfileCalcBackType,
  JProfileCalcType,
  MinDistGraphType,
  NudgeCalcBackType,
  NudgeCalcType,
  ProfileTypes,
  SfGraphType,
  SProfileCalcBackType,
  SProfileCalcType,
  TypedSurvey,
  WellBoreTrackType,
  ICalculationOffset,
  ICalculationTarget,
  ReportType,
  FpAnalysisType,
  FpAnalysisBackType,
  AnalysisDataType,
  AnalysisGraphType,
  OptimumAlignType,
  OptimumAlignBackType
} from './types';
import {
  checkTrajectoryPoints,
  createJProfileCalc,
  getProfileCalcById,
  createNudgeCalc,
  createOrUpdateTrajectoryTableItems,
  createSProfileCalc,
  deleteAllTrajectoryByWellId,
  deleteTrajectoryCalc,
  deleteTrajectoryTableItem, editJProfileCalc, editNudgeCalc, editSProfileCalc,
  getForecastInitialValues,
  getTrajectoryTableDataByWellId,
  getTrajectoryTracksByWellIds,
  intersectionCalculation,
  updateTrajectoryTableItem,
  downloadAnticollisionReport,
  downloadForecastReport, getFpAnalysis, createOptimumAlignCalc, editOptimumAlignCalc
} from './actions';
import {
  addWellboreFields,
  calcSurveyToTypedSurvey, fpAnalysisToFront,
  jProfileCalcToFront,
  nudgeCalcToFront, optimumAlignCalcToFront,
  profileCalcToFront,
  segmentsToSurvey,
  sProfileCalcToFront
} from './converters';
import { ChartLine } from '../../sdk/lineChart/types';
import { IWellCommon } from '../well/types';
import { TrajectoryDataType } from '../../sdk/trajectory/types';
import { trajTo3dModel } from './helper';

const { name } = options;

type IntersectionCalculationPayload = {
    answer: IAnticollisionCalculation;
    targetWell: IWellCommon;
    offsetWells: IWellCommon[];
};

type TrajectoryState = {
    isLoading: boolean;
    wellBoreTracks: WellBoreTrackType[];
    tableTrajectoryData: IPagingSearch<ITrajectoryTableData> | null;
    error: string;
    calculationLoading: boolean;
    allPageLoading: boolean;
    anticollisionCalculation: null | IAnticollisionCalculation;
    generalizedResults: IGeneralizedResult[];
    detailTargetData: IDetailTargetData[];
    sfGraph: null | SfGraphType;
    minDistGraph: MinDistGraphType | null;
    ellipseDistGraph: EllipseDistGraphType | null;
    targetWellInfo: IWellCommon | null;
    offsetWellsInfo: IWellCommon[];
    // 3d model
    modelGraph: TrajectoryDataType[];
    verticalGraph: null | { lines: ChartLine[] };
    horizontalGraph: null | { lines: ChartLine[] };
    // forecast
    profileCalc: IProfileCalc | null;
    nudgeCalc: NudgeCalcType | null;
    jProfileCalc: JProfileCalcType | null;
    sProfileCalc: SProfileCalcType | null;
    surveys: TypedSurvey[];
    fpAnalysis: FpAnalysisType | null;
    trajectoryItemData: ItrajectoryItemDataType | null,
    reportLoading: boolean;
    analysisData: AnalysisDataType[];
    graphData: TrajectoryDataType[];
    optimumAlignCalc: null | OptimumAlignType;
};

const initialState: TrajectoryState = {
  isLoading: false,
  wellBoreTracks: [],
  tableTrajectoryData: null,
  error: '',
  allPageLoading: false,
  calculationLoading: false,
  anticollisionCalculation: null,
  generalizedResults: [],
  detailTargetData: [],
  sfGraph: null,
  minDistGraph: null,
  ellipseDistGraph: null,
  targetWellInfo: null,
  offsetWellsInfo: [],
  modelGraph: [],
  verticalGraph: null,
  horizontalGraph: null,
  // forecast
  profileCalc: null,
  nudgeCalc: null,
  jProfileCalc: null,
  sProfileCalc: null,
  fpAnalysis: null,
  surveys: [],
  trajectoryItemData: null,
  reportLoading: false,
  analysisData: [],
  graphData: [],
  optimumAlignCalc: null
};

export const trajectorySlice = createSlice({
  name,
  initialState,
  reducers: {
    clearWellBoreTracks(state) {
      state.wellBoreTracks = [];
    },
    clearTableTrajectoryData(state) {
      state.tableTrajectoryData = null;
    },
    clearProfileCalc(state) {
      state.jProfileCalc = null;
      state.sProfileCalc = null;
      state.nudgeCalc = null;
    },
    clearTrajectoryItemData(state) {
      state.trajectoryItemData = null;
    },
    changeTrajectoryItemDistance(state, { payload }: PayloadAction<{
            pointNumber:number,
            wellUid: string }
            | null>) {
      const anticollisionCalculationData:
                | ICalculationOffset
                | undefined = state.anticollisionCalculation?.result.result_by_offset.find(
                  (item) => item.well_uuid === payload?.wellUid
                );
      if (state.trajectoryItemData) {
        state.trajectoryItemData.minDist = state.trajectoryItemData
                        && anticollisionCalculationData
                        && payload?.pointNumber ? anticollisionCalculationData.min_dist[payload.pointNumber].toFixed(2) : null;
      }
    },
    changeTrajectoryItemData(state, { payload }) {
      let anticollisionCalculationData:
                | ICalculationOffset
                | ICalculationTarget
                | undefined = state.anticollisionCalculation?.result.result_by_offset.find(
                  (item) => item.well_uuid === payload.wellUid
                );
      if (payload.wellUid === state.anticollisionCalculation?.result.target_well.well_uuid) {
        anticollisionCalculationData = state.anticollisionCalculation?.result.target_well;
      }

      if (anticollisionCalculationData) {
        state.trajectoryItemData = {
          color: payload.color,
          wellName: payload.wellName,
          md: anticollisionCalculationData?.md[
            payload.pointNumber
          ].toFixed(2),
          inc: anticollisionCalculationData?.incl[
            payload.pointNumber
          ].toFixed(2),
          az: anticollisionCalculationData?.azim[
            payload.pointNumber
          ].toFixed(2),
          minDist: state.trajectoryItemData?.minDist
        };
      }
    }
  },
  extraReducers: {
    [getTrajectoryTracksByWellIds.pending.type]: (state) => {
      state.isLoading = true;
      state.wellBoreTracks = [];
    },
    [getTrajectoryTracksByWellIds.fulfilled.type]: (state, { payload }: PayloadAction<WellBoreTrackType[]>) => {
      state.isLoading = false;
      state.wellBoreTracks = addWellboreFields(payload);
      state.error = '';
    },
    [getTrajectoryTracksByWellIds.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getTrajectoryTableDataByWellId.pending.type]: (state) => {
      state.isLoading = true;
      // state.tableTrajectoryData = [];
    },
    [getTrajectoryTableDataByWellId.fulfilled.type]: (state,
      { payload }: PayloadAction<IPagingSearch<ITrajectoryTableData>>) => {
      state.isLoading = false;
      state.tableTrajectoryData = payload;
      state.error = '';
    },
    [getTrajectoryTableDataByWellId.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [createOrUpdateTrajectoryTableItems.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createOrUpdateTrajectoryTableItems.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = '';
    },
    [createOrUpdateTrajectoryTableItems.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [updateTrajectoryTableItem.pending.type]: (state) => {
      state.isLoading = true;
    },
    [updateTrajectoryTableItem.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = '';
    },
    [updateTrajectoryTableItem.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [checkTrajectoryPoints.pending.type]: (state) => {
      state.isLoading = true;
    },
    [checkTrajectoryPoints.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = '';
    },
    [checkTrajectoryPoints.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [deleteTrajectoryTableItem.pending.type]: (state) => {
      state.isLoading = true;
    },
    [deleteTrajectoryTableItem.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = '';
    },
    [deleteTrajectoryTableItem.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [deleteAllTrajectoryByWellId.pending.type]: (state) => {
      state.isLoading = true;
    },
    [deleteAllTrajectoryByWellId.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = '';
      state.tableTrajectoryData = null;
    },
    [deleteAllTrajectoryByWellId.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getForecastInitialValues.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getForecastInitialValues.fulfilled.type]: (state, { payload }: PayloadAction<IProfileCalcBack>) => {
      state.isLoading = false;
      state.profileCalc = profileCalcToFront(payload);
      state.surveys = segmentsToSurvey(payload.segments);
      state.error = '';
    },
    [getForecastInitialValues.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Intersection

    [intersectionCalculation.pending.type]: (state) => {
      state.calculationLoading = true;
    },
    [intersectionCalculation.fulfilled.type]: (state, action: PayloadAction<IntersectionCalculationPayload>) => {
      state.calculationLoading = false;
      state.modelGraph = [];

      state.anticollisionCalculation = action.payload.answer;
      state.targetWellInfo = action.payload.targetWell;
      const { targetWell, offsetWells } = action.payload;
      state.offsetWellsInfo = action.payload.offsetWells;
      const error = action.payload.answer.errors[0];
      if (error) {
        state.error = error;
      }
      const generalizedResults: IGeneralizedResult[] = [];
      const target = action.payload.answer.result.target_well;
      const offsets = action.payload.answer.result.result_by_offset;
      offsets.forEach(offset => {
        const offsetName = offsetWells.find(well => well.uid === offset.well_uuid);
        for (let i = 0; i < offset.md.length; i++) {
          generalizedResults.push({
            targetWellName: targetWell.wellName,
            offsetWellName: offsetName?.wellName || offset.well_uuid,
            offsetMd: offset.md[i]?.toFixed(2),
            targetMd: target.md[i]?.toFixed(2),
            ellipseDist: offset.ellipse_dist[i]?.toFixed(2),
            minDist: offset.min_dist[i]?.toFixed(2),
            tfo: offset.tfo[i]?.toFixed(3),
            sf: offset.sf[i],
            sfFixed: offset.sf[i]?.toFixed(1)
          });
        }
      });
      state.generalizedResults = generalizedResults;
      // state.generalizedResults = action.payload.result.result_by_offset.map((offset, index) => ({
      //     targetWellName: action.payload.result.target_well.well_uuid,
      //     offsetWellName: offset.well_uuid,
      //
      // }));
      const detailTargetData: IDetailTargetData[] = [];
      for (let i = 0; i < target.md.length; i++) {
        detailTargetData.push({
          azim: target.azim[i].toFixed(3),
          incl: target.incl[i].toFixed(3),
          easting: target.easting[i].toFixed(2),
          index: i,
          md: target.md[i].toFixed(2),
          tvd: target.tvd[i].toFixed(2),
          northing: target.northing[i].toFixed(2),
          tvdss: target.tvdss[i].toFixed(2)
        });
      }
      state.detailTargetData = detailTargetData;

      const sfGraphLines: ChartLine[] = [];
      let maxMd = 0;

      // TODO: вынести в одну функцию
      offsets.forEach((offset, offsetIndex) => {
        const points: number[] = [];
        offset.sf.forEach((sfNumber, index) => {
          points.push(offset.md[index], sfNumber);
        });

        maxMd = Math.max(...offset.md, maxMd);
        const wellInfo = offsetWells.find(well => well.uid === offset.well_uuid);

        sfGraphLines.push({
          name: wellInfo?.wellName ? `Скважина ${wellInfo.wellName}` : offset.well_uuid,
          showLegend: true,
          points,
          color: convergenceFactorGraphColors[offsetIndex],
          id: offset.well_uuid
        });
      });

      state.sfGraph = {
        offsetLines: sfGraphLines,
        maxMd
      };

      const minDistGraphLines: ChartLine[] = [];
      offsets.forEach((offset, offsetIndex) => {
        const points: number[] = [];
        offset.min_dist.forEach((minDist, index) => {
          points.push(offset.md[index], minDist);
        });
        const wellInfo = offsetWells.find(well => well.uid === offset.well_uuid);

        minDistGraphLines.push({
          name: wellInfo?.wellName ? `Скважина ${wellInfo.wellName}` : offset.well_uuid,
          showLegend: true,
          points,
          color: trajectoryGraphColors[offsetIndex],
          id: offset.well_uuid
        });
      });
      state.minDistGraph = {
        offsetLines: minDistGraphLines
      };

      const ellipsesGraphLines: ChartLine[] = [];
      offsets.forEach((offset, offsetIndex) => {
        const points: number[] = [];
        offset.ellipse_dist.forEach((ellipse, index) => {
          points.push(offset.md[index], ellipse);
        });
        const wellInfo = offsetWells.find(well => well.uid === offset.well_uuid);

        ellipsesGraphLines.push({
          name: wellInfo?.wellName ? `Скважина ${wellInfo.wellName}` : offset.well_uuid,
          showLegend: true,
          points,
          color: trajectoryGraphColors[offsetIndex],
          id: offset.well_uuid
        });
      });
      state.ellipseDistGraph = {
        offsetLines: ellipsesGraphLines
      };

      state.modelGraph.push({
        wellName: `Скважина ${targetWell.wellName}`,
        wellboreName: '',
        wellUid: targetWell.uid,
        wellboreUid: '',
        points: trajTo3dModel(target)
      });
      offsets.forEach(offset => {
        const well = offsetWells.find(w => w.uid === offset.well_uuid);

        state.modelGraph.push({
          wellName: well?.wellName ? `Скважина ${well.wellName}` : offset.well_uuid,
          wellboreName: '',
          wellUid: offset.well_uuid,
          wellboreUid: '',
          points: trajTo3dModel(offset)
        });
      });

      state.horizontalGraph = {
        lines: offsets.map((offset, i) => {
          const points: number[] = [];
          offset.easting.forEach((east, index) => {
            points.push(east, offset.northing[index]);
          });
          const well = offsetWells.find(w => w.uid === offset.well_uuid);
          return {
            color: trajectoryGraphColors[i + 1],
            id: offset.well_uuid,
            showLegend: false,
            name: well?.wellName ? `Скважина ${well.wellName}` : offset.well_uuid,
            points
          };
        })
      };

      state.verticalGraph = {
        lines: offsets.map((offset, i) => {
          const points: number[] = [];
          offset.displacement.forEach((disp, index) => {
            points.push(disp, offset.tvd[index]);
          });
          const well = offsetWells.find(w => w.uid === offset.well_uuid);
          return {
            color: trajectoryGraphColors[i + 1],
            id: offset.well_uuid,
            showLegend: false,
            name: well?.wellName ? `Скважина ${well.wellName}` : offset.well_uuid,
            points
          };
        })
      };

      const horizontalPoints: number[] = [];
      target.easting.forEach((east, index) => {
        horizontalPoints.push(east, target.northing[index]);
      });
      state.horizontalGraph.lines.push({
        points: horizontalPoints,
        name: `Скважина ${targetWell.wellName}`,
        color: trajectoryGraphColors[0],
        id: target.well_uuid,
        showLegend: false
      });

      const verticalPoints: number[] = [];
      target.displacement.forEach((disp, index) => {
        verticalPoints.push(disp, target.tvd[index]);
      });
      state.verticalGraph.lines.push({
        points: verticalPoints,
        name: `Скважина ${targetWell.wellName}`,
        color: trajectoryGraphColors[0],
        id: target.well_uuid,
        showLegend: false
      });
    },
    [intersectionCalculation.rejected.type]: (state, action: PayloadAction<string>) => {
      state.calculationLoading = false;
      state.error = action.payload;
    },
    [createNudgeCalc.pending.type]: (state) => {
      state.calculationLoading = true;
    },
    [createNudgeCalc.fulfilled.type]: (state: TrajectoryState, { payload }: PayloadAction<NudgeCalcBackType>) => {
      state.calculationLoading = false;
      state.nudgeCalc = nudgeCalcToFront(payload);
      state.surveys.push(
        ...payload.surveys
          .map(survey => calcSurveyToTypedSurvey(survey, payload.profile_type, payload.item_uuid))
      );
    },
    [createNudgeCalc.rejected.type]: (state, action: PayloadAction<string>) => {
      state.calculationLoading = false;
      state.error = action.payload;
    },

    [createJProfileCalc.pending.type]: (state) => {
      state.calculationLoading = true;
    },
    [createJProfileCalc.fulfilled.type]: (state: TrajectoryState, { payload }: PayloadAction<JProfileCalcBackType>) => {
      state.calculationLoading = false;
      state.jProfileCalc = jProfileCalcToFront(payload);
      state.surveys.push(...payload.surveys.map(survey => calcSurveyToTypedSurvey(survey, ProfileTypes.J, payload.item_uuid)));
    },
    [createJProfileCalc.rejected.type]: (state, action: PayloadAction<string>) => {
      state.calculationLoading = false;
      state.error = action.payload;
    },
    [getProfileCalcById.pending.type]: (state) => {
      state.calculationLoading = true;
    },
    [getProfileCalcById.fulfilled.type]: (
      state: TrajectoryState,
      { payload }: PayloadAction<
                | (SProfileCalcBackType & { profileType: string })
                | (JProfileCalcBackType & { profileType: string })
                | (NudgeCalcBackType & { profileType: string })
                | (OptimumAlignBackType & { profileType: string })
                | null
            >
    ) => {
      state.calculationLoading = false;

      if (payload) {
        switch (payload.profileType) {
          case ProfileTypes.J:
            state.jProfileCalc = jProfileCalcToFront(payload as JProfileCalcBackType);
            break;
          case ProfileTypes.S:
            state.sProfileCalc = sProfileCalcToFront(payload as SProfileCalcBackType);
            break;
          case ProfileTypes.NudgeDlsInclAzim:
          case ProfileTypes.NudgeDlsTvdAzim:
          case ProfileTypes.NudgeMdInclAzim:
          case ProfileTypes.NudgeTvdInclAzim:
          case ProfileTypes.NudgeUndefined:
            state.nudgeCalc = nudgeCalcToFront(payload as NudgeCalcBackType);
            break;
          case ProfileTypes.OptimumAlignDls:
          case ProfileTypes.OptimumAlignTvd:
          case ProfileTypes.OptimumAlignTan:
            state.optimumAlignCalc = optimumAlignCalcToFront(payload as OptimumAlignBackType);
            break;
          default:
            break;
        }
      }
    },
    [getProfileCalcById.rejected.type]: (state, action: PayloadAction<string>) => {
      state.calculationLoading = false;
      state.error = action.payload;
    },
    [createSProfileCalc.pending.type]: (state) => {
      state.calculationLoading = true;
    },
    [createSProfileCalc.fulfilled.type]: (state: TrajectoryState, { payload }: PayloadAction<SProfileCalcBackType>) => {
      state.calculationLoading = false;
      state.sProfileCalc = sProfileCalcToFront(payload);
      state.surveys.push(...payload.surveys.map(survey => calcSurveyToTypedSurvey(survey, ProfileTypes.S, payload.item_uuid)));
    },
    [createSProfileCalc.rejected.type]: (state, action: PayloadAction<string>) => {
      state.calculationLoading = false;
      state.error = action.payload;
    },

    [deleteTrajectoryCalc.pending.type]: (state) => {
      state.calculationLoading = true;
    },
    [deleteTrajectoryCalc.fulfilled.type]: (state, { payload }: PayloadAction<TypedSurvey>) => {
      state.calculationLoading = false;
      if (payload) {
        state.surveys = state.surveys.filter(survey => survey.calcId !== payload.calcId);
      }
      state.error = '';
    },
    [deleteTrajectoryCalc.rejected.type]: (state, action: PayloadAction<string>) => {
      state.calculationLoading = false;
      state.error = action.payload;
    },

    [editNudgeCalc.pending.type]: (state) => {
      state.calculationLoading = true;
    },
    [editNudgeCalc.fulfilled.type]: (state, { payload }: PayloadAction<NudgeCalcBackType>) => {
      state.calculationLoading = false;
      state.nudgeCalc = nudgeCalcToFront(payload);
      state.surveys = state.surveys.filter(survey => survey.calcId !== payload.item_uuid);
      // TODO: fix
      // @ts-ignore
      state.surveys.push(...payload.surveys.map(survey => calcSurveyToTypedSurvey(survey, payload?.profile_type || ProfileTypes.NudgeDlsTvdAzim, payload.item_uuid)));
    },
    [editNudgeCalc.rejected.type]: (state, { payload }: PayloadAction<string>) => {
      state.calculationLoading = false;
      state.error = payload;
    },

    [editJProfileCalc.pending.type]: (state) => {
      state.calculationLoading = true;
    },
    [editJProfileCalc.fulfilled.type]: (state: TrajectoryState, { payload }: PayloadAction<JProfileCalcBackType>) => {
      state.calculationLoading = false;
      state.jProfileCalc = jProfileCalcToFront(payload);
      state.surveys = state.surveys.filter(survey => survey.calcId !== payload.item_uuid);
      state.surveys.push(...payload.surveys.map(survey => calcSurveyToTypedSurvey(survey, ProfileTypes.J, payload.item_uuid)));
    },
    [editJProfileCalc.rejected.type]: (state, action: PayloadAction<string>) => {
      state.calculationLoading = false;
      state.error = action.payload;
    },

    [editSProfileCalc.pending.type]: (state) => {
      state.calculationLoading = true;
    },
    [editSProfileCalc.fulfilled.type]: (state: TrajectoryState, { payload }: PayloadAction<SProfileCalcBackType>) => {
      state.calculationLoading = false;
      state.sProfileCalc = sProfileCalcToFront(payload);
      state.surveys = state.surveys.filter(survey => survey.calcId !== payload.item_uuid);
      state.surveys.push(...payload.surveys.map(survey => calcSurveyToTypedSurvey(survey, ProfileTypes.S, payload.item_uuid)));
    },
    [editSProfileCalc.rejected.type]: (state, action: PayloadAction<string>) => {
      state.calculationLoading = false;
      state.error = action.payload;
    },

    [createOptimumAlignCalc.pending.type]: (state) => {
      state.calculationLoading = true;
    },
    [createOptimumAlignCalc.fulfilled.type]: (state: TrajectoryState, { payload }: PayloadAction<OptimumAlignBackType>) => {
      state.calculationLoading = false;
      state.optimumAlignCalc = optimumAlignCalcToFront(payload);
      state.surveys.push(...payload.surveys.map(survey => calcSurveyToTypedSurvey(survey, ProfileTypes.OptimumAlignTan, payload.item_uuid)));
    },
    [createOptimumAlignCalc.rejected.type]: (state, action: PayloadAction<string>) => {
      state.calculationLoading = false;
      state.error = action.payload;
    },

    [editOptimumAlignCalc.pending.type]: (state) => {
      state.calculationLoading = true;
    },
    [editOptimumAlignCalc.fulfilled.type]: (state: TrajectoryState, { payload }: PayloadAction<OptimumAlignBackType>) => {
      state.calculationLoading = false;
      state.optimumAlignCalc = optimumAlignCalcToFront(payload);
      state.surveys = state.surveys.filter(survey => survey.calcId !== payload.item_uuid);
      state.surveys.push(...payload.surveys.map(survey => calcSurveyToTypedSurvey(survey, ProfileTypes.OptimumAlignTan, payload.item_uuid)));
    },
    [editOptimumAlignCalc.rejected.type]: (state, action: PayloadAction<string>) => {
      state.calculationLoading = false;
      state.error = action.payload;
    },

    [downloadAnticollisionReport.pending.type]: (state) => {
      state.reportLoading = true;
    },
    [downloadAnticollisionReport.fulfilled.type]: (state: TrajectoryState, { payload }: PayloadAction<ReportType>) => {
      state.reportLoading = false;
      saveAs(payload.report.data, `report_${payload.well_uuid}.xlsx`);
    },
    [downloadAnticollisionReport.rejected.type]: (state, action: PayloadAction<string>) => {
      state.reportLoading = false;
      state.error = action.payload;
    },

    [downloadForecastReport.pending.type]: (state) => {
      state.reportLoading = true;
    },
    [downloadForecastReport.fulfilled.type]: (state: TrajectoryState, { payload }: PayloadAction<ReportType>) => {
      state.reportLoading = false;
      saveAs(payload.report.data, `report_${payload.well_uuid}.xlsx`);
    },
    [downloadForecastReport.rejected.type]: (state, action: PayloadAction<string>) => {
      state.reportLoading = false;
      state.error = action.payload;
    },

    [getFpAnalysis.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getFpAnalysis.fulfilled.type]: (state: TrajectoryState, { payload }: PayloadAction<FpAnalysisBackType>) => {
      // clear data firstly
      state.graphData = [];
      state.fpAnalysis = null;
      state.isLoading = false;

      const fpAnalysis = fpAnalysisToFront(payload);
      state.fpAnalysis = fpAnalysis;
      const { result } = payload;
      result.fact_result.md.forEach((md, index) => {
        state.analysisData.push(
          {
            factMd: md,
            planMd: result.plan_result.md[index],
            factIncl: result.fact_result.incl[index],
            planIncl: result.plan_result.incl[index],
            factAzim: result.fact_result.azim[index],
            planAzim: result.plan_result.azim[index],
            lrDisp: result.fact_result.lr_disp[index],
            abDisp: result.fact_result.ab_disp[index]
          }
        );
      });
      state.graphData.push(
        {
          points: {
            x: result.fact_result.northing,
            y: result.fact_result.easting,
            z: result.fact_result.tvd
          },
          wellUid: result.well_uuid,
          wellboreName: '',
          wellName: '',
          wellboreUid: ''
        }
      );
      state.graphData.push(
        {
          points: {
            x: result.plan_result.northing,
            y: result.plan_result.easting,
            z: result.plan_result.tvd
          },
          wellUid: result.well_uuid,
          wellboreName: '',
          wellName: '',
          wellboreUid: ''
        }
      );
    },
    [getFpAnalysis.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export default trajectorySlice.reducer;