import fileSaver from 'file-saver';
import request from 'utils/http/request';
import { getTokenString } from 'utils/http/utils';
import { getUrl } from '../utils';
import options from './options';
import {
  TrajectoryCalcDetailsListResponseType,
  TrajectoryCalcPostResponseType,
  TrajectoryCalcType
} from './types';

const { apiUrl, calcUrl } = options;

const url = getUrl(apiUrl);
const calcApi = getUrl(calcUrl);

export const getAllTrajectoryCalcApi = async (): Promise<
  TrajectoryCalcType[]
> => request.get(calcApi('calc_trajectory/research/trajectory_generator/'));

export const getTrajectoryCalcByUidApi = async (
  uid: string
): Promise<TrajectoryCalcDetailsListResponseType> =>
  request.get(url(`calc_trajectory/calc/${uid}/`));

export const startTrajectoryCalcApi = async (
  body: any
): Promise<TrajectoryCalcPostResponseType> =>
  request.post(url('calc_trajectory/calc/'), body);

export const deleteTrajectoryCalcByUidApi = async (
  uid: string
): Promise<string> => {
  await request.delete(url(`calc_trajectory/calc/${uid}/`));
  return uid;
};

export const exportTrajectoryCalcByUidApi = async (
  uid: string,
  name: string
): Promise<string> =>
  request
    .instance({
      url: url('calc_trajectory/utils/generate_report/'),
      method: 'POST',
      responseType: 'blob',
      headers: {
        Authorization: getTokenString()
      },
      data: {
        calc_uuid: uid
      }
    })
    .then((res: any) => {
      fileSaver.saveAs(res.data, name || 'report.xlsx');
      return res;
    });
