import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BreadcrumbStateType, IBreadcrumbElement } from './types';
import { mainPage, reducerActions } from './utils';
import { breadcrumbFacility, breadcrumbWell } from './actions';

const initialState: BreadcrumbStateType = {
  breadcrumbs: [mainPage],
  isLoading: false,
  error: '',
  fromArea: false,
  originalWellBread: {
    wellId: '',
    wellName: '',
    areaId: '',
    areaName: '',
    areaType: '',
    facilityId: '',
    facilityName: '',
    fieldId: '',
    facilityType: '',
    fieldName: ''
  }
};

export const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  extraReducers: {
    [breadcrumbWell.pending.type]: (state) => {
      state.isLoading = true;
    },
    [breadcrumbWell.fulfilled.type]: (state, action: PayloadAction<IBreadcrumbElement[]>) => {
      state.isLoading = false;
      state.error = '';
      reducerActions.startFromMain(state, action);
    },
    [breadcrumbWell.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [breadcrumbFacility.fulfilled.type]: (state, action: PayloadAction<IBreadcrumbElement[]>) => {
      state.isLoading = false;
      state.error = '';
      reducerActions.startFromMain(state, action);
    },
    [breadcrumbFacility.pending.type]: (state) => {
      state.isLoading = true;
    },
    [breadcrumbFacility.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  },
  reducers: reducerActions
});

export const breadActions = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
