export enum TrunkPosition {
    LEFT = 'left',
    RIGHT = 'right'
}

export enum Warning {
    color = 'var(--messages-error)'
}

export enum HeightCoeff {
    // Как вычисляется коэффициент?
    // (750 / 1500) = 0.5
    // 750 - высота используемой части рисунка
    // 1500 - точка перехода на другой коэффициент
    FIRST = 0.5, // 0 - 1500
    SECOND = 0.3, // 1500 - 2500
    THIRD = 0.15, // 2500 - 5000
    FOURTH = 0.075, // 5000 - 10000
    FIFTH = 0.05, // 10000 - 15000
}

export enum TrunkName {
    DIRECTION = 'Направление', // Направление
    CONDUCTOR = 'Кондуктор', // Кондуктор
    INTERMEDIATE = 'Промежуточная', // Промежуточная
    OPEN = 'Открытый ствол', // Открытый ствол
    MINE = 'Шахта', // Шахта
    TAIL = 'Фильтр Хвостовик', // Фильтр хвостик
    OPERATIONAL = 'Эксплуатационная' // Эксплуатационная
}

export enum CanvasTypes {
    TrunkHeight = 'canvasTrunkHeightChange',
    VTrunkHeight = 'canvasVDepthTrunkHeightChange',
    TrunkOuter = 'canvasHandleChangeOuter',
    TrunkInner = 'canvasHandleChangeInner',
    TrunkActive = 'canvasHandleActive',
    VTrunkOuter = 'canvasVTrunkOuter'
}

export enum PanelTypes {
    ActiveHandle = 'panelActiveHandle',
    OuterChange = 'panelOuterChange',
    WidthChange = 'panelWidthChange',
    CementMaxChange = 'panelCementMaxChange',
    CementMinChange = 'panelCementMinChange',
    OuterVTrunkChange = 'panelOuterVTrunkChange',
    OpenDiameterChange = 'panelOpenDiameterChange',
    TrunkHeightChange = 'panelTrunkHeightChange',
    VTrunkHeightChange = 'panelVTrunkHeightChange',
    VTrunkLengthChange = 'panelVTrunkLengthChange',
    InnerTrunkChange = 'panelInnerTrunkChange'
}

export enum OtherChangesType {
    UpdateTrunks = 'otherUpdateTrunks',
    InitialChange = 'otherInitialChange',
    UpdateOpenTrunk = 'updateOpenTrunk',
    UnmountSave = 'unmountSave'
}

export interface ICement {
    id: string;
    jointCountFrom: number; // min
    jointCountTo: number; // max
    touched: boolean;
}

export interface ITrunk {
    globalId?: number;
    wallThickness: number; // width
    baseDepth: number; // height
    left: number;
    // uwi not unique
    uwi: string; // id
    // localId generated from front. For react Key
    localId: string;
    secondLeft: number;
    realWallThickness: number; // realWidth
    active: boolean;
    top: number;
    warning: Warning.color | null;
    name: Exclude<TrunkName, TrunkName.OPEN>;
    outsideDiameter: number; // outer
    insideDiameter: number; // inner
    openHoleDiameter: number; // openDiameter
    cement: ICement;
    position: number;
    saved: boolean;
}

export interface IVTrunk {
    top: number;
    active: boolean;
    name: TrunkName.OPEN;
    warning: Warning.color | null;
    outsideDiameter: number;
    insideDiameter: number;
    wallThickness: number;
    length: number;
    baseDepth: number;
    left: number;
    uwi: string;
    saved: boolean;
    openHoleDiameter: number;
    position: number;
    localId: string;
}

export interface IVerticalDepth {
    vTrunk: IVTrunk | null;
    width: number;
    height: number;
    left: number;
    id: string;
}

export interface IConstruction {
    verticalDepth: IVerticalDepth,
    trunks: ITrunk[],
    center: number,
    activeTrunk: null | ITrunk | IVTrunk,
    activeIndex: number | null,
    heightCoefficient: HeightCoeff
}

export type ActionType<TypeEnum> = {
    type: TypeEnum;
    payload: any;
};

export type ActionConstruction =
    | { type: CanvasTypes.TrunkHeight, payload: { index: number, height: number } }
    | { type: CanvasTypes.VTrunkHeight, payload: { height: number } }
    | { type: CanvasTypes.TrunkOuter, payload: { index: number, increment: boolean, value: number, openHoleDiameterList: number[] } }
    | { type: CanvasTypes.TrunkInner, payload: { index: number, increment: boolean, value: number, openHoleDiameterList: number[] } }
    | { type: CanvasTypes.TrunkActive, payload: { active: boolean, index: number } }
    | { type: CanvasTypes.VTrunkOuter, payload: number }

    | { type: PanelTypes.ActiveHandle, payload: number | null }
    | { type: PanelTypes.WidthChange, payload: number }
    | { type: PanelTypes.OuterChange, payload: number }
    | { type: PanelTypes.CementMinChange, payload: number }
    | { type: PanelTypes.CementMaxChange, payload: number }
    | { type: PanelTypes.OuterVTrunkChange, payload: number } // TODO: check
    | { type: PanelTypes.OpenDiameterChange, payload: number }
    | { type: PanelTypes.TrunkHeightChange, payload: number }
    | { type: PanelTypes.VTrunkHeightChange, payload: number } // TODO: check
    | { type: PanelTypes.VTrunkLengthChange, payload: number } // TODO: check
    | { type: PanelTypes.InnerTrunkChange, payload: number }

    | { type: OtherChangesType.UpdateTrunks, payload: ITrunk[] }
    | { type: OtherChangesType.InitialChange, payload: number }
    | { type: OtherChangesType.UpdateOpenTrunk, payload: IVTrunk | null }
    | { type: OtherChangesType.UnmountSave, payload: { dispatch: any, wellboreId: string } };

interface IBasicFormState {
    outsideDiameter: number;
    baseDepth: number;
    autoCementCount: boolean;
    openTrunk: boolean;
    openHoleDiameter: number;
}

export interface ITrunkFormState extends IBasicFormState {
    name: Exclude<TrunkName, TrunkName.OPEN> | '';
    jointCountFrom: number;
    jointCountTo: number;
}

export interface IOpenTrunkState extends IBasicFormState {
    name: TrunkName.OPEN;
    length: number;
}

export interface IToReach {
    toReach: number;
    value: number;
}
