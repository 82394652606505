import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { register } from './actions';

const { name } = options;

type AdminState = {
    user: any
    successful: boolean
    isLoading: boolean
    error: string
};

const initialState: AdminState = {
  user: { username: '' },
  successful: false,
  isLoading: false,
  error: ''
};

export const adminSlice = createSlice({
  name,
  initialState,
  reducers: {
    restart(state: AdminState, { payload }: PayloadAction<any>) {
      state.user = initialState.user;
      state.successful = initialState.successful;
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
    }
  },
  extraReducers: {
    [register.pending.type]: (state) => {
      state.isLoading = true;
    },
    [register.fulfilled.type]: (state, { payload }: PayloadAction<any>) => {
      state.successful = true;
      state.isLoading = false;
      state.user.username = payload;
    },
    [register.rejected.type]: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.successful = false;
      state.isLoading = false;
    }
  }
});

export default adminSlice.reducer;
