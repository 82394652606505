import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Badge } from 'primereact/badge';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';

import WebsocketContext from 'contexts/ws';
import { messageTypes } from 'models/notification/types';
import { useClassName } from '../../../utils/cn';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { breadcrumbSlice } from '../../../models/breadcrumb/slice';
import { BreadcrumbType } from '../../../models/breadcrumb/types';

import { UserType } from '../../../models/auth/types';
import { NotificationElement } from './notification-element';
import { TroubleContent } from './trouble-content';
import { WellContent } from './well-content';
import { ProjectContent } from './project-content';
import { selectNotificationsState } from '../../../models/notification/selectors';
import { getNotificationsList } from '../../../models/notification/actions';
import { LoadingOverlay } from '../../loading-overlay';
import IncidentContent from './incident-content';
import { notificationSlice } from '../../../models/notification/slice';
import { createId } from '../../../utils/utils';
import { eventsSlice } from '../../../reducers/events';
import { fieldSlice } from '../../../models/field/slice';
import { facilitySlice } from '../../../models/facility/slice';
import { wellSlice } from '../../../models/well/slice';
import './style.scss';
import { postNotificationAsRead } from 'models/notification/api';

type HeaderNotificationType = {
    user: UserType | null;
};

export type NotificationWellResponseType = {
    wellUid: string
    wellName: string
    type: messageTypes
};

export type NotificationPayloadType = {
    trouble: string
    prob: number
    recommend: string
    time: number
};

export type NotificationProblemResponseType = NotificationWellResponseType & {
    payload: NotificationPayloadType
};

export const HeaderNotification: React.FC<HeaderNotificationType> = ({ user }) => {
  const cn = useClassName('header-notification');
  const { t } = useTranslation('common', { keyPrefix: 'header' });

  const op = useRef<OverlayPanel | null>(null);

  const [notSeen, setNotSeen] = useState<number>(0);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { startFromMain } = breadcrumbSlice.actions;
  const { addNewMessage } = notificationSlice.actions;
  const { changeWellRiskFacilityCard } = fieldSlice.actions;
  const { changeWellRiskWellCard } = facilitySlice.actions;
  const { changeRiskWell } = wellSlice.actions;

  const { newEvent, clearEvents } = eventsSlice.actions;
  const { notifications, isLoading } = useAppSelector(selectNotificationsState);
  const { subscribe, unsubscribe } = useContext(WebsocketContext);

  const callStateChanges = (message: NotificationProblemResponseType) => {
    switch (message.type) {
      case messageTypes.INCIDENT_CLOSE:
        // @ts-ignore
        const incidentId = message.payload?.incidentId;
        dispatch(changeWellRiskWellCard(incidentId));
        dispatch(changeWellRiskFacilityCard(incidentId));
        dispatch(changeRiskWell(incidentId));
        break;
      default: break;
    }
  };

  // подписка на топик по новым скважинам
  const subscribeTopic = async () => {
    await subscribe('/topic/notice-bell', (newMessage: NotificationProblemResponseType | NotificationWellResponseType) => {
      if (newMessage) {
        dispatch(addNewMessage({
          ...(newMessage as NotificationProblemResponseType),
          id: createId(6),
          createdAt: new Date().toString(),
          read: false
        }));
        console.log('newMessage', newMessage);

        // todo new Event
        // dispatch(newEvent({
        //     type: newMessage.type,
        //     payload: newMessage
        // }));
        callStateChanges(newMessage as NotificationProblemResponseType);

        setNotSeen(prev => prev + 1);
      }
    });
  };

  useEffect(() => {
    if (user) {
      subscribeTopic();
    }

    return () => {
      unsubscribe('/topic/notice-bell');
      dispatch(clearEvents());
    };
  }, []);

  // переход при клике на кнопку Подробнее
  const handleLinkClick = (type: messageTypes, uid: string, wellName: string, id:string) => {
    op.current?.hide();
    // пометить сообщение прочитанным
    postNotificationAsRead(id);
    switch (type) {
      case messageTypes.PREDICTED_RESULT:
        dispatch(startFromMain([
          {
            label: t('monitoring'),
            to: '/monitoring'
          },
          {
            label: wellName || '',
            to: uid,
            type: BreadcrumbType.well
          }
        ]));
        navigate(`/monitoring/well/${uid}?tab=supervising`);
        break;
      case messageTypes.PROJECT_CREATED:
        dispatch(startFromMain([
          {
            label: t('projdoc'),
            to: '/projdoc'
          },
          {
            label: wellName || '',
            to: uid,
            type: BreadcrumbType.well
          }
        ]));
        navigate(`/projdoc/well/${uid}`);
        break;
      case messageTypes.WELL_CREATED:
        dispatch(startFromMain([
          {
            label: t('monitoring'),
            to: '/monitoring'
          },
          {
            label: wellName || '',
            to: uid,
            type: BreadcrumbType.well
          }
        ]));
        navigate(`/monitoring/well/${uid}?tab=params`);
        break;
      case messageTypes.INCIDENT_CLOSE:
        dispatch(startFromMain([
          {
            label: t('monitoring'),
            to: '/monitoring'
          },
          {
            label: wellName || '',
            to: uid,
            type: BreadcrumbType.well
          }
        ]));
        navigate(`/monitoring/well/${uid}?tab=supervising`);
        break;
      default: break;
    }
  };

  const toggleBell = (event: React.MouseEvent<HTMLDivElement>) => {
    op.current?.toggle(event);
    dispatch(getNotificationsList({}));
    if (notSeen) {
      setNotSeen(0);
    }
  };

  return (
    <div
      className={cn('wrapper')}
      onClick={toggleBell}
    >
      <i
        className={`pi pi-bell p-overlay-badge p-button-text ${cn('button')}`}
      >
        {Boolean(notSeen) && <Badge severity="danger" value={notSeen} size="normal" />}
      </i>

      <OverlayPanel
        ref={op}
        id="notification_panel"
        breakpoints={{ '480px': '100vw', '768px': '75vw' }}
        style={{ width: '500px' }}
        className={cn('overlay-panel')}
      >
        <div
          className={cn('header')}
          onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
        >
          <div className={cn('header-title')}>
            <h2>{t('notifications')}</h2>
          </div>
          <div className={cn('header-panel')}>
            <Dropdown
              style={{ width: 250 }}
              options={[
                {
                  label: t('unread-first'),
                  value: 'unread-first'
                }
              ]}
              value="unread-first"
              disabled
            />
          </div>
        </div>

        <div
          className={cn('content')}
          onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
        >
          <LoadingOverlay loading={isLoading} />
          {(notifications.length === 0 && !isLoading) ? (
            <div className="m-4">
              {t('no-messages')}
            </div>
          ) : (
            <>
              {notifications.map(message => (
                <NotificationElement
                  key={message.id}
                  createdAt={message.createdAt}
                  handleButtonClick={() => handleLinkClick(message.type, message.wellUid, message.wellName, message.id)}
                >
                  {
                    message.type === messageTypes.PREDICTED_RESULT ? (
                      <TroubleContent
                        wellName={message.wellName}
                        payload={message.payload}
                      />
                    ) : message.type === messageTypes.PROJECT_CREATED ? (
                      <ProjectContent
                        wellName={message.wellName}
                      />
                    ) : message.type === messageTypes.WELL_CREATED ? (
                      <WellContent
                        wellName={message.wellName}
                      />
                    ) : message.type === messageTypes.INCIDENT_CLOSE ? (
                      <IncidentContent
                        wellName={message.wellName}
                        payload={message.payload}
                      />
                    ) : null
                  }
                </NotificationElement>
              ))}
            </>
          )}
        </div>

      </OverlayPanel>
    </div>
  );
};
