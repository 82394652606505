import options from './options';
import { getUrl } from '../utils';
import request from '../../utils/http/request';
import { CalculationTorqueDragType, EditTorqueDragType } from './types';

const { apiUrl, calcUrl } = options;

const url = getUrl(apiUrl);
const calcApi = getUrl(calcUrl);

export const getTorqueDragApi = async (hydraulicId: string) => request.get(url(`?linked_with_hydraulics_calc=${hydraulicId}`));

export const updateTorqueDragApi = async (torqueId: string, body: EditTorqueDragType) => request.put(url(`${torqueId}/`), body);

export const calculateTorqueDragApi = async (hydraulicId: string): Promise<CalculationTorqueDragType> => request.post(calcApi(''), { hydraulics_calc_uuid: hydraulicId });
