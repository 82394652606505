export interface IReportTable {
    field: string;
    status: boolean;
    date: string;
    user: string;
}

//     NOT_PUBLISHED("не опубликован"),
//     ON_APPROVAL("на согласовании"),
//     RETURNED("возвращен на доработку"),
//     PUBLISHED("опубликован");

export enum SectionStatus {
    PUBLISHED = 'PUBLISHED',
    NOT_PUBLISHED = 'NOT_PUBLISHED',
    RETURNED = 'RETURNED',
    ON_APPROVAL = 'ON_APPROVAL',
    Default = ''
}

export enum SectionName {
    PROJECT_DATA = 'PROJECT_DATA',
    CONSTRUCTION = 'CONSTRUCTION',
    TRAJECTORY = 'TRAJECTORY',
    STRATIGRAPHY = 'STRATIGRAPHY',
    INCIDENT = 'INCIDENT',
    TUBULAR = 'TUBULAR',
    DRILLING_MUD = 'DRILLING_MUD',
    TECH_OPERATION = 'TECH_OPERATION',
    DEPTH_DAY_CHART = 'DEPTH_DAY_CHART',
    Default = ''
}
export const sectionRus: Record<SectionName, string> ={
  [SectionName.PROJECT_DATA]: 'Проектные данные',
  [SectionName.CONSTRUCTION]: 'Конструкция',
  [SectionName.TRAJECTORY]: 'Траектория',
  [SectionName.STRATIGRAPHY]: 'Стратиграфия',
  [SectionName.INCIDENT]: 'Осложнения',
  [SectionName.TUBULAR]: 'Компоновка колонны',
  [SectionName.DRILLING_MUD]: 'Буровой раствор',
  [SectionName.TECH_OPERATION]: 'Технологические операции',
  [SectionName.DEPTH_DAY_CHART]: 'ГГД',
  [SectionName.Default]: ''
};
export const statusRus: Record<SectionStatus, string> = {
  [SectionStatus.ON_APPROVAL]: 'на согласовании',
  [SectionStatus.PUBLISHED]: 'опубликован',
  [SectionStatus.NOT_PUBLISHED]: 'не опубликован',
  [SectionStatus.RETURNED]: 'возвращен на доработку',
  [SectionStatus.Default]: ''
};