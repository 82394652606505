import { createAsyncThunk } from '@reduxjs/toolkit';
import * as notificationApi from './api';
import options from './options';
import { NotificationFilter } from './types';

const { name } = options;

export const getNotificationsList = createAsyncThunk(
  `${name}/getNotificationsList`,
  async ({ filter = NotificationFilter.notRead, page = 0, size = 0 }: { filter?: NotificationFilter, page?: number, size?: number }, thunkApi) => {
    try {
      return await notificationApi.getNotificationsListApi(page, size, filter);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
