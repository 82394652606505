import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import {
  editPlaningWell, getDrillingInfo,
  getInstallationInfo, getWorkTypes, nextStepStage, planingSearch, schedulerSearch
} from './actions';
import {
  ChangeInstallationStepResponseType, DrillingInfoType,
  InstallationInfoType,
  IWorkType,
  PlaningWellType,
  SchedulerWellBackType,
  SchedulerWellType
} from './types';
import { schedulerWellToFront } from './converters';
import { NotNullable } from '../../interfaces/utilityTypes';

const { name } = options;

type SchedulerState = {
  isLoading: boolean;
  error: string;
  schedulerWells: SchedulerWellType[];
  workTypes: IWorkType[];
  installationInfo: InstallationInfoType[];
  activeInstallationInfo: InstallationInfoType | null;
  workInfoLoading: boolean;
  planingWells: PlaningWellType[];
  isDataLoaded: boolean;
  drillingInfo: DrillingInfoType | null;
};

const initialState: SchedulerState = {
  isLoading: false,
  error: '',
  schedulerWells: [],
  workTypes: [],
  installationInfo: [],
  workInfoLoading: false,
  activeInstallationInfo: null,
  planingWells: [],
  isDataLoaded: false,
  drillingInfo: null
};

export const schedulerSlice = createSlice({
  name,
  initialState,
  reducers: {
    cleanPage(state) {
      for (const key in initialState) {
        // @ts-ignore
        state[key] = initialState[key];
      }
    }
  },
  extraReducers: {
    [schedulerSearch.pending.type]: (state) => {
      state.isLoading = true;
    },
    [schedulerSearch.fulfilled.type]: (state, { payload }: PayloadAction<SchedulerWellBackType[]>) => {
      state.isLoading = false;
      state.error = '';
      state.schedulerWells = payload.map(well => schedulerWellToFront(well));
    },
    [schedulerSearch.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getWorkTypes.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getWorkTypes.fulfilled.type]: (state, { payload }: PayloadAction<IWorkType[]>) => {
      state.isLoading = false;
      state.error = '';
      state.workTypes = payload;
    },
    [getWorkTypes.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getInstallationInfo.pending.type]: (state) => {
      state.workInfoLoading = true;
    },
    [getInstallationInfo.fulfilled.type]: (state, { payload }: PayloadAction<InstallationInfoType[]>) => {
      state.workInfoLoading = false;
      state.error = '';
      state.installationInfo = payload;

      let index = -1;
      payload.forEach((info, i) => {
        if (info.done) index = i;
      });

      if (index === -1) {
        state.activeInstallationInfo = payload[0];
      } else if (index < payload.length - 1) {
        state.activeInstallationInfo = payload[index + 1];
      } else {
        state.activeInstallationInfo = null;
      }
    },
    [getInstallationInfo.rejected.type]: (state, action) => {
      state.workInfoLoading = false;
      state.error = action.payload;
    },

    [getDrillingInfo.pending.type]: (state) => {
      state.workInfoLoading = true;
    },
    [getDrillingInfo.fulfilled.type]: (state, { payload }: PayloadAction<DrillingInfoType>) => {
      state.workInfoLoading = false;
      state.error = '';
      state.drillingInfo = payload;
    },
    [getDrillingInfo.rejected.type]: (state, action) => {
      state.workInfoLoading = false;
      state.error = action.payload;
    },

    [nextStepStage.pending.type]: (state) => {
      state.workInfoLoading = true;
    },
    [nextStepStage.fulfilled.type]: (state, { payload }: PayloadAction<NotNullable<ChangeInstallationStepResponseType>>) => {
      state.workInfoLoading = false;
      state.error = '';

      let done = 0;
      const index = state.installationInfo.findIndex(info => info.stageTypeId === payload.workStageTypeId);
      const arrayLastIndex = state.installationInfo.length - 1;

      if (index === -1) {
        state.activeInstallationInfo = null;
      } else if (index < arrayLastIndex) {
        const info = state.installationInfo[index];
        info.done = payload.done;
        done = info.index * 20;
        state.activeInstallationInfo = state.installationInfo[index + 1];
      } else if (index === arrayLastIndex) {
        done = 100;
        state.installationInfo[index].done = payload.done;
        state.activeInstallationInfo = null;
      }

      const schedulerWell = state.schedulerWells.find(well => well.wellId === payload.wellId);
      if (schedulerWell) {
        schedulerWell.done = done;
        const work = schedulerWell.works.find(w => w.workNameAlias === 'installation');
        if (work) work.done = done;
      }
    },
    [nextStepStage.rejected.type]: (state, action) => {
      state.workInfoLoading = false;
      state.error = action.payload;
    },

    // planing

    [planingSearch.pending.type]: (state) => {
      state.isLoading = true;
    },
    [planingSearch.fulfilled.type]: (state, { payload }: PayloadAction<PlaningWellType[]>) => {
      state.isLoading = false;
      state.error = '';
      state.planingWells = payload;
      state.isDataLoaded = true;
    },
    [planingSearch.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [editPlaningWell.pending.type]: (state) => {
      state.isLoading = true;
    },
    [editPlaningWell.fulfilled.type]: (state, { payload }: PayloadAction<unknown>) => {
      state.isLoading = false;
      state.error = '';
    },
    [editPlaningWell.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export default schedulerSlice.reducer;