import options from './options';
import { getUrl } from '../utils';
import request from '../../utils/http/request';
import { ConvertorFacilityType, ConvertorWellBreadcrumbType } from './converters';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getBreadcrumbWell = async (id: string): Promise<ConvertorWellBreadcrumbType> => request.get(url(id));

export const getBreadcrumbFacility = async (body: { 'куст_ид': string, 'куст_тип': string }): Promise<ConvertorFacilityType> => request.post(url(''), body);
