import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AutoComplete, AutoCompleteCompleteEvent
} from 'primereact/autocomplete';
import { useTranslation } from 'react-i18next';

import { searchWellsByNameApi } from 'models/well/api';
import { Menu } from 'primereact/menu';
import { IWellForm } from '../../../models/well/types';
import { useClassName } from '../../../utils/cn';

export const HeaderSearch: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<IWellForm>();
  const [activeWellUid, setActiveWellUid] = useState<string | undefined>();
  const [filteredItems, setFilteredItems] = useState<IWellForm[]>();

  const menu = useRef<Menu | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const cn = useClassName('header');
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const menuItems = [
    {
      label: t('sidebar.fond-well'),
      icon: 'icon-well-menu',
      command: () => {
        if (activeWellUid) {
          navigate(`/monitoring/well/${activeWellUid}`);
          setSelected(undefined);
          setActiveWellUid(undefined);
          setFilteredItems(undefined);
        }
      }
    },
    {
      label: t('sidebar.project-doc'),
      icon: 'icon-clipboard-edit-menu',
      command: () => {
        if (activeWellUid) {
          navigate(`/projdoc/well/${activeWellUid}`);
          setSelected(undefined);
          setActiveWellUid(undefined);
          setFilteredItems(undefined);
        }
      }
    }
  ];

  const searchWellsRequest = async (name: string) => {
    setLoading(true);
    try {
      return await searchWellsByNameApi(name);
    } catch (e) {
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  const searchItem = async (event: AutoCompleteCompleteEvent) => {
    const itemsList = event.query.trim().length
      ? await searchWellsRequest(event.query.toLowerCase())
      : [];
    setFilteredItems(itemsList);
    // чтобы фокус не уходил из инпута после запроса
    inputRef?.current && inputRef.current?.focus();
  };

  const onListItemClick = (item: any) => (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setActiveWellUid(item.uid);
    menu.current?.toggle(event);
  };

  const itemTemplate = (item: any) => (
    <div
      className={cn('well-search-list-item')}
      onClick={onListItemClick(item)}
    >
      <div>
        {t('well')} {item?.wellName}
      </div>
      <div className={cn('well-search-icon')}>
        <i className="pi pi-chevron-right" />
      </div>
    </div>
  );

  return (
    <>
      <i className="pi pi-search" />
      <AutoComplete
        inputRef={inputRef}
        className={cn('well-search')}
        panelClassName={cn('well-search-autocomplete')}
        value={selected}
        suggestions={filteredItems}
        completeMethod={searchItem}
        itemTemplate={itemTemplate}
        placeholder={t('header.search-well')}
        field="wellName"
        disabled={loading}
        delay={500}
        onChange={(e) => setSelected(e.value)}
      />
      <Menu
        model={menuItems}
        className={cn('well-search-submenu')}
        popup
        ref={menu}
        id="well_search_submenu"
      />
    </>
  );
};
