import { CreateOperationType, DrillingOperationType, EditOperationType } from './types';
import { CreateDrillingFormType } from '../../pages/project-documentation/pages/well-doc/ggd/helpers';
import { ChartLine } from '../../sdk/lineChart/types';
import { formatDate } from '../../utils/date';
import { minToStringTime } from '../../utils/utils';

export const createOperationConvertor = (body: CreateDrillingFormType): CreateOperationType => ({
  typeId: body.typeId,
  note: body.note,
  depth: body.depth,
  stageId: body.stageId,
  value: body.value,
  wellboreId: body.wellboreId
});

export const editOperationConvertor = (body: CreateDrillingFormType): EditOperationType => ({
  typeId: body.typeId,
  depth: body.depth,
  stageId: body.stageId,
  value: body.value,
  id: body.id as number
});

export const generateLine = (operations: DrillingOperationType[]): ChartLine => {
  const line: ChartLine = {
    points: [],
    color: '#008850',
    pointed: {
      is: true,
      showTooltip: true,
      tooltipData: {}
    }
  };
  operations.forEach(operation => {
    const dateMs = new Date(operation.dateTimeOperationBegin).getTime();
    line.points.push(dateMs, operation.depth);
    if (line.pointed && line.pointed.tooltipData) {
      line.pointed.tooltipData[dateMs + operation.depth] = [
        {
          key: 'Этап',
          content: operation.stageName
        },
        {
          key: 'Операция',
          content: operation.typeName
        },
        {
          key: 'Дата проведения работ',
          content: formatDate(operation.dateTimeOperationBegin, 'dd.MM.yyyy HH:mm')
        },
        {
          key: 'Продолжительность',
          content: minToStringTime(operation.value) || ''
        }
      ];
    }
  });

  return line;
};