import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'primereact/button';

import { formatDate } from '../../../utils/date';
import { useClassName } from '../../../utils/cn';

type NotificationElementPropsType = {
    createdAt: string
    handleButtonClick: () => void
};

export const NotificationElement: React.FC<NotificationElementPropsType> = ({ createdAt, handleButtonClick, children }) => {
  const cn = useClassName('header-notification');
  const { t } = useTranslation('common', { keyPrefix: 'header' });

  return (
    <div className={cn('content-wrapper')}>
      {children}
      <p className={cn('content-date')}>
        {formatDate(new Date(`${createdAt}Z`), 'dd.MM.yyyy, HH:mm')}
      </p>
      <Button
        label={t('details')}
        className={`p-button-outlined ${cn('details-button')}`}
        onClick={handleButtonClick}
      />
    </div>
  );
};
