import { createAsyncThunk } from '@reduxjs/toolkit';
import * as wellLogApi from './api';
import options from './options';
import { curveNameMapper } from './constants';
import { getMnemonicsForPeriodProps } from './api';
import { MnemonicsResponse } from './types';

const { name } = options;

export const getMnemonicsForPeriod = createAsyncThunk(
  `${name}/getMnemonicsForPeriod`,
  async (params: getMnemonicsForPeriodProps, thunkApi) => {
    try {
      const halfHour = 1800001; // 30 мин
      const diffrence = params.to.getTime() - params.from.getTime();
      if (diffrence > halfHour) {
        const requestCount = 7;
        const delta = diffrence / requestCount;
        const requestList = [];

        for (let i = 1; i <= requestCount; i++) {
          const newFrom = params.from.getTime() + delta * (i - 1);
          const newTo = params.from.getTime() + delta * i;
          requestList.push(wellLogApi.getMnemonicsForPeriodApi({
            ...params,
            from: new Date(newFrom),
            to: new Date(newTo)
          }));
        }

        const response = await Promise.all(requestList);
        const finalResult: MnemonicsResponse = {
          meta: null,
          dataset: []
        };

        for (let i = 0; i < response.length; i++) {
          const res = response[i];
          if (res.meta) {
            finalResult.meta = res.meta;
          }

          if (res.dataset.length > 0 && finalResult.dataset.length === 0) {
            finalResult.dataset = res.dataset;
          } else {
            finalResult.dataset.forEach((curve, j) => {
              const finded = res.dataset.find(c => c.name === curve.name);
              if (finded) {
                curve.data = [...curve.data, ...finded.data];
              }
            });
          }
        }
        return finalResult;
      }

      return await wellLogApi.getMnemonicsForPeriodApi({ ...params, mnemonics: params.mnemonics || [] });
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

type GetLastMnemonicParams = {
    wellId: string;
    countMsPeriod: number;
    granularityMS?: number;
};
export const getLastMnemonic = createAsyncThunk(
  `${name}/getLastMnemonic`,
  async (params: GetLastMnemonicParams, thunkApi) => {
    try {
      return await wellLogApi.getLastMnemonicApi(params.wellId, params.countMsPeriod, params.granularityMS);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getMnemonicNames = createAsyncThunk(
  `${name}/getMnemonicNames`,
  async (wellId: string, thunkApi) => {
    try {
      const mnemonicsObject = await wellLogApi.getMnemonicNamesForWell(wellId);
      return Object.keys(mnemonicsObject).map(mnemonicName => ({
        name: curveNameMapper[mnemonicName] || mnemonicName,
        code: mnemonicName,
        unit: mnemonicsObject[mnemonicName]
      }));
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);