import * as yup from 'yup';

export interface IValues {
    username: string;
    password: string;
}

export const initValues: IValues = {
  username: '',
  password: ''
};

export const validationSchema: yup.SchemaOf<IValues> = yup.object({
  username: yup
    .string()
    .required('enterName'), // Введите имя
  password: yup
    .string()
    .required('enterPass') // Введите пароль
});
