import { createAsyncThunk } from '@reduxjs/toolkit';
import * as areaApi from './api';
import options from './options';
import { convertArea } from './converters';
import { WellStatus } from '../well/types';

const { name } = options;

export const getAreasForm = createAsyncThunk(
  `${name}/getAreasForm`,
  async (_, thunkApi) => {
    try {
      return await areaApi.getAreasFormApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getAreas = createAsyncThunk(
  `${name}/getAreas`,
  async (_, thunkApi) => {
    try {
      return await areaApi.getAreasFormApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getAreaDetails = createAsyncThunk(
  `${name}/getAreaDetails`,
  async (status: WellStatus[], thunkApi) => {
    try {
      return await areaApi.getAreaDetail(status);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

type GetAreaFacilityDetailsParams = {
    id: string;
    status: WellStatus[] | string[];
};

export const getAreaFacilityDetails = createAsyncThunk(
  `${name}/getAreaFacilityDetails`,
  async (params: GetAreaFacilityDetailsParams, thunkApi) => {
    try {
      return await areaApi.getAreaFacilityApi(params.id, params.status);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createAreaLinkedToField = createAsyncThunk(
  `${name}/createAreaLinkedToField`,
  async (subdivisionData: { fieldId: string, areaName: string }, thunkApi) => {
    try {
      const { fieldId, areaName } = subdivisionData;

      await areaApi.createAreaLinkedToFieldApi({
        площадь_тип: 'нефтяная',
        предпочтительный_название: areaName,
        месторождение_ид: fieldId,
        месторождение_площадь_набл_номер: 0
      });
      const areasList = await areaApi.getAreasFormApi();
      const newArea = areasList.find((area: any) => area.preferredName === areaName) || {};

      return { areasList, newArea: convertArea(newArea) };
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createArea = createAsyncThunk(
  `${name}/createArea`,
  async (areaName: string, thunkApi) => {
    try {
      return await areaApi.createAreaApi({
        площадь_тип: 'нефтяная',
        предпочтительный_название: areaName
      });
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
