import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createFasteningConstructionApi,
  createFasteningEquipmentApi,
  createFasteningSolutionApi,
  createResearchCementingApi,
  createResearchJobDataApi,
  getResearchJobDataApi,
  deleteFasConstructionApi,
  deleteFasEquipmentApi,
  deleteFasFluidApi,
  getFasConstructionsApi,
  getFasEquipmentsApi,
  getFasteningCentralizationApi,
  getFluidsApi,
  getResearchCementingApi,
  updateFasteningCentralizationApi,
  updateFasteningConstructionApi,
  updateFasteningEquipmentApi,
  updateFasteningFluidApi,
  deleteFasCentralizationApi,
  createFasteningCentralizationApi
} from 'models/fastening/api';
import {
  FasteningCalcCreateType,
  createFasteningCentralizationReq,
  createFasteningConstructionReq,
  createFasteningEquipmentReq,
  createFasteningFluidReq
} from './types';
import options from './options';
import { getJobDataToBack, getSolutionToBack } from './convertors';

const { name } = options;

export const createFasteningConstruction = createAsyncThunk(
  `${name}/createFasteningConstruction`,
  async (params: createFasteningConstructionReq, thunkAPI) => {
    try {
      return await createFasteningConstructionApi(params);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updateFasteningConstruction = createAsyncThunk(
  `${name}/updateFasteningConstruction`,
  async (params: createFasteningConstructionReq, thunkAPI) => {
    try {
      return await updateFasteningConstructionApi(params);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const createFasteningEquipment = createAsyncThunk(
  `${name}/createFasteningEquipment`,
  async (params: createFasteningEquipmentReq, thunkAPI) => {
    try {
      return await createFasteningEquipmentApi(params);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updateFasteningEquipment = createAsyncThunk(
  `${name}/updateFasteningEquipment`,
  async (params: createFasteningEquipmentReq, thunkAPI) => {
    try {
      return await updateFasteningEquipmentApi(params);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const createFasteningFluid = createAsyncThunk(
  `${name}/createFasteningFluid`,
  async (params: createFasteningFluidReq, thunkAPI) => {
    try {
      const fluidRes = await createFasteningSolutionApi(getSolutionToBack(params));
      const jobData = getJobDataToBack({
        ...params,
        fluid: fluidRes.item_uuid
      });

      return await createResearchJobDataApi(jobData);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getResearchJobData = createAsyncThunk(
  `${name}/getResearchJobData`,
  async (id: string, thunkAPI) => {
    try {
      const res = await getResearchJobDataApi(id);
      if (Array.isArray(res)) return res;

      return [];
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);


export const updateFasteningFluid = createAsyncThunk(
  `${name}/updateFasteningFluid`,
  async (params: createFasteningFluidReq, thunkAPI) => {
    try {
      return await updateFasteningFluidApi(params);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const createFasteningCalc = createAsyncThunk(
  `${name}/createFasteningCalc`,
  (body: FasteningCalcCreateType, thunkAPI) => {
    try {
      return createResearchCementingApi(body);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getFasteningCalc = createAsyncThunk(
  `${name}/getFasteningCalc`,
  async (wellId: string, thunkAPI) => {
    try {
      const res = await getResearchCementingApi(wellId);
      if (Array.isArray(res)) {
        return res[0];
      }

      thunkAPI.dispatch(
        createFasteningCalc({
          item_data: {},
          item_meta: {},
          well_uuid_outer: wellId
        })
      );
      return null;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getFasEquipments = createAsyncThunk(
  `${name}/getFasEquipments`,
  async (id: string, thunkAPI) => {
    try {
      const res = await getFasEquipmentsApi(id);
      if (Array.isArray(res)) return res;

      return [];
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getFasConstructions = createAsyncThunk(
  `${name}/getFasConstructions`,
  async (id: string, thunkAPI) => {
    try {
      const res = await getFasConstructionsApi(id);
      if (Array.isArray(res)) return res;

      return [];
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteFasConstruction = createAsyncThunk(
  `${name}/deleteFasConstruction`,
  async (itemUuid: string, thunkAPI) => {
    try {
      await deleteFasConstructionApi(itemUuid);

      return itemUuid;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteFasEquipment = createAsyncThunk(
  `${name}/deleteFasConstruction`,
  async (itemUuid: string, thunkAPI) => {
    try {
      await deleteFasEquipmentApi(itemUuid);

      return itemUuid;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteFasFluid = createAsyncThunk(
  `${name}/deleteFasConstruction`,
  async (itemUuid: string, thunkAPI) => {
    try {
      await deleteFasFluidApi(itemUuid);

      return itemUuid;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getFasFluids = createAsyncThunk(
  `${name}/getFluids`,
  async (id: string, thunkAPI) => {
    try {
      const res = await getFluidsApi(id);
      if (Array.isArray(res)) return res;

      return [];
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getFasCentralization = createAsyncThunk(
  `${name}/getFasCentralization`,
  async (id: string, thunkAPI) => {
    try {
      const res = await getFasteningCentralizationApi(id);
      if (Array.isArray(res)) return res;

      return [];
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const createFasCentralization= createAsyncThunk(
  `${name}/createFasCentralization`,
  (body: createFasteningCentralizationReq, thunkAPI) => {
    try {
      return createFasteningCentralizationApi(body);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updateFasCentralization = createAsyncThunk(
  `${name}/updateFasCentralization`,
  async (params: createFasteningCentralizationReq, thunkAPI) => {
    try {
      return await updateFasteningCentralizationApi(params);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteFasCentralization = createAsyncThunk(
  `${name}/deleteFasCentralization`,
  async (itemUuid: string, thunkAPI) => {
    try {
      return await deleteFasCentralizationApi(itemUuid);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
