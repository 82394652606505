import jwtDecode from 'jwt-decode';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { checkAuth, signIn, signOut } from './actions';
import { RefreshPayloadType, UserType } from './types';
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from './constants';

const { name } = options;

type AuthState = {
    isAuth: boolean
    user: UserType | null
    isLoading: boolean
    signInError: string
    error: string
};

const initialState: AuthState = {
  isAuth: false,
  user: null,
  isLoading: false,
  signInError: '',
  error: ''
};

export const authSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: {
    [signIn.pending.type]: (state) => {
      state.isLoading = true;
      state.signInError = '';
    },
    [signIn.fulfilled.type]: (state, { payload }: PayloadAction<RefreshPayloadType>) => {
      const { accessToken, refreshToken } = payload;
      const {
        userId, username, shortName, roles
      } = jwtDecode<any>(accessToken);

      state.user = {
        userId,
        userLogin: username,
        userName: shortName,
        userRole: roles
      };

      state.isAuth = true;
      state.isLoading = false;
      state.error = '';
      state.signInError = '';

      localStorage.setItem(TOKEN_KEY, accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    },
    [signIn.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isAuth = false;
      state.isLoading = false;
      state.user = null;
      state.signInError = action.payload;
    },
    [signOut.pending.type]: (state) => {
      state.isLoading = true;
    },
    [signOut.fulfilled.type]: (state) => {
      state.isAuth = false;
      state.user = null;
      state.isLoading = false;
      state.error = '';

      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
    },
    [signOut.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isAuth = true;
      state.isLoading = false;
      state.error = action.payload;
    },
    [checkAuth.pending.type]: (state) => {
      state.isLoading = true;
    },
    [checkAuth.fulfilled.type]: (state, { payload }: PayloadAction<RefreshPayloadType>) => {
      const { accessToken, refreshToken } = payload;
      const {
        userId, username, shortName, roles
      } = jwtDecode<any>(accessToken);

      state.user = {
        userId,
        userLogin: username,
        userName: shortName,
        userRole: roles
      };

      state.isAuth = true;
      state.isLoading = false;
      state.error = '';

      localStorage.setItem(TOKEN_KEY, accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    },
    [checkAuth.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isAuth = false;
      state.user = null;
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export default authSlice.reducer;
