import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from '../store';

type EventType = {
    type: string
    payload: any
};

type EventStateType = {
    events: EventType[]
};

const initialState: EventStateType = {
  events: []
};

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    newEvent(state, { payload }: PayloadAction<EventType>) {
      if (state.events.length >= 10) {
        state.events.unshift();
      }
      state.events.push(payload);
    },
    clearEvents(state) {
      state.events = [];
    }
  }
});

export const selectAppEvents = (state: RootStateType) => state.events.events;

export default eventsSlice.reducer;
