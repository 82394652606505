import { createAsyncThunk } from '@reduxjs/toolkit';
import * as facilityApi from './api';
import options from './options';
import { ICreateSecondStepValues } from '../../pages/project-documentation/forms/create-modal/helper';
import { createLinkedFacility2Server, createSecondStepBody2Server, facilityForm2Client } from './converters';
import {
  CreateLinkedFacilityFormType,
  FacilityFormBackType,
  GetFacilityDetailParams,
  GetFacilitySummaryBodyType
} from './types';
import { WellStatus } from '../well/types';

const { name } = options;

export const getFacilities = createAsyncThunk(
  `${name}/getFacilities`,
  async (id: number | string, thunkApi) => {
    try {
      return await facilityApi.getFacilitiesApi(id);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getFacilitiesForm = createAsyncThunk(
  `${name}/getFacilitiesForm`,
  async (_, thunkApi) => {
    try {
      return await facilityApi.getFacilitiesFormApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getFacilityDetail = createAsyncThunk(
  `${name}/getFacilityDetail`,
  async (params: GetFacilityDetailParams, thunkApi) => {
    try {
      return await facilityApi.getFacilityDetailApi(params);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

// получение данных для 2 шага добавления проект
export const getFacility2StepSummary = createAsyncThunk(
  `${name}/getFacility2StepSummary`,
  async (body: GetFacilitySummaryBodyType, thunkApi) => {
    try {
      return await facilityApi.getFacilitySummaryApi(body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

// второй шаг на форме добавления проекта
export const createOrUpdateProjectSecondStep = createAsyncThunk(
  `${name}/createOrUpdateProjectSecondStep`,
  async (body: ICreateSecondStepValues, thunkApi) => {
    try {
      return await facilityApi.createOrUpdateProjectSecondStepApi(createSecondStepBody2Server(body));
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createFacilityLinkedToFieldAndArea = createAsyncThunk(
  `${name}/createFacilityLinkedToFieldAndArea`,
  async (body: CreateLinkedFacilityFormType, thunkApi) => {
    try {
      await facilityApi.createFacilityLinkedToFieldAndAreaApi(createLinkedFacility2Server(body));
      const facilityList = await facilityApi.getFacilitiesFormApi();

      const { facilityName, facilityType } = body;
      const newFacility = facilityList
        .find((facility: any) => facility.facilityShortName === facilityName
                    && facility.facilityType === facilityType) || {};

      return { facilityList, newFacility: facilityForm2Client(newFacility as FacilityFormBackType) };
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
