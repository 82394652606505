import {
  ResearchBitBackType,
  BitCalcType, BitCalculationBack,
  BitCalcUpdateType,
  ResearchCutBackType,
  CutCalcType, CutCalculationBack,
  CutCalcUpdateType,
  EcdCalculationBack,
  ResearchEcpBackType, EcpCalcType,
  EcpCalcUpdateType,
  HandbookEquipmentBackType,
  HandbookEquipmentType,
  HydEquipmentBackType,
  HydEquipmentCreateBackType,
  HydEquipmentType,
  HydEquipmentTypes,
  MetaType,
  MetaTypeBack, PressCalculationBack,
  ResearchPressureBackType,
  PressureCalcType,
  PressureUpdateType, HydEquipmentUpdateType, HydEquipmentUpdateBackType
} from './types';
import {
  BitType,
  IBackHydraulicsResult, IFrontHydraulicsResult,
  MotorType,
  PipeType
} from '../../pages/hydraulics/pages/tabs/common/HydraulicEquipmentDialog/helper';
import { IBitForm } from '../../pages/hydraulics/pages/tabs/BitPower/helper';
import { ICutCalcForm } from '../../pages/hydraulics/pages/tabs/Cuttings/helper';
import { IPressureForm } from '../../pages/hydraulics/pages/tabs/PressureLoss/helper';
import { IEcpForm } from '../../pages/hydraulics/pages/tabs/ECP/helper';

const metaTypeToFront = (meta: MetaTypeBack | null): MetaType | null => {
  if (meta === null) return null;

  return {
    isEqual: meta.is_equal,
    nozzleCount: meta.nozzle_count,
    nozzleSizes: meta.nozzle_sizes
  };
};

const metaTypeToBack = (meta: MetaType | null): MetaTypeBack | null => {
  if (meta === null) return null;

  return {
    nozzle_sizes: meta.nozzleSizes,
    nozzle_count: meta.nozzleCount,
    is_equal: meta.isEqual
  };
};

export const hydEquipmentToBack = (body: PipeType | BitType | MotorType, hydraulicCalcId: string): HydEquipmentCreateBackType => {
  switch (body.type) {
    case HydEquipmentTypes.sdp:
    case HydEquipmentTypes.hwdp:
    case HydEquipmentTypes.ehdp:
      return {
        item_meta: null,
        item_data: null,
        type: body.type,
        muft_diameter_in: body.muftDiameterIn,
        muft_diameter_out: body.muftDiameterOut,
        name: body.name,
        length: body.length as number,
        bha_index: body.bhaIndex,
        diameter_in: body.diameterIn,
        diameter_out: body.diameterOut,
        inc_length: null,
        pressure_loss: null,
        linked_with_hydraulics_calc: hydraulicCalcId,
        unit_length: body.unitLength,
        muft_length: body.muftLength,

        tool_joint_name: body.tool_joint_name,
        material: body.material,
        collapse_resistance: body.collapse_resistance,
        density: body.density,
        makeup_torque: body.makeup_torque,
        min_yield_point: body.min_yield_point,
        poisson_ratio: body.poisson_ratio,
        steel: body.steel,
        temperature_coeff: body.temperature_coeff,
        unit_weight: body.unit_weight,
        young_modulus: body.young_modulus
      };
    case HydEquipmentTypes.bit:
      return {
        item_meta: {
          nozzle_sizes: body.nozzleSizes,
          nozzle_count: body.nozzleCount,
          is_equal: body.isEqual
        },
        item_data: null,
        type: body.type,
        name: body.name,
        length: body.length,
        bha_index: body.bhaIndex,
        diameter_in: null,
        diameter_out: body.diameterOut,
        inc_length: null,
        pressure_loss: null,
        linked_with_hydraulics_calc: hydraulicCalcId,

        tool_joint_name: '',
        material: '',
        collapse_resistance: null,
        density: null,
        makeup_torque: null,
        min_yield_point: null,
        poisson_ratio: null,
        steel: '',
        temperature_coeff: null,
        unit_weight: null,
        young_modulus: null
      };
    case HydEquipmentTypes.motor:
      return {
        item_meta: null,
        item_data: null,
        type: body.type,
        name: body.name,
        length: body.length,
        bha_index: body.bhaIndex,
        diameter_in: body.diameterIn,
        diameter_out: body.diameterOut,
        inc_length: null,
        pressure_loss: null,
        linked_with_hydraulics_calc: hydraulicCalcId,

        tool_joint_name: body.tool_joint_name,
        material: body.material,
        collapse_resistance: body.collapse_resistance,
        density: body.density,
        makeup_torque: body.makeup_torque,
        min_yield_point: body.min_yield_point,
        poisson_ratio: body.poisson_ratio,
        steel: body.steel,
        temperature_coeff: body.temperature_coeff,
        unit_weight: body.unit_weight,
        young_modulus: body.young_modulus
      };
    default:
      throw new Error('Hydraulic equipment switch get default. It action never not gonna happen, but happen');
  }
};

export const hydraulicEqToFront = (result:IBackHydraulicsResult): IFrontHydraulicsResult => ({
  itemUuid: result.item_uuid,
  rowChangedBy: result.row_changed_by,
  rowChangedDatetime: result.row_changed_date_time,
  rowCreatedBy: result.row_created_by,
  rowCreatedDatetime: result.row_created_datetime
});

export const ecpCalcFrontToBack = (body: IEcpForm, hydraulicId: string): EcpCalcUpdateType => ({
  liquid_density: body.liquidDensity,
  plastic_viscosity: body.plasticViscosity,
  flow_rate: body.flowRate,
  rock_density: body.rockDensity,
  rock_part_diameter: body.rockPartDiameter,
  rock_part_height: body.rockPartHeight,
  yield_point: body.yieldPoint,
  rop: body.rop,
  linked_with_hydraulics_calc: hydraulicId,
  use_cuttings: true,
  item_meta: null,
  item_data: null,
  well_diameter_by_user: body.wellDiameterByUser
});

export const ecpCalcBackToFront = (body: ResearchEcpBackType): EcpCalcType => ({
  depth: body.depth,
  depthStart: body.depth_start,
  depthStep: body.depth_step,
  depthStop: body.depth_stop,
  ecd: body.ecd,
  flowRate: body.flow_rate,
  ecds: body.ecds || [],
  flowRates: body.flow_rates || [],
  itemData: body.item_data,
  itemMeta: body.item_meta,
  itemUuid: body.item_uuid,
  linkedWithHydraulicsCalc: body.linked_with_hydraulics_calc,
  liquidDensity: body.liquid_density,
  mudConcentration: body.mud_concentration,
  mudTransportEfficiency: body.mud_transport_efficiency,
  mudConcentrations: body.mud_concentrations || [],
  mudTransportEfficiencies: body.mud_transport_efficiencies,
  plasticViscosity: body.plastic_viscosity,
  rockDensity: body.rock_density,
  rockPartDiameter: body.rock_part_diameter,
  rockPartHeight: body.rock_part_height,
  rop: body.rop,
  rowChangedBy: body.row_changed_by,
  rowChangedDatetime: body.row_changed_datetime,
  rowCreatedBy: body.row_created_by,
  rowCreatedDatetime: body.row_created_datetime,
  useCuttings: body.use_cuttings,
  yieldPoint: body.yield_point,
  wellDiameterByUser: body.well_diameter_by_user
});

export const calculationEcpBackToFront = (body: EcdCalculationBack, prevEcpCalc: EcpCalcType): EcpCalcType => ({
  ...prevEcpCalc,
  depth: body.depth,
  depthStart: body.depth_start,
  depthStep: body.depth_step,
  depthStop: body.depth_stop,
  ecd: body.ecd,
  flowRate: body.flow_rate,
  ecds: body.ecds || [],
  flowRates: body.flow_rates || [],
  liquidDensity: body.liquid_density,
  mudConcentration: body.mud_concentration,
  mudTransportEfficiency: body.mud_transport_efficiency,
  mudConcentrations: body.mud_concentrations || [],
  mudTransportEfficiencies: body.mud_transport_efficiencies,
  plasticViscosity: body.plastic_viscosity,
  rockDensity: body.rock_density,
  rockPartDiameter: body.rock_part_diameter,
  rockPartHeight: body.rock_part_height,
  rop: body.rop,
  useCuttings: body.use_cuttings,
  yieldPoint: body.yield_point,
  wellDiameterByUser: body.d_well
});

export const bitCalcBackToFront = (body: ResearchBitBackType): BitCalcType => ({
  flowRate: body.flow_rate,
  itemData: body.item_data,
  itemMeta: body.item_meta,
  itemUuid: body.item_uuid,
  rowChangedBy: body.row_changed_by,
  rowChangedDatetime: body.row_created_datetime,
  flowRates: body.flow_rates || [],
  hydraulicPower: body.hydraulic_power,
  hydraulicsPowers: body.hydraulic_powers || [],
  impactForce: body.impact_force,
  impactForces: body.impact_forces || [],
  linkedWithHydraulicsCalc: body.linked_with_hydraulics_calc,
  liquidDensity: body.liquid_density,
  maxPressure: body.max_pressure,
  nozzleVelocities: body.nozzle_velocities || [],
  plasticViscosity: body.plastic_viscosity,
  nozzleVelocity: body.nozzle_velocity,
  pressureLoss: body.pressure_loss,
  pressureLosses: body.pressure_losses || [],
  rowCreatedBy: body.row_created_by,
  rowCreatedDatetime: body.row_created_datetime,
  yieldPoint: body.yield_point,
  totalFlowArea: body.total_flow_area,
  wellDiameterByUser: body.well_diameter_by_user
});

export const calculationBitBackToFront = (body: BitCalculationBack, prevBitCalc: BitCalcType): BitCalcType => ({
  ...prevBitCalc,
  flowRate: body.flow_rate,
  flowRates: body.flow_rates || [],
  hydraulicPower: body.hydraulic_power,
  hydraulicsPowers: body.hydraulic_powers || [],
  impactForce: body.impact_force,
  impactForces: body.impact_forces || [],
  liquidDensity: body.liquid_density,
  maxPressure: body.max_pressure,
  nozzleVelocities: body.nozzle_velocities || [],
  plasticViscosity: body.plastic_viscosity,
  nozzleVelocity: body.nozzle_velocity,
  pressureLoss: body.pressure_loss,
  pressureLosses: body.pressure_losses || [],
  yieldPoint: body.yield_point,
  totalFlowArea: body.total_flow_area,
  wellDiameterByUser: body.d_well
});

export const bitCalcFrontToBack = (body: IBitForm, hydraulicId: string): BitCalcUpdateType => ({
  flow_rate: body.flowRate,
  liquid_density: body.liquidDensity,
  yield_point: body.yieldPoint,
  plastic_viscosity: body.plasticViscosity,
  max_pressure: body.maxPressure,
  well_diameter_by_user: body.wellDiameterByUser,
  linked_with_hydraulics_calc: hydraulicId,
  item_meta: null,
  item_data: null
});

export const calculationCutBackToFront = (body: CutCalculationBack, prevCutCalc: CutCalcType): CutCalcType => ({
  ...prevCutCalc,
  cuttingsHeights: body.cuttings_heights || [],
  rop: body.rop,
  rops: body.rops || [],
  ropStep: body.rop_step,
  flowRatesCritRop: body.flow_rates_crit_rop || [],
  ropMin: body.rop_min,
  ropMax: body.rop_max,
  depths: body.depths || [],
  flowRate: body.flow_rate,
  yieldPoint: body.yield_point,
  liquidDensity: body.liquid_density,
  flowRatesCrit: body.flow_rates_crit || [],
  incls: body.incls || [],
  plasticViscosity: body.plastic_viscosity,
  rockDensity: body.rock_density,
  rockPartDiameter: body.rock_part_diameter,
  rockPartHeight: body.rock_part_height,
  rpm: body.rpm,
  wellDiameterByUser: body.d_well,
  velocitiesCrit: body.velocities_crit || []
});

export const researchCutBackToFront = (body: ResearchCutBackType, prevCutCalc: CutCalcType | null): CutCalcType => ({
  cuttingsHeights: body.cuttings_heights || [],
  rop: body.rop,
  rops: prevCutCalc?.rops || [],
  flowRatesCritRop: body.flow_rates_crit_rop || [],
  ropMin: body.rop_min,
  ropMax: body.rop_max,
  ropStep: body.rop_step,
  depths: body.depths || [],
  flowRate: body.flow_rate,
  itemData: body.item_data,
  itemMeta: body.item_meta,
  itemUuid: body.item_uuid,
  rowChangedBy: body.row_changed_by,
  rowChangedDatetime: body.row_changed_datetime,
  rowCreatedBy: body.row_created_by,
  rowCreatedDatetime: body.row_created_datetime,
  yieldPoint: body.yield_point,
  linkedWithHydraulicsCalc: body.linked_with_hydraulics_calc,
  liquidDensity: body.liquid_density,
  flowRatesCrit: body.flow_rates_crit || [],
  incls: body.incls || [],
  plasticViscosity: body.plastic_viscosity,
  rockDensity: body.rock_density,
  rockPartDiameter: body.rock_part_diameter,
  rockPartHeight: body.rock_part_height,
  rpm: body.rpm,
  velocitiesCrit: body.velocities_crit || [],
  volumeExcessFactor: body.volume_excess_factor,
  wellDiameterByUser: body.well_diameter_by_user,
  wellDiameterCalced: body.well_diameter_by_user

});
export const cutCalcFrontToBack = (body: ICutCalcForm, hydraulicId: string): CutCalcUpdateType => ({
  incls: body.incls,
  rop: body.rop,
  rop_min: body.ropMin,
  rop_max: body.ropMax,
  rop_step: body.ropStep,
  rpm: body.rpm,
  item_data: null,
  depths: body.depths,
  item_meta: null,
  flow_rate: body.flowRate,
  liquid_density: body.liquidDensity,
  plastic_viscosity: body.plasticViscosity,
  rock_density: body.rockDensity,
  rock_part_diameter: body.rockPartDiameter,
  rock_part_height: body.rockPartHeight,
  volume_excess_factor: body.volumeExcessFactor,
  well_diameter_by_user: body.wellDiameterByUser,
  well_diameter_calced: body.wellDiameterCalced,
  yield_point: body.yieldPoint,
  linked_with_hydraulics_calc: hydraulicId
});

export const pressureBackToFront = (body: ResearchPressureBackType): PressureCalcType => ({
  bitPressureLosses: body.bit_pressure_losses || [],
  casingPressureLosses: body.casing_pressure_losses || [],
  itemData: body.item_data,
  itemMeta: body.item_meta,
  itemUuid: body.item_uuid,
  rowChangedBy: body.row_changed_by,
  rowChangedDatetime: body.row_changed_datetime,
  rowCreatedBy: body.row_created_by,
  rowCreatedDatetime: body.row_changed_datetime,
  flowRates: body.flow_rates || [],
  linkedWithHydraulicsCalc: body.linked_with_hydraulics_calc,
  liquidDensity: body.liquid_density,
  maxPressure: body.max_pressure,
  plasticViscosity: body.plastic_viscosity,
  yieldPoint: body.yield_point,
  wellDiameterByUser: body.well_diameter_by_user,
  systemPressureLosses: body.system_pressure_losses || [],
  tubePressureLosses: body.tube_pressure_losses || []
});

export const calculationPressBackToFront = (
  body: PressCalculationBack,
  prevPressureCalc: PressureCalcType
): PressureCalcType => ({
  ...prevPressureCalc,
  bitPressureLosses: body.bit_pressure_losses || [],
  casingPressureLosses: body.casing_pressure_losses || [],
  flowRates: body.flow_rates || [],
  liquidDensity: body.liquid_density,
  maxPressure: body.max_pressure,
  plasticViscosity: body.plastic_viscosity,
  systemPressureLosses: body.system_pressure_losses || [],
  tubePressureLosses: body.tube_pressure_losses || [],
  yieldPoint: body.yield_point,
  wellDiameterByUser: body.d_well,
  tube_pressure_loss: body.tube_pressure_loss,
  casing_pressure_loss: body.casing_pressure_loss,
  system_pressure_loss: body.system_pressure_loss
});

export const pressureFrontToBack = (body: IPressureForm, hydraulicId: string): PressureUpdateType => ({
  item_data: null,
  max_pressure: body.maxPressure,
  item_meta: null,
  liquid_density: body.liquidDensity,
  plastic_viscosity: body.plasticViscosity,
  yield_point: body.yieldPoint,
  well_diameter_by_user: body.wellDiameterByUser,
  linked_with_hydraulics_calc: hydraulicId
});

export const handbookToFront = (handbooks: HandbookEquipmentBackType[]): HandbookEquipmentType[] =>
  handbooks.map(body => ({
    name: body.name,
    code: body.code,
    itemData: body.item_data,
    itemMeta: body.item_meta,
    itemUuid: body.item_uuid,
    rowChangedBy: body.row_changed_by,
    rowChangedDatetime: body.row_changed_datetime,
    rowCreatedBy: body.row_created_by,
    rowCreatedDatetime: body.row_created_datetime
  }));

export const hydEquipmentToFront = (body: HydEquipmentBackType): HydEquipmentType => ({
  type: body.type,
  name: body.name,
  length: body.length,
  bhaIndex: body.bha_index,
  diameterIn: body.diameter_in,
  diameterOut: body.diameter_out,
  itemData: body.item_data,
  itemMeta: metaTypeToFront(body.item_meta),
  muftDiameterIn: body.muft_diameter_in,
  muftDiameterOut: body.muft_diameter_out,
  itemUuid: body.item_uuid,
  rowChangedBy: body.row_changed_by,
  rowChangedDatetime: body.row_changed_datetime,
  rowCreatedBy: body.row_created_by,
  rowCreatedDatetime: body.row_changed_datetime,
  incLength: body.inc_length,
  pressureLoss: body.pressure_loss,
  linkedWithHydraulicsCalc: body.linked_with_hydraulics_calc,
  unitLength: body.unit_length,
  muftLength: body.muft_length,

  tool_joint_name: body.tool_joint_name,
  material: body.material,
  collapse_resistance: body.collapse_resistance,
  density: body.density,
  makeup_torque: body.makeup_torque,
  min_yield_point: body.min_yield_point,
  poisson_ratio: body.poisson_ratio,
  steel: body.steel,
  temperature_coeff: body.temperature_coeff,
  unit_weight: body.unit_weight,
  young_modulus: body.young_modulus
});

// export const calculationToEcdCalc = ({result}: CalculationEcdBackType): EcpCalcType => ({
//     depth: result.depth,
//     rop: result.rop,
//     ecd: result.ecd,
//     flowRate: result.flow_rate,
//     ecds: result.ecds,
//     flowRates: result.flow_rates,
//     itemData: null,
//     rockPartDiameter: result.rock_part_diameter,
//     rockPartHeight: result.rock_part_height,
//     liquidDensity: result.liquid_density,
//     mudConcentration: result.mud_concentration,
//     mudTransportEfficiency: result.mud_transport_efficiency,
//     mudConcentrations: result.mud_concentrations,
//     mudTransportEfficiencies: result.mud_transport_efficiencies,
//     plasticViscosity: result.plastic_viscosity,
//     rockDensity: result.rock_density,
//     yieldPoint: result.yield_point,
//     itemUuid: '',
//     useCuttings: result.use_cuttings,
//     depthStop: result.
// });

export const equipmentUpdateToBack = (body: HydEquipmentUpdateType): HydEquipmentUpdateBackType => ({
  item_data: body.itemData,
  item_meta: metaTypeToBack(body.itemMeta),
  muft_diameter_in: body.muftDiameterIn,
  muft_diameter_out: body.muftDiameterOut,
  bha_index: body.bhaIndex,
  diameter_in: body.diameterIn,
  diameter_out: body.diameterOut,
  inc_length: body.incLength,
  type: body.type,
  name: body.name,
  length: body.length,
  linked_with_hydraulics_calc: body.linkedWithHydraulicsCalc,
  pressure_loss: body.pressureLoss,
  unit_length: body.unitLength,
  muft_length: body.muftLength,

  tool_joint_name: body.tool_joint_name,
  material: body.material,
  collapse_resistance: body.collapse_resistance,
  density: body.density,
  makeup_torque: body.makeup_torque,
  min_yield_point: body.min_yield_point,
  poisson_ratio: body.poisson_ratio,
  steel: body.steel,
  temperature_coeff: body.temperature_coeff,
  unit_weight: body.unit_weight,
  young_modulus: body.young_modulus
});
