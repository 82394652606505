import { MenuItem } from 'primereact/menuitem';

export type BreadcrumbStateType = {
    breadcrumbs: IBreadcrumbElement[];
    isLoading: boolean;
    error: string;
    fromArea: boolean;
    originalWellBread: IWellBreadcrumb;
};

export interface IBreadcrumbElement extends MenuItem {
    label: string;
    to: string;
    type?: BreadcrumbType
}

export enum BreadcrumbType {
    facility = 'facility', // куст
    field = 'field', // месторождение
    well = 'well', // скважина
    area = 'area' // НГДУ (Площадь)
}

export interface IWellBreadcrumb {
    wellId: string;
    facilityId: string;
    facilityType: string;
    facilityName: string;
    areaId: string;
    areaType: string;
    areaName: string;
    fieldId: string;
    fieldName: string;
    wellName: string;
}

export interface IFacilityBreadcrumb {
    facilityName: string;
    facilityType: string;
    facilityId: string;
    areaId: string;
    areaType: string;
    areaName: string;
    fieldId: string;
    fieldName: string;
}
