import {
  AreaDetailType, AreaFacilityDetails, AreaFormBackType, AreaFormType, AreaType
} from './types';
import { FacilityType } from '../facility/types';
import { IWellCommon } from '../well/types';

export const rus2eng = (areas: AreaFormBackType[]): AreaFormType[] => areas.map((area) => ({
  uid: area.areaId,
  areaName: area.preferredName || '',
  areaType: area.areaType
}));

export const convertArea = (area: any) => ({
  uid: area.areaId || '',
  areaName: area.preferredName || '',
  globalId: area.ppdmGuid || '',
  areaType: area.areaType || ''
});

export const convertAreas = (areas: any): AreaType[] => areas.map(convertArea);

export const areaDetailToClient = (areas: AreaDetailType[]): AreaDetailType[] => {
  const finalArr: AreaDetailType[] = [];
  areas.forEach((area: AreaDetailType) => {
    const facRiskCount = area.facilities?.reduce((acc: number, curr: FacilityType) => (curr.incidents?.length ? acc + curr.incidents.length : acc), 0) || 0;

    const obj = {
      ...area,
      uid: `${area.areaType}&&${area.areaId}`,
      wellRisk: facRiskCount,
      wellRiskDetail: area.wellRiskDetail || [],
      facilities: area.facilities || [],
      ropAverage: Number(area.ropAverage),
      wellCount: Number(area.wellCount)
    };

    obj.facilities = obj.facilities.map(facility => ({
      ...facility,
      wellRisk: facility.incidents?.length || 0,
      facilityName: facility.facilityName || 'Безымянный',
      uid: `${facility.facilityType}&&${facility.facilityId}`
    }));

    finalArr.push(obj);
  });

  return finalArr;
};

export const convertAreaFacDetail = (areas: AreaFacilityDetails): AreaFacilityDetails => {
  areas.facilities.forEach(facility => {
    const wellRiskCount = facility.wellsList
      ? facility.wellsList.reduce((acc: number, curr: IWellCommon) => (curr.incidentInfo?.length ? acc + 1 : acc), 0) : facility.incidents ? facility.incidents.length : 0;

    facility.wellRisk = wellRiskCount;
    facility.uid = `${facility.facilityType}&&${facility.facilityId}`;
    facility.facilityName = `Куст ${facility.facilityName}`;
  });

  return areas;
};
