import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import {
  createDrillingOperation, createDrillingType, deleteOperation, deleteSomeOperation, editOperation,
  getDrillingOperation,
  getDrillingOperationType,
  getDrillingStageOptions, getProjectDate, reorderOperation, updateProjectDate
} from './actions';
import {
  DrillingOperationType,
  EffectiveDateType, IStageOptions, OperationTypes
} from './types';
import { IWellBoreByWell } from '../well/types';
import { CreateDrillingFormType } from '../../pages/project-documentation/pages/well-doc/ggd/helpers';
import { ChartLine } from '../../sdk/lineChart/types';
import { generateLine } from './convertor';

const { name } = options;

type GgdState = {
  isLoading: boolean;
  error: string;
  stageOptions: IStageOptions[];
  operationOptions: OperationTypes[];
  drillingOperations: (DrillingOperationType | CreateDrillingFormType | any)[];
  wellsAndWellBores: IWellBoreByWell[];
  dateError: string;
  effectiveDateStr: string | null;
  allPageLoading: boolean;
  drillingOperationChart: ChartLine[];
  operationType: CreateDrillingFormType | null
};

const initialState: GgdState = {
  isLoading: false,
  error: '',
  stageOptions: [],
  operationOptions: [],
  drillingOperations: [],
  wellsAndWellBores: [],
  dateError: '',
  effectiveDateStr: null,
  allPageLoading: false,
  drillingOperationChart: [],
  operationType: null
};

export const ggdSlice = createSlice({
  name,
  initialState,
  reducers: {
    addDrillingOperation(state, action: PayloadAction<CreateDrillingFormType>) {
      state.drillingOperations.unshift(action.payload);
    },
    removeNewOperation(state) {
      const index = state.drillingOperations.findIndex(operation => operation.newOperation);
      if (index > -1) {
        state.drillingOperations.splice(index, 1);
      }
    },
    editOperation(state, action: PayloadAction<any>) {
      const index = state.drillingOperations.findIndex(op => op.id === action.payload.id);
      if (index > -1) {
        state.drillingOperations[index] = action.payload;
      }
    }
  },
  extraReducers: {
    [getDrillingStageOptions.pending.type]: (state: GgdState) => {
      state.isLoading = true;
    },
    [getDrillingStageOptions.fulfilled.type]: (state: GgdState, action: PayloadAction<IStageOptions[]>) => {
      state.isLoading = false;
      state.stageOptions = action.payload;
    },
    [getDrillingStageOptions.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getDrillingOperationType.pending.type]: (state: GgdState) => {
      state.isLoading = true;
    },
    [getDrillingOperationType.fulfilled.type]: (state: GgdState, action: PayloadAction<OperationTypes[]>) => {
      state.isLoading = false;
      state.operationOptions = action.payload;
    },
    [getDrillingOperationType.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getDrillingOperation.pending.type]: (state: GgdState) => {
      state.isLoading = true;
    },
    [getDrillingOperation.fulfilled.type]: (state: GgdState, action: PayloadAction<DrillingOperationType[]>) => {
      state.isLoading = false;
      state.error = '';
      state.drillingOperations = action.payload;
      state.drillingOperationChart = [generateLine(action.payload)];
    },
    [getDrillingOperation.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getProjectDate.pending.type]: (state) => {
      state.allPageLoading = true;
    },
    [getProjectDate.fulfilled.type]: (state, { payload }: PayloadAction<EffectiveDateType>) => {
      state.allPageLoading = false;
      state.dateError = '';
      state.effectiveDateStr = payload.effectiveDate || null;
    },
    [getProjectDate.rejected.type]: (state, action: PayloadAction<string>) => {
      state.allPageLoading = false;
      state.dateError = action.payload;
    },

    [updateProjectDate.pending.type]: (state) => {
      state.isLoading = true;
    },
    [updateProjectDate.fulfilled.type]: (state, { payload }: PayloadAction<EffectiveDateType>) => {
      state.isLoading = false;
      state.error = '';
      state.effectiveDateStr = payload.effectiveDate || null;
    },
    [updateProjectDate.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [createDrillingOperation.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createDrillingOperation.fulfilled.type]: (state, { payload }: PayloadAction<DrillingOperationType[]>) => {
      state.isLoading = false;
      state.error = '';
      state.drillingOperations = payload;
      state.drillingOperationChart = [generateLine(payload)];
    },
    [createDrillingOperation.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [deleteOperation.pending.type]: (state) => {
      state.isLoading = true;
    },
    [deleteOperation.fulfilled.type]: (state, { payload }: PayloadAction<number>) => {
      state.isLoading = false;
      state.error = '';
      const index = state.drillingOperations.findIndex(operation => operation.id === payload);
      if (index > -1) {
        state.drillingOperations.splice(index, 1);
      }
    },
    [deleteOperation.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [deleteSomeOperation.pending.type]: (state) => {
      state.isLoading = true;
    },
    [deleteSomeOperation.fulfilled.type]: (state, { payload }: PayloadAction<number[]>) => {
      state.isLoading = false;
      state.error = '';
      payload.forEach(id => {
        const index = state.drillingOperations.findIndex(operation => operation.id === id);
        if (index > -1) {
          state.drillingOperations.splice(index, 1);
        }
      });
    },
    [deleteSomeOperation.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [editOperation.pending.type]: (state) => {
      state.isLoading = true;
    },
    [editOperation.fulfilled.type]: (state, { payload }: PayloadAction<DrillingOperationType[]>) => {
      state.isLoading = false;
      state.error = '';
      state.drillingOperations = payload;
      state.drillingOperationChart = [generateLine(payload)];
    },
    [editOperation.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [reorderOperation.pending.type]: (state) => {
      state.isLoading = true;
    },
    [reorderOperation.fulfilled.type]: (state, { payload }: PayloadAction<DrillingOperationType[]>) => {
      state.isLoading = false;
      state.error = '';
      state.drillingOperations = payload;
    },
    [reorderOperation.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [createDrillingType.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createDrillingType.fulfilled.type]: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.operationType = payload;
      state.error = '';
    },
    [createDrillingType.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export default ggdSlice.reducer;
