import { format } from 'date-fns';

/**
 * Функция перевода Date в нужный формат
 *
 * @param date - объект Date или строка
 * @param dateFormat - нужный формат (например: 'dd.MM.yyyy', 'dd.MM.yyyy HH:mm:ss')
 * @param returnValue - значение которое будет возвращаться, по дефолту null, если дата пустая
 */

export function formatDate(date: any, dateFormat: string, returnValue: any = null) {
  if (!date) return returnValue;

  return format(new Date(date), dateFormat);
}
