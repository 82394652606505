import { getUrl } from '../utils';
import options from './options';
import request from '../../utils/http/request';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const createUserApi = async (body: any): Promise<any> => {
  return request.post(url('users'), body);
};
