import { UserRoleType } from './types';

export const TOKEN_KEY = 'dd_token';
export const REFRESH_TOKEN_KEY = 'dd_refresh_token';

export const USER = 1;
export const EXPERT = 2;
export const ADMIN = 3;
// todo моковые роли
// export const WELL = 2
// export const DRILLING = 3;
export const GEOSTEERING = 3;
export const SUPERVISOR = 3;
export const HEAD = 3;
export const GUEST = 7;

export const ROLES: UserRoleType[] = [
  {
    uid: USER,
    shortDescription: 'Пользователь',
    name: 'STAR_LORD'
  },
  {
    uid: EXPERT,
    shortDescription: 'Начальник смены',
    name: 'MASTER'
  },
  {
    uid: ADMIN,
    shortDescription: 'Администратор',
    name: 'ADMIN'
  },
  {
    uid: GUEST,
    shortDescription: 'Гость',
    name: 'GUEST'
  }
];
