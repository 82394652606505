import {
  ProjIncidentAddForm
} from '../../pages/project-documentation/pages/well-doc/project-incident/ProjAddIncidentModal/helper';
import { StratUnitType } from '../stratigraphy/types';

export interface IResponseType {
    actionType: string;
    longName: string;
}

export type IncidentSet = {
    incidentSetId: string;
    fullName: string;
};

export type IncidentType = {
    incidentSetId: string;
    incidentTypeId: string;
    description: string;
};

export type IncidentSubstance = {
    incident_substance: string;
    longName: string;
};

export type CriticalityType = {
    id: string;
    name: string;
    rusName: string;
};

export type IncidentResult = {
    response_result: string;
    abbreviation: string;
    longName: string;
};

// backend enum, front format
// api ↓ for checking is enum right
// api/incident/incidentrespresult
export enum Effective {
    YES = '1',
    NO = '2'
}

export enum IncidentKind {
    FACT = 'FACT',
    PLAN = 'PLAN'
}

export type IncidentSubstanceGet = {
    incidentId: string;
    responseObsNo: number;
    incidentSubstance: string;
    substanceSeqNo: number;
    volume: number;
    volumeUom: string;
};

export type IncidentResponse = {
    incidentId: string;
    responseObsNo: number;
    effectiveDate: number[];
    responseType: string;
    responseResult: string;
    hseIncidentSubstanceList: IncidentSubstanceGet[];
};

export type IncidentDetail = {
    incidentId: string;
    detailObsNo: number;
    incidentSetId: string;
    incidentSetName: string;
    incidentTypeName: string;
    incidentTypeId: string;
    criticalityTypeRusName: string;
    criticalityType: string;
};

export type Incident = {
    incidentId: string;
    incidentDate: number;
    verticalDepth: number;
    measuredDepth: number;
    incidentDateEnd: number;
    hseIncidentResponseList: IncidentResponse[];
    stratUnitDescriptionDto: StratUnitType[];
    incidentDetail: IncidentDetail[];
    liquidated: boolean;
    supervisor: string;
    incidentKind: IncidentKind;
    uwi: string;
};

export type FactIncidentSubstance = {
    incidentSubstance: string;
    volume: number;
    incidentId: string;
    substanceName: string;
    volumeUom?: string;
};

export type FactIncidentResponse = {
    responseType: string;
    responseTypeName: string;
    incidentId: string;
    hseIncidentSubstanceList: FactIncidentSubstance[];
};

export type FactIncident = Omit<Incident, 'incidentKind'> & { incidentKind: IncidentKind.FACT };

export type FactWithoutLiq = Omit<FactIncident, 'liquidated'>;

export type PlanIncident = Omit<Incident, 'verticalDepth' | 'measuredDepth' | 'liquidated' | 'incidentDate' |
    'incidentDateEnd' | 'hseIncidentResponseList' | 'incidentKind'> & {
    tvdMinValue: number;
    tvdMaxValue: number;
    hseIncidentResponseList: FactIncidentResponse[];
    incidentKind: IncidentKind.PLAN;
};

export type EditPlanIncident = ProjIncidentAddForm & { incidentId: string };

export type UnknownIncident = Omit<Incident, 'liquidated'>;

export type PlanIncidentCreate = Omit<ProjIncidentAddForm, 'incidentDuration'> & { incidentDuration: number };

export type CloseIncidentType = {
    incidentDateEnd: Date;
    incidentId: string;
};

export type UnitOfMeasureType = {
    uomId: string,
    uomName: string;
};

export type IncidentInterval = {
    from: number;
    to: number;
    wellId: string;
};