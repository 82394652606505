import request from 'utils/http/request';
import {
  CreateEquipmentType, CreateEquipmentTypeType,
  CreateNewTypeAndCatEquipmentType,
  CreateTubularAssemblyBodyType,
  DrillingMudNoAssembly,
  EquipmentAssemblyType, EquipmentGradeType,
  EquipmentGroupType, EquipmentMaterialType, EquipmentType,
  EquipmentTypeType,
  GetEquipmentListParams,
  GetEquipmentTypeListParams,
  IDrillingMudResponseType,
  IDrillingMudType, MaterialInfoType,
  TubularAssemblyType
} from './types';
import { getUrl } from '../utils';
import options from './options';

const { serviceUrl, apiUrl } = options;

const url = getUrl(serviceUrl);
const ddUrl = getUrl(apiUrl);

// export const getEquipmentListByTypeAbbrApi = async (groupAbbr: string, typeAbbr?: string): Promise<EquipmentType[]> => {
//     const getTypeAbbrParam = typeAbbr ? `&type-abbr=${typeAbbr}` : '';
//     return request.get(ddUrl(`?group-abbr=${groupAbbr}${getTypeAbbrParam}`));
// };

export const getEquipmentTypeListApi = async (params: GetEquipmentTypeListParams): Promise<EquipmentTypeType[]> => request.get(ddUrl('type'), {
  params
});

export const getEquipmentGroupListApi = async (): Promise<EquipmentGroupType[]> => request.get(ddUrl('cat-equipment-group'));

// сборки
export const getAssembliesByTubularIdApi = async (id: number): Promise<TubularAssemblyType[]> => request.get(ddUrl(`tubular-assembly/tubular-global-id/${id}`));

export const deleteAssembliesByTubularIdApi = async (id: number): Promise<number> => {
  await request.delete(ddUrl(`tubular-assembly/tubular-global-id/${id}`));
  return id;
};

export const getAssemblyByIdApi = async (assemblyId: string): Promise<TubularAssemblyType> => request.get(ddUrl(`tubular-assembly/${assemblyId}`));

export const updateAssemblyByIdApi = async (assemblyId: string, body: CreateTubularAssemblyBodyType): Promise<TubularAssemblyType> => request.put(ddUrl(`tubular-assembly/${assemblyId}`), body);

export const createAssemblyByTubularIdApi = async (body: CreateTubularAssemblyBodyType): Promise<TubularAssemblyType> => request.post(ddUrl('tubular-assembly'), body);

export const deleteAssemblyByIdApi = async (assemblyId: string): Promise<string> => {
  await request.delete(ddUrl(`tubular-assembly/${assemblyId}`));
  return assemblyId;
};

// детали сборки
export const updateAssemblyDetailByIdApi = async (assemblyId: string, detailId: string, body: EquipmentAssemblyType): Promise<EquipmentAssemblyType> => request.put(url(`proxy/tubular-assembly/tubular-assembly-id/${assemblyId}/equipment/${detailId}`), body);

export const deleteAssemblyDetailByIdApi = async (assemblyId: string, detailId: string): Promise<string> => {
  await request.delete(url(`proxy/tubular-assembly/tubular-assembly-id/${assemblyId}/equipment/${detailId}`));
  return detailId;
};

// буровые растворы
export const getDrillingMudsByTubularIdApi = async (id: number): Promise<DrillingMudNoAssembly[]> => request.get(ddUrl(`drilling-mud/findAllByTubularGlobalId/${id}`));

export const createDrillingMudApi = async (body: IDrillingMudType): Promise<DrillingMudNoAssembly> => request.post(ddUrl('drilling-mud'), body);

export const createDrillingMudWithCopyApi = async (body: IDrillingMudType): Promise<DrillingMudNoAssembly> => request.post(ddUrl('drilling-mud/createWithCopy'), body);

export const updateDrillingMudByTubularIdApi = async (mudId: string, body: IDrillingMudType): Promise<IDrillingMudResponseType> => request.put(ddUrl(`drilling-mud/${mudId}`), body);

export const createNewTypeAndCatEquipmentApi = async (body:CreateNewTypeAndCatEquipmentType): Promise<EquipmentTypeType> => request.post(ddUrl('cat-equipment-type/createNewTypeAndCatEquipment'), body);

export const deleteDrillingMudByIdApi = async (mudId: string): Promise<IDrillingMudResponseType> => request.delete(ddUrl(`drilling-mud/${mudId}`));

export const deleteAllDrillingMudsByIdApi = async (id: number): Promise<number> => request.delete(ddUrl(`drilling-mud/tubular-global-id/${id}`));

// Забойное оборудование

export const createEquipmentApi = (body: CreateEquipmentType): Promise<EquipmentType> => request.post(ddUrl(''), body);

export const editEquipmentApi = (id: string, body: CreateEquipmentType): Promise<EquipmentType> => request.put(ddUrl(id), body);

export const deleteEquipmentApi = (id: string) => request.delete(ddUrl(id));

export const getEquipmentListApi = async (params: GetEquipmentListParams): Promise<EquipmentType[]> => request.get(ddUrl(''), {
  params: {
    'group-abbr': params.groupAbbr,
    'type-abbr': params.typeAbbr
  }
});

export const createEquipmentTypeApi = async (body: CreateEquipmentTypeType): Promise<EquipmentTypeType> => request.post(ddUrl('type'), body);

export const getEquipmentMaterialApi = async (): Promise<EquipmentMaterialType[]> => request.get(ddUrl('material'));

export const getGradeByMaterialIdApi = async (materialId: number | string): Promise<EquipmentGradeType[]> => request.get(ddUrl(`grade/findAllGradeByMaterialId/${materialId}`));

export const getGradeMaterialInfoApi = async (gradeId: number | string): Promise<MaterialInfoType> => request.get(ddUrl(`grade/getGradeMaterialInfo/${gradeId}`));