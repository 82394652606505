import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { addLocale, locale } from 'primereact/api';
import resources from './locales/resources';

// const missingKeys: {[key: string]: string} = {};

const initOptions: InitOptions = {
  // lng: "ru",
  resources,
  fallbackLng: 'ru',
  defaultNS: 'home',
  ns: Object.keys(resources.ru), // ['project-doc-home', 'monitoring'],
  supportedLngs: Object.keys(resources), // ['ru', 'en'],
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  debug: process.env.NODE_ENV === 'development'
  // если нужно загружать асинхронно (еще нужно скачать плагин i18next-http-backend)
  // backend: {
  //     loadPath: './locales/{{lng}}/{{ns}}.json',
  //     allowMultiLoading: false
  // }
};

addLocale('ru', {
  accept: 'Да',
  reject: 'Нет',
  choose: 'Выберите',
  upload: 'Загрузить',
  cancel: 'Отмена',
  dayNames: [
    'понедельник',
    'вторник',
    'среда',
    'четверг',
    'пятница',
    'суббота',
    'воскресенье'
  ],
  dayNamesShort: ['Вск', 'Пнд', 'Втр', 'Срд', 'Чтв', 'Птн', 'Сбт'],
  dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ],
  monthNamesShort: [
    'Янв',
    'Фев',
    'Мар',
    'Апр',
    'Май',
    'Июн',
    'Июл',
    'Авг',
    'Сен',
    'Окт',
    'Ноя',
    'Дек'
  ],
  today: 'Сегодня',
  clear: 'Очистить',
  weekHeader: 'Нед.',
  firstDayOfWeek: 1,
  dateFormat: 'dd.mm.yy',
  weak: 'Простой',
  medium: 'Нормальный',
  strong: 'Хороший',
  passwordPrompt: 'Введите пароль'
});

i18n.on('languageChanged', lng => {
  locale(lng);
});

i18n
  .use(initReactI18next)
  .init(initOptions)
  .catch(err => {
    throw err;
  });

export default i18n;
