import React, { FC, useEffect, useState } from 'react';
import { AutoComplete, AutoCompleteSelectEvent } from 'primereact/autocomplete';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useClassName } from '../../../utils/cn';
import { selectBookmarkedWellList } from '../../../models/well/selectors';
import { deleteWellFromFavorites, getBookmarkedWellsByCurrentUser } from '../../../models/well/actions';
import { IBookmarkedField, IBookmarkedWell } from '../../../models/well/types';

export const HeaderBookmarkedAutocomplete: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.models.auth.user);
  const bookmarkedWells = useAppSelector(selectBookmarkedWellList);
  const [filteredItems, setFilteredItems] = useState<any>(bookmarkedWells);
  const [selected, setSelected] = useState<any>(null);
  const { t } = useTranslation('common');
  const cn = useClassName('header');

  const searchItems = (event: { query: string }) => {
    const { query } = event;
    const filteredResult: any[] = [];

    bookmarkedWells.forEach(bookmarked => {
      const filteredBookmarks = bookmarked.wellPickupResponseDtos
        .filter((markedWell) => markedWell.wellName.toString().toLowerCase()
          .indexOf(query.toLowerCase()) !== -1);

      if (filteredBookmarks && filteredBookmarks.length) {
        filteredResult.push({ ...bookmarked, wellPickupResponseDtos: filteredBookmarks });
      }
    });

    setFilteredItems(filteredResult);
  };

  const SelectedItemTemplate = (items: string) => (
    <div>{items}</div>
  );

  useEffect(() => {
    dispatch(getBookmarkedWellsByCurrentUser());
  }, []);

  const groupedItemTemplate = (wells: IBookmarkedField) => (
    <div>
      <span>{wells.fieldName}</span>
    </div>
  );

  const onStarButtonClick = async (e: React.MouseEvent<HTMLElement>, uid: string) => {
    e.stopPropagation();
    dispatch(deleteWellFromFavorites({
      uid,
      user: user?.userLogin
    })).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        dispatch(getBookmarkedWellsByCurrentUser());
      }
    });
  };

  const ItemTemplate = (well: IBookmarkedWell) => (
    <div className={cn('dropdown-fields')}>
      <i
        className="pi pi-star-fill"
        onClick={e => onStarButtonClick(e, well.uid)}
      />
      {t('common:well')} {well.wellName}
    </div>
  );

  useEffect(() => {
    setFilteredItems(bookmarkedWells);
  }, [bookmarkedWells]);

  const onBookmarkedSelect = (e: AutoCompleteSelectEvent) => {
    setSelected(null);

    const location = {
      pathname: `/monitoring/well/${e.value.uid}`,
      state: { fromDashboard: true }
    };
    navigate(location);
  };

  return (
    <AutoComplete
      className={cn('favorite-search')}
      value={selected}
      suggestions={filteredItems}
      completeMethod={searchItems}
      field="fieldName"
      optionGroupLabel="wellName"
      placeholder={t('header.fav-wells')}
      optionGroupChildren="wellPickupResponseDtos"
      optionGroupTemplate={groupedItemTemplate}
      dropdown
      itemTemplate={ItemTemplate}
      selectedItemTemplate={SelectedItemTemplate}
      forceSelection
      onSelect={onBookmarkedSelect}
      onChange={(e) => setSelected(e.value)}
    />

  );
};
