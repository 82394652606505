import { createAsyncThunk } from '@reduxjs/toolkit';
import * as calcApi from './api';
import options from './options';

const { name } = options;

export const getAllTrajectoryCalc = createAsyncThunk(
  `${name}/getAllTrajectoryCalc`,
  async (_, thunkApi) => {
    try {
      return await calcApi.getAllTrajectoryCalcApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getTrajectoryCalcByUid = createAsyncThunk(
  `${name}/getTrajectoryCalcByUid`,
  async (uid: string, thunkApi) => {
    try {
      return await calcApi.getTrajectoryCalcByUidApi(uid);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const startTrajectoryCalc = createAsyncThunk(
  `${name}/startTrajectoryCalc`,
  async (body: FormData, thunkApi) => {
    try {
      return await calcApi.startTrajectoryCalcApi(body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteTrajectoryCalcByUid = createAsyncThunk(
  `${name}/deleteTrajectoryCalcByUid`,
  async (uid: string, thunkApi) => {
    try {
      return await calcApi.deleteTrajectoryCalcByUidApi(uid);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const exportTrajectoryCalcByUid = createAsyncThunk(
  `${name}/exportTrajectoryCalcByUid`,
  async ({ uid, name }: { uid: string, name: string }, thunkApi) => {
    try {
      return await calcApi.exportTrajectoryCalcByUidApi(uid, name);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
