import axios from 'axios';
import { SignInBody, RefreshPayloadType } from './types';
import { getUrl } from '../utils';
import options from './options';
import request from '../../utils/http/request';
import { getRefreshToken } from '../../utils/http/utils';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const signInApi = async (body: SignInBody): Promise<RefreshPayloadType> => {
  const response = await axios.post(url('sign-in'), body);
  return response.data;
};

export const signOutApi = async (login: string): Promise<{}> => {
  return request.post(url('sign-out'));
};

export const checkAuthApi = async (): Promise<{}> => {
  const response = await axios.post(url('refresh-token'), { refreshToken: getRefreshToken() });
  return response.data;
};
