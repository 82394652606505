import { createAsyncThunk } from '@reduxjs/toolkit';
import * as wellsApi from './api';
import options from './options';
import { facilitySlice } from '../facility/slice';
import {
  CoordinateSystemType,
  CreateProjectFourthStepFormType, CreateProjectThirdStepFormType, IBookmarkedField, UpdateWellStatusType, WellProjectType
} from './types';
import { createFourthStepBody2Server, createThirdStepBody2Server } from './converters';

const { name } = options;

type getWellByUidProp = {
    id: string
    user?: string
};

export const getWellByUid = createAsyncThunk(
  `${name}/getWellByUid`,
  async (props: getWellByUidProp, thunkApi) => {
    try {
      const well = await wellsApi.getWellByUidApi(props.id);

      let bookmarks: IBookmarkedField[] = (thunkApi.getState() as any).models.well.bookmarkedWells;
      if (!bookmarks.length) {
        bookmarks = await wellsApi.getBookmarksByUserApi();
      }

      let isBookmarked = false;
      bookmarks.forEach(bookmarkedField => {
        bookmarkedField.wellPickupResponseDtos.forEach(bookmarkedWell => {
          if (bookmarkedWell.uid === props.id) isBookmarked = true;
        });
      });

      return { ...well, isBookmarked };
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getBookmarkedWellsByCurrentUser = createAsyncThunk(
  `${name}/getBookmarkedWellsByCurrentUser`,
  async (_, thunkApi) => {
    try {
      return await wellsApi.getBookmarksByUserApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getUnassignedWells = createAsyncThunk(
  `${name}/getUnassignedWells`,
  async (_, thunkApi) => {
    try {
      return await wellsApi.getUnassignedWellsApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const updateWellFacility = createAsyncThunk(
  `${name}/updateWellFacility`,
  async ({ uid, body }: any, thunkApi) => {
    try {
      return await wellsApi.updateWellFacilityApi(uid, body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getWells = createAsyncThunk(
  `${name}/getWells`,
  async (_, thunkApi) => {
    try {
      return await wellsApi.getWellsApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getWellsDetailedByFacilityId = createAsyncThunk(
  `${name}/getWellsDetailedByFacilityId`,
  async (id: string, thunkApi) => {
    try {
      return await wellsApi.getWellsDetailedByFacilityIdApi(id);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

// bookmarked actions

export const addWellToFavorites = createAsyncThunk(
  `${name}/addWellToFavorites`,
  async (body: any, thunkApi) => {
    try {
      await wellsApi.addWellToFavoritesApi(body);
      thunkApi.dispatch(facilitySlice
        .actions.changeBookmark({ isActive: true, uid: body.uid }));
      return body;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteWellFromFavorites = createAsyncThunk(
  `${name}/deleteWellFromFavorites`,
  async (body: any, thunkApi) => {
    try {
      await wellsApi.deleteWellFromFavoritesApi(body);
      thunkApi.dispatch(facilitySlice
        .actions.changeBookmark({ isActive: false, uid: body.uid }));
      return body;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const searchWellsByName = createAsyncThunk(
  `${name}/searchWellsByName`,
  async (wellName: string, thunkApi) => {
    try {
      return await wellsApi.searchWellsByNameApi(wellName);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createOrUpdateProjectThirdStep = createAsyncThunk(
  `${name}/createOrUpdateProjectThirdStep`,
  async (body: CreateProjectThirdStepFormType, thunkApi) => {
    try {
      return await wellsApi.createOrUpdateProjectThirdStepApi(createThirdStepBody2Server(body));
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createOrUpdateProjectFourthStep = createAsyncThunk(
  `${name}/createOrUpdateProjectFourthStep`,
  async (body: CreateProjectFourthStepFormType, thunkApi) => {
    try {
      return await wellsApi.createOrUpdateProjectFourthStepApi(createFourthStepBody2Server(body));
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getWellProject = createAsyncThunk(
  `${name}/getWellProject`,
  async (wellId: string, thunkAPI) => {
    try {
      return await wellsApi.getWellProjectApi(wellId);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updateWellProjectById = createAsyncThunk(
  `${name}/updateWellProjectById`,
  async (body: WellProjectType, thunkAPI) => {
    try {
      await wellsApi.updateWellProjectByIdApi(body);
      return await wellsApi.getWellProjectApi(body.uid);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getWellStatus = createAsyncThunk(
  `${name}/getWellStatus`,
  async (wellId: string, thunkAPI) => {
    try {
      return await wellsApi.getWellStatusApi(wellId);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updateWellStatus = createAsyncThunk(
  `${name}/updateWellStatus`,
  async (body: UpdateWellStatusType, thunkAPI) => {
    try {
      await wellsApi.updateWellStatusApi(body);
      return body.statusType;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getWellboreByWellId = createAsyncThunk(
  `${name}/getWellboreByWellId`,
  async (wellId: string, thunkAPI) => {
    try {
      const wellResponse = await wellsApi.getWellboreByWellIdApi([wellId]);
      const well = wellResponse[0];
      return well.wellboreList[0] || null;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getCoordinateSystem = createAsyncThunk<CoordinateSystemType[], void>(
  `${name}/getCoordinateSystem`,
  async (_, thunkAPI) => {
    try {
      return await wellsApi.getCoordinateSystemApi();
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);