const options = {
  name: 'well',
  apiUrl: '/well',

  pickupName: 'wellpickup',
  pickupApiUrl: '/wellpickup',

  serviceUrl: '/well'
};

export default options;
