import { createAsyncThunk } from '@reduxjs/toolkit';
import options from '../field/options';
import {
  calculateEcdApi,
  createHydraulicCalcApi,
  createHydraulicEqApi,
  getEcdCalcApi,
  getEhdPipeApi,
  getHydEquipmentsApi,
  getHydEquipmentTypeApi,
  getHwdPipeApi,
  getHydraulicCalcApi,
  getSdPipeApi,
  updateEcdCalcApi,
  deleteHydEquipmentApi,
  updateHydEquipmentApi,
  getBitCalcApi,
  updateBitCalcApi,
  calculateBitApi,
  getCutCalcApi,
  updateCutCalcApi,
  calculateCutCalcApi,
  getPressureLossApi,
  updatePressureLossApi,
  calculatePressureLossApi,
  downloadReportApi,
  calculateCementingApi
} from './api';
import {
  bitCalcFrontToBack,
  ecpCalcFrontToBack,
  equipmentUpdateToBack,
  handbookToFront,
  hydEquipmentToBack,
  pressureFrontToBack,
  cutCalcFrontToBack
} from './convertors';
import {
  HydraulicCalcCreateType, HydEquipmentTypes, HydEquipmentType, HydEquipmentUpdateType
} from './types';
import { BitType, MotorType, PipeType } from '../../pages/hydraulics/pages/tabs/common/HydraulicEquipmentDialog/helper';
import { IBitForm } from '../../pages/hydraulics/pages/tabs/BitPower/helper';
import { ICutCalcForm } from '../../pages/hydraulics/pages/tabs/Cuttings/helper';
import { IPressureForm } from '../../pages/hydraulics/pages/tabs/PressureLoss/helper';
import { IEcpForm } from '../../pages/hydraulics/pages/tabs/ECP/helper';

const { name } = options;

export const getHydEquipmentType = createAsyncThunk(
  `${name}/getHydEquipmentType`,
  async (_, thunkAPI) => {
    try {
      return await getHydEquipmentTypeApi();
    } catch (e:any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getHydraulicHandbook = createAsyncThunk(
  `${name}/getHydraulicHandbook`,
  async (type: HydEquipmentTypes, thunkAPI) => {
    try {
      let res;
      switch (type) {
        case HydEquipmentTypes.sdp:
          res = await getSdPipeApi();
          break;
        case HydEquipmentTypes.motor:
          return [{ name: 'ВЗД' }];
        case HydEquipmentTypes.bit:
          return [{ name: 'PDC' }];
        case HydEquipmentTypes.ehdp:
          res = await getEhdPipeApi();
          break;
        case HydEquipmentTypes.hwdp:
          res = await getHwdPipeApi();
          break;
        case HydEquipmentTypes.mwd:
          return [{ name: 'Телесистема' }];
        default:
          return [];
      }

      return handbookToFront(res);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const createHydraulicCalc = createAsyncThunk(
  `${name}/createHydraulicCalcAction`,
  (body: HydraulicCalcCreateType, thunkAPI) => {
    try {
      return createHydraulicCalcApi(body);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getHydraulicCalc = createAsyncThunk(
  `${name}/getHydraulicCalc`,
  async (wellId: string, thunkAPI) => {
    try {
      const res = await getHydraulicCalcApi(wellId);
      if (Array.isArray(res)) {
        return res[0];
      }

      thunkAPI.dispatch(createHydraulicCalc({
        item_data: {},
        item_meta: {},
        well_uuid_outer: wellId
      }));
      return null;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getEcdCalc = createAsyncThunk(
  `${name}/getEcdCalc`,
  async (hydraulicCalcId: string, thunkAPI) => {
    try {
      const res = await getEcdCalcApi(hydraulicCalcId);
      return res[0];
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

type CreateHydraulicEqParams = {
    hydraulicCalcId: string;
    body: PipeType | BitType | MotorType;
};

export const createHydEquipment = createAsyncThunk(
  `${name}/createHydEquipment`,
  async (params: CreateHydraulicEqParams, thunkAPI) => {
    try {
      return await createHydraulicEqApi(hydEquipmentToBack(params.body, params.hydraulicCalcId));
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

type UpdateEcdCalcParams = {
    hydraulicCalcId: string;
    calcId: string;
    body: IEcpForm;
};

export const updateEcdCalc = createAsyncThunk(
  `${name}/updateEcdCalc`,
  async (params: UpdateEcdCalcParams, thunkAPI) => {
    try {
      return await updateEcdCalcApi(params.calcId, ecpCalcFrontToBack(params.body, params.hydraulicCalcId));
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

type UpdateBitCalcParams = {
    hydraulicCalcId: string;
    calcId: string;
    body: IBitForm;
};

export const updateBitCalc = createAsyncThunk(
  `${name}/updateBitCalc`,
  async (params: UpdateBitCalcParams, thunkAPI) => {
    try {
      return await updateBitCalcApi(params.calcId, bitCalcFrontToBack(params.body, params.hydraulicCalcId));
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getBitCalc = createAsyncThunk(
  `${name}/getBitCalc`,
  async (hydraulicCalcId: string, thunkAPI) => {
    try {
      const res = await getBitCalcApi(hydraulicCalcId);
      return res[0];
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getCutCalc = createAsyncThunk(
  `${name}/getCutCalc`,
  async (hydraulicCalcId: string, thunkAPI) => {
    try {
      const res = await getCutCalcApi(hydraulicCalcId);
      return res[0];
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

type UpdateCutCalcParams = {
    hydraulicCalcId: string;
    calcId: string;
    body: ICutCalcForm;
};

export const updateCutCalc = createAsyncThunk(
  `${name}/updateCutCalc`,
  async (params: UpdateCutCalcParams, thunkAPI) => {
    try {
      return await updateCutCalcApi(params.calcId, cutCalcFrontToBack(params.body, params.hydraulicCalcId));
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getPressureLoss = createAsyncThunk(
  `${name}/getPressureLoss`,
  async (hydraulicCalcId: string, thunkAPI) => {
    try {
      const res = await getPressureLossApi(hydraulicCalcId);
      return res[0];
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

type UpdatePressureParams = {
    hydraulicCalcId: string;
    calcId: string;
    body: IPressureForm;
};

export const updatePressureLoss = createAsyncThunk(
  `${name}/updatePressureLoss`,
  async (params: UpdatePressureParams, thunkAPI) => {
    try {
      return await updatePressureLossApi(params.calcId, pressureFrontToBack(params.body, params.hydraulicCalcId));
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getHydEquipments = createAsyncThunk(
  `${name}/getHydEquipments`,
  async (hydraulicCalcId: string, thunkAPI) => {
    try {
      const res = await getHydEquipmentsApi(hydraulicCalcId);
      if (Array.isArray(res)) return res;

      return [];
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const calculateEcd = createAsyncThunk(
  `${name}/calculateEcd`,
  async (hydraulicCalcId: string, thunkAPI) => {
    try {
      return await calculateEcdApi({
        hydraulics_calc_uuid: hydraulicCalcId
      });
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const calculateBit = createAsyncThunk(
  `${name}/calculateBit`,
  async (hydraulcCalcId: string, thunkAPI) => {
    try {
      return await calculateBitApi({
        hydraulics_calc_uuid: hydraulcCalcId
      });
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const calculateCut = createAsyncThunk(
  `${name}/calculateCut`,
  async (hydraulcCalcId: string, thunkAPI) => {
    try {
      return await calculateCutCalcApi({
        hydraulics_calc_uuid: hydraulcCalcId
      });
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const calculatePressure = createAsyncThunk(
  `${name}/calculatePressure`,
  async (hydraulcCalcId: string, thunkAPI) => {
    try {
      return await calculatePressureLossApi({
        hydraulics_calc_uuid: hydraulcCalcId
      });
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const calculateCementing = createAsyncThunk(
  `${name}/calculateCementing`,
  async (hydraulcCalcId: string, thunkAPI) => {
    try {
      return await calculateCementingApi({
        well_uuid: hydraulcCalcId
      });
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteHydEquipment = createAsyncThunk(
  `${name}/deleteHydEquipment`,
  async (id: string, thunkAPI) => {
    try {
      await deleteHydEquipmentApi(id);
      return id;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updateHydEquipment = createAsyncThunk(
  `${name}/updateHydEquipment`,
  async (body: HydEquipmentUpdateType, thunkAPI) => {
    try {
      const id = body.itemUuid;
      return await updateHydEquipmentApi(id, equipmentUpdateToBack(body));
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const reorderHydEquipments = createAsyncThunk(
  `${name}/reorderHydEquipments`,
  async (equipments: HydEquipmentType[], thunkAPI) => {
    try {
      const ids = equipments.map(eq => eq.itemUuid);
      const requests = equipments.map((eq, i) => updateHydEquipmentApi(ids[i], equipmentUpdateToBack(eq)));
      return await Promise.all(requests);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const downloadReport = createAsyncThunk(
  `${name}/downloadReport`,
  async (id: string, thunkAPI) => {
    try {
      const report = await downloadReportApi({
        well_uuid: id
      });
      return {
        report,
        well_uuid: id
      };
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

// export const getBitCalc