import request from 'utils/http/request';
import { getUrl } from '../utils';
import options from './options';
import { IBasicDictionary } from './types';

const { fieldUrl } = options;

const fieldDictUrl = getUrl(fieldUrl);

// справочные данные
export const getDatumListApi = async (): Promise<IBasicDictionary[]> => {
  return request.get(fieldDictUrl('datum'));
};

export const getCylindricalProjectionListApi = async (): Promise<IBasicDictionary[]> => {
  return request.get(fieldDictUrl('cylindrical_projection'));
};

export const getGeomagneticModelListApi = async (): Promise<IBasicDictionary[]> => {
  return request.get(fieldDictUrl('geomagnetic_model'));
};
