import * as yup from 'yup';
import { createId } from '../../../../../utils/utils';
import { CreateTrunkType, EditTrunkType, TrunkType } from '../../../../../models/construction/types';
import {
  HeightCoeff,
  IConstruction,
  IOpenTrunkState,
  ITrunk,
  ITrunkFormState,
  IVerticalDepth,
  IVTrunk,
  TrunkName,
  Warning
} from './types';

export const getHeightByCoefficient: { [key in HeightCoeff]: number } = {
  [HeightCoeff.FIRST]: 1500,
  [HeightCoeff.SECOND]: 2500,
  [HeightCoeff.THIRD]: 5000,
  [HeightCoeff.FOURTH]: 10000,
  [HeightCoeff.FIFTH]: 15000
};

export const coefficientAndHeights: { coefficient: HeightCoeff, height: number }[] = [
  {
    coefficient: HeightCoeff.FIRST,
    height: getHeightByCoefficient[HeightCoeff.FIRST]
  },
  {
    coefficient: HeightCoeff.SECOND,
    height: getHeightByCoefficient[HeightCoeff.SECOND]
  },
  {
    coefficient: HeightCoeff.THIRD,
    height: getHeightByCoefficient[HeightCoeff.THIRD]
  },
  {
    coefficient: HeightCoeff.FOURTH,
    height: getHeightByCoefficient[HeightCoeff.FOURTH]
  },
  {
    coefficient: HeightCoeff.FIFTH,
    height: getHeightByCoefficient[HeightCoeff.FIFTH]
  }
];

// TODO: think
export const mutateCoefficient = (height: number) => {
  Object.keys(getHeightByCoefficient).forEach(key => {
    // @ts-ignore
    getHeightByCoefficient[key] = (height * getIncHeightCoeff(Number(key))) - 30;
  });

  coefficientAndHeights.forEach(el => {
    el.height = getHeightByCoefficient[el.coefficient];
  });
};

export const INITIAL_CONSTRUCTION: IConstruction = {
  verticalDepth: {
    id: createId(),
    width: 0,
    left: 0,
    height: 0,
    vTrunk: null
  },
  center: 0,
  heightCoefficient: HeightCoeff.FIRST,
  activeIndex: null,
  activeTrunk: null,
  trunks: []
};

export const trunksName: { value: TrunkName, label: string }[] = [
  {
    value: TrunkName.OPEN,
    label: 'Открытый ствол'
  },
  {
    value: TrunkName.CONDUCTOR,
    label: 'Кондуктор'
  },
  {
    value: TrunkName.INTERMEDIATE,
    label: 'Промежуточная'
  },
  {
    value: TrunkName.DIRECTION,
    label: 'Направление'
  },
  {
    value: TrunkName.MINE,
    label: 'Шахта'
  },
  {
    value: TrunkName.TAIL,
    label: 'Фильтр хвостик'
  },
  {
    value: TrunkName.OPERATIONAL,
    label: 'Эксплуатационная'
  }
];

// не используется. Приходит с бэка
export const trunkPositionOrder: { [key in TrunkName]: number } = {
  [TrunkName.MINE]: 70,
  [TrunkName.DIRECTION]: 60,
  [TrunkName.CONDUCTOR]: 50, // Кондуктор
  [TrunkName.INTERMEDIATE]: 40, // Промежуточная
  [TrunkName.OPERATIONAL]: 30, // Эксплуатационная
  [TrunkName.TAIL]: 20, // Фильтр хвостик
  [TrunkName.OPEN]: 10
};

export const trunkFormInitialState: ITrunkFormState = {
  autoCementCount: true,
  baseDepth: 8,
  name: '',
  outsideDiameter: 0,
  jointCountFrom: 0,
  jointCountTo: 8,
  openTrunk: false,
  openHoleDiameter: 0
};

export const openTrunkInitialState : IOpenTrunkState = {
  baseDepth: 8,
  name: TrunkName.OPEN,
  outsideDiameter: 0,
  autoCementCount: false,
  length: 10,
  openTrunk: true,
  openHoleDiameter: 0
};

export const validationSchema = yup.object({
  name: yup
    .mixed()
    .oneOf<TrunkName>(trunksName.map(name => name.value), 'Выберите ствол')
    .required('Обязательное поле'),
  outsideDiameter: yup
    .number()
    .required('Обязательное поле')
    .lessThan(yup.ref('openHoleDiameter'), 'Должно быть меньше чем диаметр открытого ствола'),
  baseDepth: yup
    .number()
    .required('Обязательное поле'),
  jointCountFrom: yup
    .number()
    .when('openTrunk', {
      is: false,
      then: yup
        .number()
        .min(0, 'Не может быть меньше нуля')
        .required('Обязательное поле')
    }),
  jointCountTo: yup
    .number()
    .when('openTrunk', {
      is: false,
      then: yup
        .number()
        .moreThan(yup.ref('jointCountFrom'), 'Значение должно быть больше чем минимум')
        .required('Обязательное поле')
    }),
  length: yup
    .number()
    .when('openTrunk', {
      is: true,
      then: yup
        .number()
        .min(10)
        .required('Обязательное поле')
    }),
  openHoleDiameter: yup
    .number()
    .required('Обязательное поле')
    .moreThan(yup.ref('outsideDiameter'), 'Должно быть больше  чем внешний ⌀')
});

export const ACTIVE_TRUNK_COLOR = 'var(--bg-success)';
export const DEFAULT_TRUNK_COLOR = 'var(--grey-300)';

export const CEMENT_WIDTH = 20;
export const VDEPTH_PADDING = 0;
export const TRUNK_PADDING = 5.5;
// export const WARNING_COLOR = '#ffdc73';

export const VTRUNK_INDEX = -2;

export const WIDTH_COEFFICIENT = 2; // with 1 we have some problem. It draws minimum 15px trunk width, but it can be 5px

export const FirstInnerTrunkDifference = 0;

// функция принимает коэффициент снижения значения и
// возвращает обратный коэффициент увеличения
// Например: 0.5 - коэффициент снижения, а 2 - обратный коэффициент значению 0.5
export const getIncHeightCoeff = (decreaseCoefficient: number): number => 10 / (decreaseCoefficient * 10);

export const cloneTrunks = (trunks: ITrunk[]): ITrunk[] => trunks.map(trunk => ({ ...trunk, cement: { ...trunk.cement } }));

export const cloneVerticalDepth = (vd: IVerticalDepth): IVerticalDepth => ({
  ...vd,
  vTrunk: vd.vTrunk ? { ...vd.vTrunk } : null
});

export const findNextCanvasHeight = (height: number): HeightCoeff => {
  const nextCoeff = coefficientAndHeights.find(coeff => coeff.height > height);
  if (nextCoeff) {
    return nextCoeff.coefficient;
  }

  return HeightCoeff.FIFTH;
};

export const makeInactiveTrunks = (trunks: ITrunk[]) => {
  trunks.forEach(trunk => {
    trunk.active = false;
  });
};

export const localizeTrunk = (trunk: TrunkType): ITrunk => ({
  globalId: trunk.globalId,
  name: trunk.name as Exclude<TrunkName, TrunkName.OPEN>,
  baseDepth: trunk.baseDepth,
  // uwi - not unique
  uwi: trunk.uwi,
  localId: createId(),
  openHoleDiameter: trunk.openHoleDiameter,
  outsideDiameter: trunk.outsideDiameter,
  insideDiameter: trunk.insideDiameter,
  position: trunk.position,
  cement: {
    jointCountFrom: trunk.jointCountFrom,
    jointCountTo: trunk.jointCountTo,
    id: createId(),
    touched: trunk.jointCountTo !== trunk.baseDepth
  },
  wallThickness: WIDTH_COEFFICIENT * trunk.wallThickness,
  realWallThickness: trunk.wallThickness,
  warning: null,
  top: 0,
  left: 0,
  secondLeft: 0,
  active: false,
  saved: true
});

export const localizeVTrunk = (vTrunk: TrunkType): IVTrunk => ({
  name: TrunkName.OPEN,
  active: false,
  baseDepth: vTrunk.baseDepth,
  left: 0,
  outsideDiameter: vTrunk.outsideDiameter,
  top: vTrunk.baseDepth - vTrunk.length,
  uwi: vTrunk.uwi,
  warning: null,
  insideDiameter: vTrunk.insideDiameter,
  wallThickness: vTrunk.wallThickness,
  saved: false,
  localId: createId(),
  length: vTrunk.length,
  position: vTrunk.position,
  openHoleDiameter: vTrunk.openHoleDiameter
});

export const checkNeedToChangeHeight = (
  trunks: ITrunk[],
  currentCoefficient: HeightCoeff,
  increase: boolean,
): false | HeightCoeff => {
  let needToChange = false;
  const h = getHeightByCoefficient[currentCoefficient];

  if (increase) {
    if (currentCoefficient === HeightCoeff.FIFTH) return false;

    trunks.forEach(trunk => {
      if (trunk.baseDepth > h || trunk.cement.jointCountTo > h) {
        needToChange = true;
      }
    });

    if (needToChange) return findNextCanvasHeight(h);

    return false;
  } else {
    if (currentCoefficient === HeightCoeff.FIRST) return false;

    const arr: boolean[] = [];
    const index = coefficientAndHeights.findIndex(el => el.coefficient === currentCoefficient);
    const prevCoefficient = coefficientAndHeights[index - 1];

    if (prevCoefficient) {
      trunks.forEach(trunk => {
        let toChange = false;
        if (trunk.baseDepth < (prevCoefficient.height * 0.8)) {
          toChange = true;
        }
        arr.push(toChange);
      });
    } else {
      return false;
    }

    return arr.every(el => el) ? prevCoefficient.coefficient : false;
  }
};

export const needToChangeHeightPanel = (
  trunks: ITrunk[],
  currentCoefficient: HeightCoeff,
  increase: boolean
): HeightCoeff | false => {
  let max = 0;
  trunks.forEach(trunk => {
    if (max < trunk.baseDepth) max = trunk.baseDepth;
  });

  if (increase) {
    if (currentCoefficient === HeightCoeff.FIFTH) return false;

    const coefficient = coefficientAndHeights.find(coeff => coeff.height > max);
    if (coefficient) {
      if (coefficient.coefficient === currentCoefficient) return false;
      return coefficient.coefficient;
    }

    return HeightCoeff.FIFTH;
  } else {
    if (currentCoefficient === HeightCoeff.FIRST) return false;

    const coefficient = coefficientAndHeights.find(coeff => coeff.height > max);
    if (coefficient) {
      if (coefficient.coefficient === currentCoefficient) return false;
      return coefficient.coefficient;
    }

    return HeightCoeff.FIRST;
  }
};

export const sortByTrunkOrder = (trunks: ITrunk[]): void => {
  trunks.sort((a, b) => a.position - b.position);
};

export const calculateVTrunkPosition = (vTrunk: IVTrunk, vdMiddle: number) => {
  vTrunk.left = vdMiddle - (vTrunk.outsideDiameter / 2);
};

export const buildInitialVerticalDepth = (
  verticalDepth: IVerticalDepth,
  trunks: ITrunk[],
  middle: number
): IVerticalDepth => {
  const vd = cloneVerticalDepth(verticalDepth);
  const centerTrunk = trunks[0];
  if (centerTrunk) {
    vd.width = centerTrunk.insideDiameter;
    vd.height = centerTrunk.baseDepth;
  }

  vd.left = middle - (vd.width / 2);

  if (vd.vTrunk) {
    calculateVTrunkPosition(vd.vTrunk, vd.width / 2);
    if (centerTrunk) vd.vTrunk.top = centerTrunk.baseDepth;
  }

  return vd;
};

export const calculateTrunksPosition = (trunks: ITrunk[], center: number): void => {
  trunks.forEach((trunk, index) => {
    const left = center - (trunk.insideDiameter / 2) - trunk.wallThickness;
    const secondLeft = center + (trunk.insideDiameter / 2);
    trunk.left = left;
    trunk.secondLeft = secondLeft;
  });
};

interface ICreateTrunkOptions {
    name: TrunkName,
    baseDepth: number,
    outsideDiameter: number,
    insideDiameter?: number,
    wallThickness?: number,
    openHoleDiameter: number,
    // cement?: Omit<ICement, 'id'>
    jointCountFrom?: number,
    jointCountTo?: number,
    autoCementCount?: boolean,
    length?: number
    position: number
}

export const createTrunkForBack = (options: ICreateTrunkOptions): CreateTrunkType => {
  const {
    outsideDiameter,
    insideDiameter,
    wallThickness = 10,
    name,
    baseDepth = 100,
    openHoleDiameter = 0,
    jointCountFrom = 0,
    jointCountTo = baseDepth,
    autoCementCount = false,
    length = 0
  } = options;

  return {
    outsideDiameter: options.outsideDiameter,
    openHoleDiameter,
    baseDepth: options.baseDepth,
    jointCountFrom,
    name,
    insideDiameter: outsideDiameter - (wallThickness * 2),
    wallThickness,
    reportedBaseTvd: options.baseDepth,
    jointCountTo: autoCementCount ? options.baseDepth : jointCountTo,
    length,
    position: options.position,
    circulationSystem: null,
    drilledRockVolume: null,
    shburoVolume: null,
    sludgeVolume: null,
    weightObr: null,
    weightOruo: null
  };
};

export const makeBackendTrunk = (trunk: ITrunk | IVTrunk): EditTrunkType => {
  if (trunk.name === TrunkName.OPEN) {
    const wallThickness = 10;
    return {
      name: trunk.name,
      baseDepth: trunk.baseDepth,
      jointCountFrom: 0,
      reportedBaseTvd: trunk.baseDepth,
      wallThickness, // mock data for back
      insideDiameter: trunk.outsideDiameter - (2 * wallThickness), // mock data for back
      outsideDiameter: trunk.outsideDiameter,
      jointCountTo: 0,
      openHoleDiameter: trunk.openHoleDiameter,
      length: trunk.length,
      circulationSystem: null,
      drilledRockVolume: null,
      shburoVolume: null,
      sludgeVolume: null,
      weightObr: null,
      weightOruo: null
    };
  } else {
    trunk.baseDepth = +trunk.baseDepth.toFixed(2);
    trunk.cement.jointCountTo = +trunk.cement.jointCountTo.toFixed(2);
    return {
      name: trunk.name,
      baseDepth: trunk.baseDepth,
      length: 0,
      jointCountFrom: trunk.cement.jointCountFrom,
      jointCountTo: trunk.cement.touched ? trunk.cement.jointCountTo : trunk.baseDepth,
      openHoleDiameter: trunk.openHoleDiameter,
      outsideDiameter: trunk.outsideDiameter,
      insideDiameter: trunk.insideDiameter,
      wallThickness: trunk.realWallThickness,
      reportedBaseTvd: trunk.baseDepth,
      circulationSystem: null,
      drilledRockVolume: null,
      shburoVolume: null,
      sludgeVolume: null,
      weightObr: null,
      weightOruo: null
    };
  }
};

export const checkTrunksValid = (trunks: ITrunk[], vTrunk: IVTrunk | null, activeIndexHeight?: number): void => {
  for (let i = 1; i < trunks.length; i += 1) {
    const prevTrunk = trunks[i - 1];
    const trunk = trunks[i];

    if (trunk.left + trunk.realWallThickness >= prevTrunk.left) {
      trunk.warning = Warning.color;
    } else if (trunk.outsideDiameter >= trunk.openHoleDiameter) {
      trunk.warning = Warning.color;
    } else if (trunk.baseDepth >= prevTrunk.baseDepth) {
      if (activeIndexHeight !== undefined && activeIndexHeight >= 0) {
        trunks[activeIndexHeight].warning = Warning.color;
      } else {
        trunk.warning = Warning.color;
      }
    } else if (activeIndexHeight !== undefined && activeIndexHeight === 0) {
      const next = trunks[1];
      if (trunks[activeIndexHeight].baseDepth <= next?.baseDepth) {
        trunks[activeIndexHeight].warning = Warning.color;
      } else {
        trunks[0].warning = null;
        trunk.warning = null;
      }
    } else {
      trunk.warning = null;
    }
  }

  if (vTrunk && trunks[0]) {
    if (vTrunk.outsideDiameter >= trunks[0].insideDiameter) {
      vTrunk.warning = Warning.color;
    } else {
      vTrunk.warning = null;
    }
  }
};

export const findActiveTrunk = (trunks: ITrunk[], vTrunk: IVTrunk | null): number | null => {
  const index = trunks.findIndex(tr => tr.active);
  if (index > -1) {
    return index;
  }

  return vTrunk?.active ? VTRUNK_INDEX : null;
};

export interface ITemplateType {
    id: string;
    value: number;
}

export interface ITemplateParam {
    trunkName: TrunkName,
    templates: ITemplateType[]
}

export const INNER_TEMPLATES: ITemplateParam[] = [
  {
    trunkName: TrunkName.DIRECTION,
    templates: [
      {
        id: 't-1',
        value: 304
      },
      {
        id: 't-2',
        value: 225
      }
    ]
  },
  {
    trunkName: TrunkName.CONDUCTOR,
    templates: [
      {
        id: 't-1',
        value: 126
      },
      {
        id: 't-2',
        value: 148
      },
      {
        id: 't-3',
        value: 225
      }
    ]
  },
  {
    trunkName: TrunkName.INTERMEDIATE,
    templates: [
      {
        id: 't-1',
        value: 126
      },
      {
        id: 't-2',
        value: 148
      }
    ]
  },
  {
    trunkName: TrunkName.MINE,
    templates: [
      {
        id: 't-1',
        value: 406
      }
    ]
  },
  {
    trunkName: TrunkName.TAIL,
    templates: [
      {
        id: 't-1',
        value: 86
      }
    ]
  },
  {
    trunkName: TrunkName.OPERATIONAL,
    templates: [
      {
        id: 't-1',
        value: 106
      },
      {
        id: 't-2',
        value: 86
      }
    ]
  }
];

export const OUTER_TEMPLATES: ITemplateParam[] = [
  {
    trunkName: TrunkName.DIRECTION,
    templates: [
      {
        id: 't-1',
        value: 324
      },
      {
        id: 't-2',
        value: 245
      }
    ]
  },
  {
    trunkName: TrunkName.CONDUCTOR,
    templates: [
      {
        id: 't-1',
        value: 245
      },
      {
        id: 't-2',
        value: 168
      },
      {
        id: 't-3',
        value: 146
      }
    ]
  },
  {
    trunkName: TrunkName.INTERMEDIATE,
    templates: [
      {
        id: 't-1',
        value: 146
      },
      {
        id: 't-2',
        value: 168
      }
    ]
  },
  {
    trunkName: TrunkName.MINE,
    templates: [
      {
        id: 't-1',
        value: 426
      }
    ]
  },
  {
    trunkName: TrunkName.TAIL,
    templates: [
      {
        id: 't-1',
        value: 102
      }
    ]
  },
  {
    trunkName: TrunkName.OPERATIONAL,
    templates: [
      {
        id: 't-1',
        value: 114
      },
      {
        id: 't-2',
        value: 102
      }
    ]
  },
  {
    trunkName: TrunkName.OPEN,
    templates: [
      {
        id: 't-1',
        value: 123.8
      },
      {
        id: 't-2',
        value: 142.9
      },
      {
        id: 't-3',
        value: 395
      },
      {
        id: 't-4',
        value: 295.3
      },
      {
        id: 't-5',
        value: 215.9
      },
      {
        id: 't-6',
        value: 490
      },
      {
        id: 't-7',
        value: 129.8
      }
    ]
  }
];

export const DIAMETER_OPEN: ITemplateParam[] = [
  {
    trunkName: TrunkName.DIRECTION,
    templates: [
      {
        id: 't-1',
        value: 395
      },
      {
        id: 't-2',
        value: 295.3
      }
    ]
  },
  {
    trunkName: TrunkName.CONDUCTOR,
    templates: [
      {
        id: 't-1',
        value: 295.3
      },
      {
        id: 't-2',
        value: 215.9
      }
    ]
  },
  {
    trunkName: TrunkName.INTERMEDIATE,
    templates: [
      {
        id: 't-1',
        value: 215.9
      }
    ]
  },
  {
    trunkName: TrunkName.MINE,
    templates: [
      {
        id: 't-1',
        value: 490
      }
    ]
  },
  {
    trunkName: TrunkName.TAIL,
    templates: [
      {
        id: 't-1',
        value: 129.8
      }
    ]
  },
  {
    trunkName: TrunkName.OPERATIONAL,
    templates: [
      {
        id: 't-1',
        value: 142.9
      },
      {
        id: 't-2',
        value: 123.8
      }
    ]
  }
];
