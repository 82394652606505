import {
  DrillingWellBoreType,
  SchedulerDrillingType,
  SchedulerInstallationType,
  SchedulerWellBackType,
  SchedulerWellType,
  UnitedWorkType
} from './types';

export const schedulerWellToFront = (body: SchedulerWellBackType): SchedulerWellType => {
  let beginDate = null;
  let endDate = null;
  let wellDone = 0;
  const works: UnitedWorkType[] = [];
  let wellBore: DrillingWellBoreType | null = null;

  const drillingWork = body.works.find(work => work.workName === 'бурение') as (SchedulerDrillingType | undefined);
  const installationWork = body.works.find(work => work.workName === 'монтаж') as (SchedulerInstallationType | undefined);

  const wellbores = drillingWork?.wellbores;
  if (wellbores && wellbores[0]) {
    wellBore = wellbores[0];
    beginDate = wellbores[0].scheduledStart;
    endDate = wellbores[0].scheduledEnd;
  } else if (installationWork) {
    beginDate = installationWork.scheduledStart;
    endDate = installationWork.scheduledEnd;
  }

  body.works.forEach(work => {
    // wellDone count
    wellDone += work.done;

    // UnitedWorkType
    if (work.workName === 'бурение') {
      works.push({
        done: work.done,
        workId: work.workId,
        workName: work.workName,
        wellBoreId: wellBore?.wellBoreId || null,
        wellBoreName: wellBore?.wellBoreName || null,
        scheduledEnd: wellBore?.scheduledEnd ? wellBore.scheduledEnd : null,
        scheduledStart: wellBore?.scheduledStart ? wellBore.scheduledStart : null,
        wellId: body.wellId,
        workNameAlias: 'drilling',
        showWarnPastMonth: null,
        showWarnFutureMonth: null
      });
    } else if (work.workName === 'монтаж') {
      works.push({
        ...work,
        scheduledStart: work.scheduledStart,
        scheduledEnd: work.scheduledEnd,
        wellBoreId: wellBore?.wellBoreId || null,
        wellBoreName: wellBore?.wellBoreName || null,
        wellId: body.wellId,
        workNameAlias: 'installation',
        showWarnPastMonth: null,
        showWarnFutureMonth: null
      });
    }
  });

  return {
    ...body,
    beginDate,
    endDate,
    done: wellDone,
    works
  };
};