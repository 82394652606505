import axios from 'axios';

import options from 'options';

const instance = axios.create({
  baseURL: options.baseURL
});

let HttpClient: any;

export function registerHttpClient(provider: any) {
  HttpClient = provider;
}

const request = {
  instance,
  // setToken,
  async get(...args: any) {
    return HttpClient.get(...args);
  },
  async post(...args: any) {
    return HttpClient.post(...args);
  },
  async put(...args: any) {
    return HttpClient.put(...args);
  },
  async patch(...args: any) {
    return HttpClient.patch(...args);
  },
  async delete(...args: any) {
    return HttpClient.delete(args[0], { data: args[1] });
  }
};

export default request;
