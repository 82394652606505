import { HydraulicNotFound } from 'models/hydraulics/types';
import request from '../../utils/http/request';
import { getUrl } from '../utils';
import options from './options';
import {
  IFasEquipment,
  IFasConstructionEquipment,
  createFasteningConstructionReq,
  createFasteningEquipmentReq,
  FasteningNotFound,
  FasteningCalcCreateType,
  IJobData,
  createFasteningFluidReq,
  IFasSolutionBack,
  ICementingCalc,
  IFasSolution,
  IFasCentralization,
  createFasteningCentralizationReq
} from './types';

const { apiUrl, serviceUrl } = options;
const url = getUrl(apiUrl);
const urlWithoutProxy = getUrl(serviceUrl);

// centralization

export const getFasteningCentralizationApi = async (
  well_uuid: string
): Promise<IFasCentralization[] | FasteningNotFound> =>
  request.get(url(`hydraulics/research/centralization/?well_uuid=${well_uuid}`));

export const createFasteningCentralizationApi = async (
  body: createFasteningCentralizationReq
): Promise<IFasCentralization> =>
  request.post(url('hydraulics/research/centralization'), body);

export const updateFasteningCentralizationApi = async (
  body: createFasteningCentralizationReq
): Promise<IFasCentralization> =>
  request.put(url('hydraulics/research/centralization'), body);

export const deleteFasCentralizationApi = async (itemUuid: string): Promise<void> =>
  request.delete(url(`hydraulics/research/centralization/${itemUuid}/`));

// fluid

export const getFluidsApi = async (
  hydraulicCalcId: string
): Promise<IFasSolution[] | HydraulicNotFound> =>
  request.get(url(`hydraulics//fluid/fluid/?well_uuid=${hydraulicCalcId}`));

export const createFasteningSolutionApi = async (
  body: createFasteningFluidReq
): Promise<IFasSolutionBack> =>
  request.post(url('hydraulics/fluid/fluid/'), body);

export const updateFasteningFluidApi = async (
  body: createFasteningFluidReq
): Promise<IFasSolution> =>
  request.put(url(`hydraulics/fluid/fluid/${body.item_uuid}/`), body);

// equipment

export const createFasteningEquipmentApi = async (
  body: createFasteningEquipmentReq
): Promise<IFasEquipment> =>
  request.post(url('hydraulics/equipment/equipment/'), body);

export const updateFasteningEquipmentApi = async (
  body: createFasteningEquipmentReq
): Promise<IFasConstructionEquipment> =>
  request.put(url(`hydraulics/equipment/equipment/${body.item_uuid}/`), body);

export const deleteFasEquipmentApi = async (itemUuid: string): Promise<void> =>
  request.delete(urlWithoutProxy(`/${itemUuid}/`));

export const getFasEquipmentsApi = async (
  hydraulicCalcId: string
): Promise<IFasEquipment[] | HydraulicNotFound> =>
  request.get(
    url(`hydraulics/equipment/equipment/?well_uuid=${hydraulicCalcId}`)
  );

// construction

export const createFasteningConstructionApi = async (
  body: createFasteningConstructionReq
): Promise<IFasConstructionEquipment> =>
  request.post(url('hydraulics/equipment/construction/'), body);

export const getFasteningConstructionApi = async (
  well_uuid: string
): Promise<IFasConstructionEquipment[]> =>
  request.get(url(`hydraulics/equipment/construction/?well_uuid=${well_uuid}`));

export const updateFasteningConstructionApi = async (
  body: createFasteningConstructionReq
): Promise<IFasConstructionEquipment> =>
  request.put(
    url(`hydraulics/equipment/construction/${body.item_uuid}/`),
    body
  );

export const getResearchJobDataApi = async (
  researchJobId: string
): Promise<IJobData> =>
  request.get(url(`hydraulics/research/job_data/?item_uuid=${researchJobId}`));

export const getFasConstructionsApi = async (
  hydraulicCalcId: string
): Promise<IFasConstructionEquipment[] | HydraulicNotFound> =>
  request.get(
    url(`hydraulics/equipment/construction/?well_uuid=${hydraulicCalcId}`)
  );

export const deleteFasConstructionApi = async (
  itemUuid: string
): Promise<void> =>
  request.delete(
    urlWithoutProxy(`/equipment/construction/${itemUuid}/`)
  );

// cementing
export const getResearchCementingApi = async (
  wellId: string
): Promise<ICementingCalc[] | FasteningNotFound> =>
  request.get(url(`hydraulics/research/cementing/?well_uuid_outer=${wellId}`));

export const deleteFasFluidApi = async (
  itemUuid: string
): Promise<void> =>
  request.delete(
    urlWithoutProxy(`/fluid/fluid/${itemUuid}/`));

export const createResearchCementingApi = async (
  body: FasteningCalcCreateType
): Promise<ICementingCalc> =>
  request.post(url('hydraulics/research/cementing/'), body);

// job data

export const createResearchJobDataApi = async (
  body: IJobData
): Promise<IJobData> =>
  request.post(url('hydraulics/research/job_data/'), body);