import { IFacilityBreadcrumb, IWellBreadcrumb } from './types';

export type ConvertorWellBreadcrumbType = {
    'well_name':string,
    'куст_ид': string;
    'куст_короткое_имя': string;
    'куст_тип': string;
    'месторождение_ид': string;
    'месторождение_имя': string;
    'площадь_ид': string;
    'площадь_тип': string;
    'предпочтительный_название': string;
    'уид': string;
};

export type ConvertorFacilityType = {
    'куст_короткое_имя': string,
    'площадь_ид': string,
    'площадь_тип': string,
    'предпочтительный_название': string,
    'месторождение_ид': string,
    'месторождение_имя': string
};

export const convertWellBreadcrumb = (breadcrumb: ConvertorWellBreadcrumbType): IWellBreadcrumb => ({
  wellId: breadcrumb['уид'],
  facilityId: breadcrumb['куст_ид'],
  facilityType: breadcrumb['куст_тип'],
  areaId: breadcrumb['площадь_ид'],
  areaType: breadcrumb['площадь_тип'],
  fieldId: breadcrumb['месторождение_ид'],
  facilityName: breadcrumb['куст_короткое_имя'],
  areaName: breadcrumb['предпочтительный_название'],
  fieldName: breadcrumb['месторождение_имя'],
  wellName: breadcrumb.well_name
});

export const convertFacilityBreadcrumb = (
  breadcrumb : ConvertorFacilityType & { facilityType: string, facilityId: string }
): IFacilityBreadcrumb => ({
  areaId: breadcrumb['площадь_ид'],
  areaType: breadcrumb['площадь_тип'],
  facilityName: breadcrumb['куст_короткое_имя'],
  fieldId: breadcrumb['месторождение_ид'],
  areaName: breadcrumb['предпочтительный_название'],
  fieldName: breadcrumb['месторождение_имя'],
  facilityType: breadcrumb.facilityType,
  facilityId: breadcrumb.facilityId
});
