import React from 'react';
import AuthProvider from 'containers/authorization';
import ToastProvider from 'containers/toast';

import ApiProvider from './http/api-provider';
import WebsocketProvider from './http/ws-provider';

export const SystemProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => (
  <ToastProvider life={5000}>
    <ApiProvider>
      <AuthProvider>
        <WebsocketProvider>
          {children}
        </WebsocketProvider>
      </AuthProvider>
    </ApiProvider>
  </ToastProvider>
);
