import {
  FieldInfoResponseType, FieldInfoType, FieldsFormType, FieldType
} from './types';
import { FacilityType } from '../facility/types';
import { cutNumbers } from '../../utils/utils';
import { IWellCommon } from '../well/types';

export const fieldsDetail2Client = (res: FieldType[]) => {
  const fieldsResult: any[] = [];
  res.forEach((field: any, fieldIndex: number) => {
    const facResult: any[] = [];

    field.facilities?.forEach((facility: FacilityType) => {
      const {
        facilityId, facilityType, facilityName, incidents, wellCount, wellActive, ropAverage, wellRiskDetail, wellsList
      } = facility;

      const wellRiskCount = wellsList
        ? wellsList.reduce((acc: number, curr: IWellCommon) => (curr.incidentInfo?.length ? acc + 1 : acc), 0) : incidents ? incidents.length : 0;

      facResult.push({
        uid: `${facilityType || ''}&&${facilityId || ''}`,
        facilityId,
        facilityType,
        facilityName,
        wellCount,
        wellActive,
        ropAverage,
        wellRisk: wellRiskCount,
        wellRiskDetail,
        wellsList
      });
    });
    const {
      facilities, fieldId, fieldName, wellCount, wellActive, ropAverage, wellRiskDetail
    } = field;

    const facRiskCount = facilities?.reduce((acc: number, curr: FacilityType) => (curr.incidents?.length ? acc + curr.incidents.length : acc), 0) || 0;

    fieldsResult.push({
      fieldId,
      fieldName,
      wellCount,
      wellActive,
      ropAverage,
      wellRisk: facRiskCount,
      wellRiskDetail,
      facilities: facResult
    });
  });
  return fieldsResult;
};

export const convertFieldsForTable = (res: FieldType[]) => {
  const fieldsResult: any[] = [];
  res.forEach((field: any, fieldIndex: number) => {
    const facResult: any[] = [];

    field.facilities.forEach((facility: FacilityType) => {
      const {
        uid,
        facilityId, facilityType, facilityName, wellCount, wellActive, ropAverage, wellRisk, wellRiskDetail
      } = facility;

      facResult.push({
        key: `${fieldIndex}-${facilityId}`,
        data: {
          uid,
          facilityId,
          facilityType,
          caption: facilityName,
          wellCount: wellCount || '-',
          wellActive: wellActive || '-',
          ropAverage: ropAverage ? cutNumbers(ropAverage, 2) : '-',
          wellRisk,
          wellRiskDetail
        }
      });
    });
    const {
      fieldId, fieldName, wellCount, wellActive, ropAverage, wellRisk, wellRiskDetail
    } = field;

    fieldsResult.push({
      key: `${fieldIndex}`,
      data: {
        fieldId,
        caption: fieldName,
        wellCount: wellCount || '-',
        wellActive: wellActive || '-',
        ropAverage: ropAverage ? cutNumbers(ropAverage, 2) : '-',
        wellRisk,
        wellRiskDetail
      },
      children: facResult
    });
  });
  return fieldsResult;
};

export const field2Client = (field: any) => ({
  // TODO: Изменились приходящие данные. Надо обдумать. Так как половина на кирилице, вторая половина на латинице
  uid: field.fieldId,
  fieldName: field.fieldName
});

export const fieldsList2Client = (fields: []): FieldsFormType[] => fields.map(field2Client);

export const fieldInfo2Client = (field: FieldInfoResponseType): FieldInfoType => {
  const {
    fieldId, datum, cylindricalProjection, geomagneticModel, coordinateSystem
  } = field;

  return {
    fieldId,
    datumId: datum.id || undefined,
    cylindricalProjectionId: cylindricalProjection.id || undefined,
    geomagneticModelId: geomagneticModel.id || undefined,
    coordinateSystem: coordinateSystem || undefined
  };
};
