export type HydraulicCalcType = {
  item_uuid: string;
  item_meta: {};
  item_data: {};
  row_changed_by: null;
  row_changed_datetime: string;
  row_created_by: null;
  row_created_datetime: string;
  well_uuid_outer: string;
};

export type HydraulicNotFound = {
  detail: string;
};

export type HydraulicCalcCreateType = {
  item_meta: Record<string, any>;
  item_data: Record<string, any>;
  well_uuid_outer: string;
};

export type EcpCalcType = {
  itemUuid: string;
  itemMeta: null;
  itemData: null;
  rowChangedBy: null;
  rowChangedDatetime: string;
  rowCreatedBy: null;
  rowCreatedDatetime: string;
  useCuttings: boolean;
  liquidDensity: null | number;
  plasticViscosity: null | number;
  yieldPoint: null | number;
  rockDensity: null | number;
  rockPartDiameter: null | number;
  rockPartHeight: null | number;
  depth: null | number;
  depthStart: null | number;
  depthStop: null | number;
  depthStep: null | number;
  flowRate: null | number;
  flowRates: (number | null)[];
  rop: null | number;
  ecd: null | number;
  mudConcentration: null | number;
  mudTransportEfficiency: null | number;
  ecds: (number | null)[];
  mudConcentrations: (number | null)[];
  mudTransportEfficiencies: null | (number | null)[];
  wellDiameterByUser: null | number;
  linkedWithHydraulicsCalc: string;
};

export type EcpCalcUpdateType = {
  item_meta: Record<string, any> | null;
  item_data: Record<string, any> | null;
  use_cuttings: boolean;
  liquid_density: number | null;
  plastic_viscosity: number | null;
  yield_point: number | null;
  rock_density: number | null;
  rock_part_diameter: number | null;
  rock_part_height: number | null;
  flow_rate: number | null;
  rop: number | null;
  well_diameter_by_user: number | null;
  linked_with_hydraulics_calc: string;
};

export type ResearchEcpBackType = {
  item_uuid: string;
  item_meta: null;
  item_data: null;
  row_changed_by: null;
  row_changed_datetime: string;
  row_created_by: null;
  row_created_datetime: string;
  use_cuttings: boolean;
  liquid_density: null | number;
  plastic_viscosity: null | number;
  yield_point: null | number;
  rock_density: null | number;
  rock_part_diameter: null | number;
  rock_part_height: null | number;
  depth: null | number;
  depth_start: null | number;
  depth_stop: null | number;
  depth_step: null | number;
  flow_rate: null | number;
  flow_rates: null | (number | null)[];
  rop: null | number;
  ecd: null | number;
  mud_concentration: null | number;
  mud_transport_efficiency: null | number;
  ecds: null | (number | null)[];
  mud_concentrations: null | (number | null)[];
  mud_transport_efficiencies: null | (number | null)[];
  well_diameter_by_user: null | number;
  linked_with_hydraulics_calc: string;
};

export type EcdCalculationBack = {
  d_well: number | null;
} & Omit<
  ResearchEcpBackType,
  | 'item_uuid'
  | 'item_meta'
  | 'item_data'
  | 'row_created_by'
  | 'row_changed_by'
  | 'row_changed_datetime'
  | 'row_created_datetime'
  | 'linked_with_hydraulics_calc'
  | 'well_diameter_by_user'
>;

export type CalculationEcdBackType = {
  errors: string[];
  result: EcdCalculationBack;
};

export type BitCalcType = {
  itemUuid: string;
  flowRates: (number | null)[];
  pressureLosses: (number | null)[];
  nozzleVelocities: (number | null)[];
  impactForces: (number | null)[];
  hydraulicsPowers: (number | null)[];
  itemMeta: null;
  itemData: null;
  rowChangedBy: null;
  rowChangedDatetime: string;
  rowCreatedBy: null;
  rowCreatedDatetime: string;
  liquidDensity: null | number;
  plasticViscosity: null | number;
  yieldPoint: null | number;
  maxPressure: null | number;
  flowRate: null | number;
  totalFlowArea: null | number;
  pressureLoss: null | number;
  nozzleVelocity: null | number;
  impactForce: null | number;
  hydraulicPower: null | number;
  wellDiameterByUser: null | number;
  linkedWithHydraulicsCalc: string;
};

export type ResearchBitBackType = {
  item_uuid: string;
  flow_rates: null | (number | null)[];
  pressure_losses: null | (number | null)[];
  nozzle_velocities: null | (number | null)[];
  impact_forces: null | (number | null)[];
  hydraulic_powers: null | (number | null)[];
  item_meta: null;
  item_data: null;
  row_changed_by: null;
  row_changed_datetime: string;
  row_created_by: null;
  row_created_datetime: string;
  liquid_density: null | number;
  plastic_viscosity: null | number;
  yield_point: null | number;
  max_pressure: null | number;
  flow_rate: null | number;
  total_flow_area: null | number;
  pressure_loss: null | number;
  nozzle_velocity: null | number;
  impact_force: null | number;
  hydraulic_power: null | number;
  well_diameter_by_user: null | number;
  linked_with_hydraulics_calc: string;
};

export type BitCalcUpdateType = {
  item_meta: Record<string, any> | null;
  item_data: Record<string, any> | null;
  liquid_density: number | null;
  plastic_viscosity: number | null;
  yield_point: number | null;
  max_pressure: number | null;
  flow_rate: number | null;
  well_diameter_by_user: number | null;
  linked_with_hydraulics_calc: string;
};

export type BitCalculationBack = {
  d_well: number | null;
} & Omit<
  ResearchBitBackType,
  | 'item_uuid'
  | 'item_meta'
  | 'item_data'
  | 'row_created_by'
  | 'row_changed_by'
  | 'row_changed_datetime'
  | 'row_created_datetime'
  | 'linked_with_hydraulics_calc'
  | 'well_diameter_by_user'
>;

export type CalculationBitBackType = {
  errors: string[];
  result: BitCalculationBack;
};

export type CutCalcType = {
  // компиляция research и calculation
  itemUuid: string;
  itemMeta: null;
  itemData: null;
  rowChangedBy: null;
  rowChangedDatetime: string;
  rowCreatedBy: null;
  rowCreatedDatetime: string;
  liquidDensity: null | number;
  plasticViscosity: null | number;
  yieldPoint: null | number;
  rockDensity: null | number;
  rockPartDiameter: null | number;
  rockPartHeight: null | number;
  rop: null | number;
  ropMin: null | number;
  ropMax: null | number;
  ropStep: null | number;
  rpm: null | number;
  flowRate: null | number;
  wellDiameterCalced: null | number;
  wellDiameterByUser: null | number;
  volumeExcessFactor: null | number;
  depths: (number | null)[];
  incls: (number | null)[];
  rops: (number | null)[];
  velocitiesCrit: (number | null)[];
  flowRatesCrit: (number | null)[];
  cuttingsHeights: (number | null)[];
  flowRatesCritRop: (number | null)[];
  linkedWithHydraulicsCalc: string;
};

export type ResearchCutBackType = {
  item_uuid: string;
  item_meta: null;
  item_data: null;
  row_changed_by: null;
  row_changed_datetime: string;
  row_created_by: null;
  row_created_datetime: string;
  liquid_density: null | number;
  plastic_viscosity: null | number;
  yield_point: null | number;
  rock_density: null | number;
  rock_part_diameter: null | number;
  rock_part_height: null | number;
  rop: null | number;
  flow_rates_crit_rop: null | (number | null)[];
  rop_min: null | number;
  rop_max: null | number;
  rop_step: null | number;
  rpm: null | number;
  flow_rate: null | number;
  well_diameter_calced: null | number;
  well_diameter_by_user: null | number;
  volume_excess_factor: null | number;
  depths: null | (number | null)[];
  incls: null | (number | null)[];
  velocities_crit: null | (number | null)[];
  flow_rates_crit: null | (number | null)[];
  cuttings_heights: null | (number | null)[];
  linked_with_hydraulics_calc: string;
};

export type CutCalcUpdateType = {
  item_meta: Record<string, any> | null;
  item_data: Record<string, any> | null;
  liquid_density: null | number;
  plastic_viscosity: null | number;
  yield_point: null | number;
  rock_density: null | number;
  rock_part_diameter: null | number;
  rock_part_height: null | number;
  rop: null | number;
  rop_min: null | number;
  rop_max: null | number;
  rop_step: null | number;
  rpm: null | number;
  flow_rate: null | number;
  well_diameter_calced: null | number;
  well_diameter_by_user: null | number;
  volume_excess_factor: null | number;
  depths: null | (number | null)[];
  incls: null | (number | null)[];
  linked_with_hydraulics_calc: string;
};

export type CutCalculationBack = {
  rops: (number | null)[] | null;
  d_well: number | null;
} & Omit<
  ResearchCutBackType,
  | 'item_uuid'
  | 'item_meta'
  | 'item_data'
  | 'row_created_by'
  | 'row_changed_by'
  | 'row_changed_datetime'
  | 'row_created_datetime'
  | 'linked_with_hydraulics_calc'
  | 'volume_excess_factor'
  | 'well_diameter_calced'
  | 'well_diameter_by_user'
>;

export type CalculationCutBackType = {
  errors: string[];
  result: CutCalculationBack;
};

export type PressureCalcType = {
  itemUuid: string;
  flowRates: (number | null)[];
  tubePressureLosses: (number | null)[];
  itemMeta: null;
  itemData: null;
  rowChangedBy: null;
  rowChangedDatetime: string;
  rowCreatedBy: null;
  rowCreatedDatetime: string;
  liquidDensity: number;
  plasticViscosity: number;
  yieldPoint: number;
  maxPressure: number;
  casingPressureLosses: (number | null)[]; // ?
  bitPressureLosses: (number | null)[]; // ?
  systemPressureLosses: (number | null)[]; // ?
  wellDiameterByUser: number;
  linkedWithHydraulicsCalc: string;
  tube_pressure_loss?: number;
  casing_pressure_loss?: number;
  system_pressure_loss?: number;
};

export type ResearchPressureBackType = {
  item_uuid: string;
  flow_rates: null | (number | null)[];
  tube_pressure_losses: null | (number | null)[];
  item_meta: null;
  item_data: null;
  row_changed_by: null;
  row_changed_datetime: string;
  row_created_by: null;
  row_created_datetime: string;
  liquid_density: number;
  plastic_viscosity: number;
  yield_point: number;
  max_pressure: number;
  casing_pressure_losses: null | (number | null)[]; // ?
  bit_pressure_losses: null | (number | null)[]; // ?
  system_pressure_losses: null | (number | null)[]; // ?
  well_diameter_by_user: number;
  linked_with_hydraulics_calc: string;
  tube_pressure_loss: number;
  casing_pressure_loss: number;
  system_pressure_loss: number;
};

export type PressureUpdateType = {
  item_meta: Record<string, any> | null;
  item_data: Record<string, any> | null;
  liquid_density: null | number;
  plastic_viscosity: null | number;
  yield_point: null | number;
  max_pressure: null | number;
  well_diameter_by_user: null | number;
  linked_with_hydraulics_calc: string;
};

export type PressCalculationBack = {
  d_well: number;
} & Omit<
  ResearchPressureBackType,
  | 'item_uuid'
  | 'item_meta'
  | 'item_data'
  | 'row_created_by'
  | 'row_changed_by'
  | 'row_changed_datetime'
  | 'row_created_datetime'
  | 'linked_with_hydraulics_calc'
  | 'well_diameter_by_user'
>;

export type CalculationPressureBackType = {
  errors: string[];
  result: PressCalculationBack;
};

export enum HydEquipmentTypes {
  motor = 'motor', // Двигатель
  bit = 'bit', // Долото
  mwd = 'mwd', // Телесистема
  sdp = 'sdp', // (СБТ) Стальная бурильная труба
  ehdp = 'ehdp', // (УБТ) Утяжеленная бурильная труба
  hwdp = 'hwdp', // (ТБТ) Толстостенная бурильная труба
  casing = 'casing'
}

export type HydEquipmentCodeType = {
  code: HydEquipmentTypes;
  name: string;
  description: string;
};

export type HandbookEquipmentBackType = {
  item_uuid: string;
  item_meta: null;
  item_data: null;
  row_changed_by: null;
  row_changed_datetime: string;
  row_created_by: null;
  row_created_datetime: string;
  code: HydEquipmentTypes;
  name: string;
};

export type HandbookEquipmentType = {
  itemUuid: string;
  itemMeta: null;
  itemData: null;
  rowChangedBy: null;
  rowChangedDatetime: string;
  rowCreatedBy: null;
  rowCreatedDatetime: string;
  code: HydEquipmentTypes;
  name: string;
};

export type CalculationBodyType = {
  hydraulics_calc_uuid: string;
};

export type CalculationWellUuidBodyType = {
  well_uuid: string;
};

export type MetaTypeBack = {
  nozzle_count?: number | null;
  is_equal?: boolean | null;
  nozzle_sizes?: number[] | null;
};

export type MetaType = {
  nozzleCount?: number | null;
  isEqual?: boolean | null;
  nozzleSizes?: number[] | null;
};

export type HydEquipmentBackType = {
  item_uuid: string;
  item_meta: MetaTypeBack | null;
  muft_diameter_in?: number | null;
  muft_diameter_out?: number | null;
  item_data: null;
  row_changed_by: null;
  row_changed_datetime: string;
  row_created_by: null;
  row_created_datetime: string;
  type: HydEquipmentTypes;
  bha_index: number;
  name: string;
  diameter_in: number | null;
  diameter_out: number | null;
  length: number;
  inc_length: number | null;
  pressure_loss: number | null;
  linked_with_hydraulics_calc: string;
  unit_length?: number | null;
  muft_length?: number | null;

  unit_weight?: null | number;
  density?: null | number;
  makeup_torque?: null | number;
  young_modulus?: null | number;
  min_yield_point?: null | number;
  collapse_resistance?: null | number;
  poisson_ratio?: null | number;
  temperature_coeff?: null | number;
  steel?: string;
  material?: string;
  tool_joint_name?: string;
};

export type HydEquipmentType = {
  itemUuid: string;
  itemMeta: MetaType | null;
  muftDiameterIn?: number | null;
  muftDiameterOut?: number | null;
  itemData: null;
  rowChangedBy: null;
  rowChangedDatetime: string;
  rowCreatedBy: null;
  rowCreatedDatetime: string;
  type: HydEquipmentTypes;
  bhaIndex: number;
  name: string;
  diameterIn: number | null;
  diameterOut: number | null;
  length: number;
  incLength: number | null;
  pressureLoss: number | null;
  linkedWithHydraulicsCalc: string;
  unitLength?: number | null;
  muftLength?: number | null;

  unit_weight?: null | number;
  density?: null | number;
  makeup_torque?: null | number;
  young_modulus?: null | number;
  min_yield_point?: null | number;
  collapse_resistance?: null | number;
  poisson_ratio?: null | number;
  temperature_coeff?: null | number;
  steel?: string;
  material?: string;
  tool_joint_name?: string;
};

export type HydEquipmentUpdateType = Omit<
  HydEquipmentType,
  | 'rowChangedDatetime'
  | 'rowChangedBy'
  | 'rowCreatedDatetime'
  | 'rowCreatedBy'
  | 'ItemUuid'
>;

export type HydEquipmentUpdateBackType = Omit<
  HydEquipmentBackType,
  | 'row_changed_datetime'
  | 'row_changed_by'
  | 'row_created_datetime'
  | 'row_created_by'
  | 'item_uuid'
>;

export type HydEquipmentCreateType = Omit<
  HydEquipmentType,
  | 'rowCreatedBy'
  | 'rowCreatedDatetime'
  | 'rowChangedDatetime'
  | 'rowChangedBy'
  | 'itemUuid'
>;

export type HydEquipmentCreateBackType = Omit<
  HydEquipmentBackType,
  | 'row_created_by'
  | 'row_changed_datetime'
  | 'row_changed_by'
  | 'row_created_datetime'
  | 'item_uuid'
>;
