import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import * as ggdApi from './api';
import { createDrillingTypeOperationApi, getProjectDateApi } from './api';
import { EffectiveDateType, OperationTypes, ReorderOperationType } from './types';
import { createOperationConvertor, editOperationConvertor } from './convertor';
import { CreateDrillingFormType } from '../../pages/project-documentation/pages/well-doc/ggd/helpers';

const { name } = options;

export const getDrillingStageOptions = createAsyncThunk(
  `${name}/getDrillingStageOptions`,
  async (_, thunkAPI) => {
    try {
      return await ggdApi.getDrillingStageApi();
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getDrillingOperationType = createAsyncThunk(
  `${name}/getDrillingOperationType`,
  async (id: number, thunkAPI) => {
    try {
      return await ggdApi.getDrillingOperationTypeApi(id);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getDrillingOperation = createAsyncThunk(
  `${name}/getDrillingOperation`,
  async (wellBoreId: string, thunkAPI) => {
    try {
      return await ggdApi.getDrillingOperationApi(wellBoreId);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const createDrillingOperation = createAsyncThunk(
  `${name}/createDrillingOperationController`,
  async (body: CreateDrillingFormType, thunkAPI) => {
    try {
      await ggdApi.createDrillingOperationApi(createOperationConvertor(body));
      // запрашиваем весь список, так как update возвращает не полные данные
      return await ggdApi.getDrillingOperationApi(body.wellboreId);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const createDrillingType = createAsyncThunk<OperationTypes, CreateDrillingFormType>(
  `${name}/createDrillingType`,
  async (body, thunkAPI) => {
    try {
      return await createDrillingTypeOperationApi({
        name: body.note,
        note: '',
        stageId: body.stageId,
        defaultValue: body.value
      });
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getProjectDate = createAsyncThunk(
  `${name}/getProjectDate`,
  async (wellboreId: string, thunkAPI) => {
    try {
      return await getProjectDateApi(wellboreId);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updateProjectDate = createAsyncThunk(
  `${name}/updateProjectDate`,
  async (body: EffectiveDateType, thunkAPI) => {
    try {
      return await ggdApi.updateProjectDateApi(body);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteOperation = createAsyncThunk(
  `${name}/deleteOperation`,
  async (id: number, thunkAPI) => {
    try {
      await ggdApi.deleteOperationApi(id);
      return id;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteSomeOperation = createAsyncThunk(
  `${name}/deleteSomeOperation`,
  async (ids: number[], thunkAPI) => {
    try {
      await ggdApi.deleteSomeOperationApi(ids);
      return ids;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const editOperation = createAsyncThunk(
  `${name}/editOperation`,
  async (body: CreateDrillingFormType, thunkAPI) => {
    try {
      await ggdApi.editOperationApi(editOperationConvertor(body));
      // запрашиваем весь список, так как update возвращает не полные данные
      return await ggdApi.getDrillingOperationApi(body.wellboreId);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

type ReorderOperationParams = ReorderOperationType & {
    wellBoreId: string;
};

export const reorderOperation = createAsyncThunk(
  `${name}/reorderOperation`,
  async (body: ReorderOperationParams, thunkAPI) => {
    try {
      await ggdApi.reorderOperationApi({
        id: body.id,
        orderOld: body.orderOld,
        orderNew: body.orderNew
      });
      // запрашиваем весь список, так как update возвращает не полные данные
      return await ggdApi.getDrillingOperationApi(body.wellBoreId);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);