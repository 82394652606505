import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import * as reportApi from './api';
import { CreateProjectReportBodyType } from './types';

const { name } = options;

export const createWellProjectReport = createAsyncThunk(
  `${name}/createWellProjectReport`,
  async (body: CreateProjectReportBodyType, thunkApi) => {
    try {
      return await reportApi.createProjectReportApi(body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getWellProjectReport = createAsyncThunk(
  `${name}/getWellProjectReport`,
  async (wellId: string, thunkAPI) => {
    try {
      return await reportApi.getProjectReportApi(wellId);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);