import { createAsyncThunk } from '@reduxjs/toolkit';
import * as equipmentApi from './api';
import options from './options';
import {
  CreateTubularAssemblyBodyType,
  IDrillingMudType,
  EquipmentAssemblyType,
  UpdateAssemblyFormType,
  CreateNewTypeAndCatEquipmentType,
  IDrillingMudResponseType,
  GetEquipmentTypeListParams,
  GetEquipmentListParams,
  CreateEquipmentType,
  EquipmentType,
  EquipmentTypeType,
  CreateEquipmentTypeType,
  EquipmentMaterialType, EquipmentGradeType, MaterialInfoType
} from './types';
import { updateAssemblyBody2server } from './converters';

const { name } = options;

export const getEquipmentList = createAsyncThunk(
  `${name}/getEquipmentList`,
  async (query: { groupAbbr?: string, typeAbbr?: string } | undefined, thunkApi) => {
    try {
      return await equipmentApi.getEquipmentListApi({ ...query });
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getRigEquipmentList = createAsyncThunk(
  `${name}/getRigEquipmentList`,
  async (_, thunkApi) => {
    try {
      const stationList = await equipmentApi.getEquipmentListApi({
        typeAbbr: 'stationary_rig'
      });
      const mobileList = await equipmentApi.getEquipmentListApi({
        typeAbbr: 'mobile_rig'
      });
      return [...stationList, ...mobileList];
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getBhaEquipmentList = createAsyncThunk(
  `${name}/getBhaEquipmentList`,
  async (params: GetEquipmentListParams, thunkApi) => {
    try {
      return await equipmentApi.getEquipmentListApi(params);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getEquipmentTypeList = createAsyncThunk(
  `${name}/getEquipmentTypeList`,
  async (params: GetEquipmentTypeListParams, thunkApi) => {
    try {
      return await equipmentApi.getEquipmentTypeListApi(params);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getEquipmentGroupList = createAsyncThunk(
  `${name}/getEquipmentGroupList`,
  async (_, thunkApi) => {
    try {
      return await equipmentApi.getEquipmentGroupListApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

// сборки
export const getAssembliesByTubularId = createAsyncThunk(
  `${name}/getAssembliesByTubularId`,
  async (id: number, thunkApi) => {
    try {
      return await equipmentApi.getAssembliesByTubularIdApi(id);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteAssembliesByTubularId = createAsyncThunk(
  `${name}/deleteAssembliesByTubularId`,
  async (id: number, thunkApi) => {
    try {
      return await equipmentApi.deleteAssembliesByTubularIdApi(id);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getAssemblyById = createAsyncThunk(
  `${name}/getAssemblyById`,
  async (assemblyId: string, thunkApi) => {
    try {
      return await equipmentApi.getAssemblyByIdApi(assemblyId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createAssemblyByTubularId = createAsyncThunk(
  `${name}/createAssemblyByTubularId`,
  async (body: CreateTubularAssemblyBodyType, thunkApi) => {
    try {
      return await equipmentApi.createAssemblyByTubularIdApi(body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const updateAssemblyById = createAsyncThunk(
  `${name}/updateAssemblyById`,
  async ({ id, body }: { id: string, body: UpdateAssemblyFormType }, thunkApi) => {
    try {
      return await equipmentApi.updateAssemblyByIdApi(id, updateAssemblyBody2server(body));
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const duplicateAssemblyByTubularId = createAsyncThunk(
  `${name}/duplicateAssemblyByTubularId`,
  async (body: CreateTubularAssemblyBodyType, thunkApi) => {
    try {
      return await equipmentApi.createAssemblyByTubularIdApi(body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteAssemblyById = createAsyncThunk(
  `${name}/deleteAssemblyById`,
  async (assemblyId: string, thunkApi) => {
    try {
      return await equipmentApi.deleteAssemblyByIdApi(assemblyId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const updateAssemblyDetailById = createAsyncThunk(
  `${name}/updateAssemblyDetailById`,
  async ({ assemblyId, detailId, body }: { assemblyId: string, detailId: string, body: EquipmentAssemblyType }, thunkApi) => {
    try {
      return await equipmentApi.updateAssemblyDetailByIdApi(assemblyId, detailId, body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteAssemblyDetailById = createAsyncThunk(
  `${name}/deleteAssemblyDetailById`,
  async ({ assemblyId, detailId }: { assemblyId: string, detailId: string }, thunkApi) => {
    try {
      return await equipmentApi.deleteAssemblyDetailByIdApi(assemblyId, detailId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

// fluid

export const getDrillingMudsByTubularId = createAsyncThunk(
  `${name}/getDrillingMudsByTubularId`,
  async (id: number, thunkApi) => {
    try {
      return await equipmentApi.getDrillingMudsByTubularIdApi(id);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createDrillingMud = createAsyncThunk(
  `${name}/createDrillingMud`,
  async (body: IDrillingMudType, thunkApi) => {
    try {
      return await equipmentApi.createDrillingMudApi(body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createDrillingMudWithCopy = createAsyncThunk(
  `${name}/createDrillingMudWithCopy`,
  async (body: IDrillingMudType, thunkApi) => {
    try {
      return await equipmentApi.createDrillingMudWithCopyApi(body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const updateDrillingMudByTubularId = createAsyncThunk(
  `${name}/updateDrillingMudByTubularId`,
  async ({ mudId, body } : { mudId: string, body: IDrillingMudResponseType }, thunkApi) => {
    try {
      return await equipmentApi.updateDrillingMudByTubularIdApi(mudId, body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createNewTypeAndCatEquipment = createAsyncThunk(
  `${name}/createNewTypeAndCatEquipment`,
  async (body: CreateNewTypeAndCatEquipmentType, thunkApi) => {
    try {
      return await equipmentApi.createNewTypeAndCatEquipmentApi(body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteDrillingMudById = createAsyncThunk(
  `${name}/deleteDrillingMudById`,
  async (mudId: string, thunkAPI) => {
    try {
      await equipmentApi.deleteDrillingMudByIdApi(mudId);
      return mudId;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
export const deleteAllDrillingMudsById = createAsyncThunk(
  `${name}/deleteAllDrillingMudsById`,
  async (id: number, thunkAPI) => {
    try {
      return await equipmentApi.deleteAllDrillingMudsByIdApi(id);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const createEquipment = createAsyncThunk<EquipmentType, CreateEquipmentType>(
  `${name}/createEquipment`,
  async (body, thunkAPI) => {
    try {
      return await equipmentApi.createEquipmentApi(body);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

type EditEquipmentParams = {
  id: string;
  body: CreateEquipmentType;
};

export const editEquipment = createAsyncThunk<EquipmentType, EditEquipmentParams>(
  `${name}/editEquipment`,
  async ({ id, body }, thunkAPI) => {
    try {
      return await equipmentApi.editEquipmentApi(id, body);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteEquipment = createAsyncThunk<string, string>(
  `${name}/deleteEquipment`,
  async (id, thunkAPI) => {
    try {
      await equipmentApi.deleteEquipmentApi(id);
      return id;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const createEquipmentType = createAsyncThunk<EquipmentTypeType, CreateEquipmentTypeType>(
  `${name}/createEquipmentType`,
  async (body, thunkAPI) => {
    try {
      return await equipmentApi.createEquipmentTypeApi(body);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getEquipmentMaterial = createAsyncThunk<EquipmentMaterialType[]>(
  `${name}/getEquipmentMaterial`,
  async (_, thunkAPI) => {
    try {
      return await equipmentApi.getEquipmentMaterialApi();
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getGradeByMaterialId = createAsyncThunk<EquipmentGradeType[], number | string>(
  `${name}/getGradeByMaterialId`,
  async (materialId, thunkAPI) => {
    try {
      return await equipmentApi.getGradeByMaterialIdApi(materialId);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getGradeMaterialInfo = createAsyncThunk<MaterialInfoType, number | string>(
  `${name}/getGradeMaterialInfo`,
  async (gradeId, thunkAPI) => {
    try {
      return await equipmentApi.getGradeMaterialInfoApi(gradeId);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);