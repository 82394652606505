import {
  useContext, useEffect, useRef, useState
} from 'react';
import ToastContext from 'contexts/toast';

// использование встроенных в праймреакт тостов
export function useToast() {
  const { toast, life } = useContext(ToastContext);

  const showToast = {
    success: (message: string, title = '') => toast.current.show({
      severity: 'success', summary: title, detail: message, life
    }),
    error: (message: string, title = '') => toast.current.show({
      severity: 'error', summary: title || 'Ошибка', detail: message || 'Неизвестная ошибка', life
    }),
    info: (message: string, title = '') => toast.current.show({
      severity: 'info', summary: title, detail: message, life
    })
  };

  return { showToast };
}

// использование Localstorage
export const useLocalStorage = (keyName: string, defaultValue: string) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = localStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      } else {
        localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue: string) => {
    try {
      localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

// использование предыдущего значения в useEffect, для того чтобы можно было сравнить два значения.
// Чтобы получить аналог PrevProps в ComponentDidUpdate
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
Пример использования:

const Component = ({counter}) => {
    const prevCounter = usePrevious(counter);
    useEffect(() => {
        if(prevCounter !== counter) {

         // какаято логика если изменился counter
        }
    }, [counter])
}
*/
