import React, { useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';

import Auth from '../../contexts/auth';
import { useClassName } from '../../utils/cn';

import { HeaderNotification } from './header-notification';
import { HeaderSearch } from './components/headerSearch';
import { HeaderBookmarkedAutocomplete } from './components/headerBookmarkedAutocomplete';

import './style.scss';

export default (): JSX.Element => {
  const cn = useClassName('header');
  const { user, onLogout } = useContext(Auth);
  const menu = useRef<Menu | null>(null);
  const { t } = useTranslation('common', { keyPrefix: 'header' });
  const navigate = useNavigate();

  const menuItems = [
    {
      label: t('change-user'),
      command: () => {},
      disabled: true
    },
    {
      label: t('change-pas'),
      command: () => {},
      disabled: true
    },
    {
      label: t('add-user'),
      command: () => {
        const location = {
          pathname: '/registration',
          state: { fromDashboard: true }
        };
        navigate(location);
      }
    },
    {
      label: t('exit'),
      command: onLogout
    }
  ];

  return (
    <div className="layout-topbar flex justify-content-between align-items-center">
      <div className={cn('search-wrapper')}>
        <div className={cn('favorite-search-wrapper')}>
          <i className="pi pi-star-fill" />
          <HeaderBookmarkedAutocomplete />
        </div>

        <div className={cn('well-search-wrapper')}>
          <HeaderSearch />
        </div>
      </div>
      <div className={cn('right-wrapper')}>
        <div className={cn('username-wrapper')}>
          <Menu
            model={menuItems}
            popup
            style={{ width: 240 }}
            ref={menu}
            id="popup_menu"
          />
          <Button
            label={user?.userName || ''}
            icon="pi pi-angle-down"
            iconPos="right"
            className={`p-button p-button-text p-button-secondary ${cn('user-button')}`}
            onClick={(event) => {
              menu.current?.toggle(event);
            }}
            aria-controls="popup_menu"
            aria-haspopup
          />
        </div>
        <HeaderNotification user={user} />
      </div>
    </div>
  );
};
