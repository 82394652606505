import { getTokenString } from 'utils/http/utils';
import { AxiosResponse } from 'axios';
import options from './options';
import { getUrl } from '../utils';
import request from '../../utils/http/request';
import {
  ResearchBitBackType,
  BitCalcUpdateType,
  CalculationBitBackType,
  CalculationBodyType,
  CalculationCutBackType,
  CalculationEcdBackType,
  CalculationPressureBackType,
  ResearchCutBackType,
  CutCalcUpdateType,
  ResearchEcpBackType,
  EcpCalcUpdateType,
  HandbookEquipmentBackType,
  HydEquipmentBackType,
  HydEquipmentCreateBackType,
  HydraulicCalcCreateType,
  HydraulicCalcType,
  HydraulicNotFound,
  ResearchPressureBackType,
  PressureUpdateType,
  HydEquipmentUpdateBackType,
  CalculationWellUuidBodyType
} from './types';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getHydEquipmentTypeApi = async (): Promise<
  HandbookEquipmentBackType[]
> => request.get(url('hydraulics/equipment/equipment_type/'));

export const getSdPipeApi = async (): Promise<HandbookEquipmentBackType[]> =>
  request.get(url('hydraulics/handbook/sd_pipe/'));

export const getBitApi = async (): Promise<HandbookEquipmentBackType[]> =>
  request.get(url('hydraulics/handbook/bit/'));

export const getEhdPipeApi = async (): Promise<HandbookEquipmentBackType[]> =>
  request.get(url('hydraulics/handbook/ehd_pipe/'));

export const getHwdPipeApi = async (): Promise<HandbookEquipmentBackType[]> =>
  request.get(url('hydraulics/handbook/hwd_pipe/'));

export const getMotorApi = async (): Promise<HandbookEquipmentBackType[]> =>
  request.get(url('hydraulics/handbook/motor/'));

export const getMwdApi = async (): Promise<HandbookEquipmentBackType[]> =>
  request.get(url('hydraulics/handbook/mwd/'));

export const getHydraulicCalcApi = async (
  wellId: string
): Promise<HydraulicCalcType[] | HydraulicNotFound> =>
  request.get(
    url(`hydraulics/research/hydraulics_calc/?well_uuid_outer=${wellId}`)
  );

export const createHydraulicCalcApi = async (
  body: HydraulicCalcCreateType
): Promise<HydraulicCalcType> =>
  request.post(url('hydraulics/research/hydraulics_calc/'), body);

export const createHydraulicEqApi = async (
  body: HydEquipmentCreateBackType
): Promise<HydEquipmentBackType> =>
  request.post(url('hydraulics/equipment/equipment/'), body);

export const getEcdCalcApi = async (
  hydraulicCalcId: string
): Promise<ResearchEcpBackType[]> =>
  request.get(
    url(
      `hydraulics/research/ecd_calc/?linked_with_hydraulics_calc=${hydraulicCalcId}`
    )
  );

export const updateEcdCalcApi = async (
  ecpId: string,
  body: EcpCalcUpdateType
): Promise<ResearchEcpBackType> =>
  request.put(url(`hydraulics/research/ecd_calc/${ecpId}/`), body);

export const getHydEquipmentsApi = async (
  hydraulicCalcId: string
): Promise<HydEquipmentBackType[] | HydraulicNotFound> =>
  request.get(
    url(
      `hydraulics/equipment/equipment/?linked_with_hydraulics_calc=${hydraulicCalcId}`
    )
  );

export const calculateEcdApi = async (
  body: CalculationBodyType
): Promise<CalculationEcdBackType> =>
  request.post(url('hydraulics/calculation/ecd/'), body);

export const deleteHydEquipmentApi = async (
  id: string
): Promise<HydEquipmentBackType> => request.delete(`/api/hydraulics/${id}/`);

export const updateHydEquipmentApi = async (
  id: string,
  body: HydEquipmentUpdateBackType
): Promise<HydEquipmentBackType> =>
  request.put(url(`hydraulics/equipment/equipment/${id}/`), body);

export const getBitCalcApi = async (
  hydraulicCalcId: string
): Promise<ResearchBitBackType[]> =>
  request.get(
    url(
      `hydraulics/research/bit_tfa_calc/?linked_with_hydraulics_calc=${hydraulicCalcId}`
    )
  );

export const updateBitCalcApi = async (
  bitId: string,
  body: BitCalcUpdateType
): Promise<ResearchBitBackType> =>
  request.put(url(`hydraulics/research/bit_tfa_calc/${bitId}/`), body);

export const calculateBitApi = async (
  body: CalculationBodyType
): Promise<CalculationBitBackType> =>
  request.post(url('hydraulics/calculation/bit_tfa/'), body);

export const getCutCalcApi = async (
  hydraulicCalcId: string
): Promise<ResearchCutBackType[]> =>
  request.get(
    url(
      `hydraulics/research/cuttings_calc/?linked_with_hydraulics_calc=${hydraulicCalcId}`
    )
  );

export const updateCutCalcApi = async (
  cutId: string,
  body: CutCalcUpdateType
): Promise<ResearchCutBackType> =>
  request.put(url(`hydraulics/research/cuttings_calc/${cutId}/`), body);

export const calculateCutCalcApi = async (
  body: CalculationBodyType
): Promise<CalculationCutBackType> =>
  request.post(url('hydraulics/calculation/cuttings/'), body);

export const getPressureLossApi = async (
  hydraulicId: string
): Promise<ResearchPressureBackType[]> =>
  request.get(
    url(
      `hydraulics/research/pressure_loss_calc/?linked_with_hydraulics_calc=${hydraulicId}`
    )
  );

export const updatePressureLossApi = async (
  pressureId: string,
  body: PressureUpdateType
): Promise<ResearchPressureBackType> =>
  request.put(
    url(`hydraulics/research/pressure_loss_calc/${pressureId}/`),
    body
  );

export const calculatePressureLossApi = async (
  body: CalculationBodyType
): Promise<CalculationPressureBackType> =>
  request.post(url('hydraulics/calculation/pressure_loss/'), body);

export const calculateCementingApi = async (
  body: CalculationWellUuidBodyType
): Promise<CalculationPressureBackType> =>
  request.post(url('hydraulics/calculation/cementing/'), body);

export const downloadReportApi = async (body: {
  well_uuid: string;
}): Promise<AxiosResponse<Blob>> =>
  request.instance({
    url: url('hydraulics/report/hydraulics/'),
    method: 'POST',
    responseType: 'blob',
    headers: {
      Authorization: getTokenString()
    },
    data: body
  });
