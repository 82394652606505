import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import {
  deleteTrajectoryCalcByUid,
  exportTrajectoryCalcByUid,
  getAllTrajectoryCalc,
  getTrajectoryCalcByUid,
  startTrajectoryCalc
} from './actions';
import {
  TrajectoryCalcDetailsListResponseType,
  TrajectoryCalcListType,
  TrajectoryCalcPostResponseType, TrajectoryCalcType
} from './types';

const { name } = options;

type CalcState = {
    isLoading: boolean
    createLoading: boolean
    calcDetailsLoading: boolean
    trajectoryCalculatedList: TrajectoryCalcType[]
    trajectoryCalcDetailed: TrajectoryCalcDetailsListResponseType | null
    error: string
};

const initialState: CalcState = {
  isLoading: false,
  createLoading: false,
  calcDetailsLoading: false,
  trajectoryCalculatedList: [],
  trajectoryCalcDetailed: null,
  error: ''
};

export const calculationsSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearTrajectoryCalculatedList(state) {
      state.trajectoryCalculatedList = [];
    },
    clearTrajectoryCalcDetailed(state) {
      state.trajectoryCalcDetailed = null;
    }
  },
  extraReducers: {
    [getAllTrajectoryCalc.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getAllTrajectoryCalc.fulfilled.type]: (state, { payload }: PayloadAction<TrajectoryCalcType[]>) => {
      state.isLoading = false;
      state.trajectoryCalculatedList = payload;
      state.error = '';
    },
    [getAllTrajectoryCalc.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getTrajectoryCalcByUid.pending.type]: (state) => {
      state.calcDetailsLoading = true;
      state.trajectoryCalcDetailed = null;
    },
    [getTrajectoryCalcByUid.fulfilled.type]: (state, { payload }: PayloadAction<TrajectoryCalcDetailsListResponseType>) => {
      state.calcDetailsLoading = false;
      state.trajectoryCalcDetailed = payload;
      state.error = '';
    },
    [getTrajectoryCalcByUid.rejected.type]: (state, action: PayloadAction<string>) => {
      state.calcDetailsLoading = false;
      state.error = action.payload;
    },
    [startTrajectoryCalc.pending.type]: (state) => {
      state.createLoading = true;
    },
    [startTrajectoryCalc.fulfilled.type]: (state, { payload }: PayloadAction<TrajectoryCalcPostResponseType>) => {
      state.createLoading = false;
      // state.trajectoryCalcOffsetWells = payload;
      state.error = '';
    },
    [startTrajectoryCalc.rejected.type]: (state, action: PayloadAction<string>) => {
      state.createLoading = false;
      state.error = action.payload;
    },
    [deleteTrajectoryCalcByUid.pending.type]: (state) => {
      state.isLoading = true;
    },
    [deleteTrajectoryCalcByUid.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.trajectoryCalculatedList = state.trajectoryCalculatedList.filter(item => item.item_uuid !== payload);
      state.error = '';
    },
    [deleteTrajectoryCalcByUid.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [exportTrajectoryCalcByUid.pending.type]: (state) => {
      state.isLoading = true;
    },
    [exportTrajectoryCalcByUid.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = '';
    },
    [exportTrajectoryCalcByUid.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export default calculationsSlice.reducer;
