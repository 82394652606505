const options = {
  name: 'dicts',
  url: '/dicts',

  wellName: 'well',
  wellUrl: '/well',

  fieldName: 'field',
  fieldUrl: '/field'
};

export default options;
