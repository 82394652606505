import options from './options';
import { getUrl } from '../utils';
import request from '../../utils/http/request';
import {
  ChangeInstallationStepResponseType,
  ChangeInstallationStepType,
  CreateWellCommentType,
  DrillingInfoType,
  EditPlaningWellType,
  GetInstallationInfoType,
  InstallationInfoType,
  IWorkType,
  PlaningSearchParamsType,
  PlaningWellType,
  SchedulerSearchParamsType,
  SchedulerWellBackType
} from './types';
import { IShortPaging } from '../../interfaces';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const schedulerSearchApi = (
  body: SchedulerSearchParamsType
): Promise<IShortPaging<SchedulerWellBackType>> =>
  request.post(url('well_construction_stage_work/search'), body);

export const getWorkTypesApi = (): Promise<IWorkType[]> =>
  request.get(url('well_construction_work_type'));

export const getInstallationInfoApi = (
  body: GetInstallationInfoType
): Promise<InstallationInfoType[]> =>
  request.post(url('well_construction_stage_work/getStagesByWorkId'), body);

export const getDrillingInfoApi = (id: string): Promise<DrillingInfoType> =>
  request.get(`/api/tech_operation/getMaxTechOperationsByWellboreId/${id}`);

export const nextStepStageApi = (
  body: ChangeInstallationStepType
): Promise<ChangeInstallationStepResponseType> =>
  request.post(url('well_construction_stage_work/createSuccessStage'), body);

export const planingSearchApi = (
  body: PlaningSearchParamsType
): Promise<IShortPaging<PlaningWellType>> =>
  request.post(url('well_construction_stage_work/searchPlan'), body);

export const editPlaningWellApi = (body: EditPlaningWellType) =>
  request.post(url('well_construction_stage_work/editSearchPlan'), body);

export const createWellCommentApi = (body: CreateWellCommentType) =>
  request.post(url('comment'), body);
