import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import {
  createArea,
  createAreaLinkedToField,
  getAreaDetails, getAreaFacilityDetails, getAreas, getAreasForm
} from './actions';
import {
  AreaDetailType, AreaFacilityDetails, AreaFormBackType, AreaFormType, AreaType, CreateAreaResponseType
} from './types';
import {
  areaDetailToClient, convertArea, convertAreaFacDetail, convertAreas, rus2eng
} from './converters';

const { name } = options;

type AreaState = {
    isLoading: boolean
    areas: AreaType[],
    error: string,
    areasForm: AreaFormType[],
    areaDetails: AreaDetailType[],
    areaFacilityDetails: AreaFacilityDetails
};

const areaFacilityDetailsInitial = {
  areaId: '',
  areaName: '',
  areaType: '',
  wellRisk: null,
  facilities: []
};

const initialState: AreaState = {
  isLoading: false,
  areas: [],
  error: '',
  areasForm: [],
  areaDetails: [],
  areaFacilityDetails: areaFacilityDetailsInitial
};

export const areaSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearAreasForm(state) {
      state.areasForm = [];
    },
    clearAreaFacilityDetails(state) {
      state.areaFacilityDetails = areaFacilityDetailsInitial;
    },
    clearAreaDetail(state) {
      state.areaDetails = [];
    }
  },
  extraReducers: {
    [getAreasForm.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getAreasForm.fulfilled.type]: (state, action: PayloadAction<AreaFormBackType[]>) => {
      state.isLoading = false;
      state.areasForm = rus2eng(action.payload);
      state.error = '';
    },
    [getAreasForm.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getAreas.fulfilled.type]: (state, action: PayloadAction<AreaType[]>) => {
      state.isLoading = false;
      state.areas = convertAreas(action.payload);
      state.error = '';
    },
    [getAreas.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getAreas.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getAreaDetails.pending.type]: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    [getAreaDetails.fulfilled.type]: (state, action: PayloadAction<AreaDetailType[]>) => {
      state.isLoading = false;
      state.areaDetails = areaDetailToClient(action.payload);
      state.error = '';
    },
    [getAreaDetails.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getAreaFacilityDetails.pending.type]: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    [getAreaFacilityDetails.fulfilled.type]: (state, action: PayloadAction<AreaFacilityDetails>) => {
      state.isLoading = false;
      state.areaFacilityDetails = convertAreaFacDetail(action.payload);
      state.error = '';
    },
    [getAreaFacilityDetails.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [createAreaLinkedToField.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createAreaLinkedToField.fulfilled.type]: (state, { payload }: PayloadAction<{ areasList: [], newArea: {} }>) => {
      state.isLoading = false;
      state.areas = convertAreas(payload.areasList);
      state.error = '';
    },
    [createAreaLinkedToField.rejected.type]: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    [createArea.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createArea.fulfilled.type]: (state, { payload }: PayloadAction<CreateAreaResponseType>) => {
      state.isLoading = false;
      state.areas.push(convertArea(payload.dto));
      state.error = '';
    },
    [createArea.rejected.type]: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});

export default areaSlice.reducer;
