import { createAsyncThunk } from '@reduxjs/toolkit';
import { getResponseErrorMessage } from 'utils/http/utils';
import * as authApi from './api';
import options from './options';

import { SignInBody } from './types';

const { name } = options;

export const signIn = createAsyncThunk(
  `${name}/signIn`,
  async (body: SignInBody, thunkApi) => {
    try {
      return await authApi.signInApi(body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(getResponseErrorMessage(e));
    }
  }
);

export const signOut = createAsyncThunk(
  `${name}/signOut`,
  async (userLogin: string, thunkApi) => {
    try {
      return await authApi.signOutApi(userLogin);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const checkAuth = createAsyncThunk(
  `${name}/checkAuth`,
  async (_, thunkApi) => {
    try {
      return await authApi.checkAuthApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
