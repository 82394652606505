import React, { useRef, useState } from 'react';
import { useClassName } from 'utils/cn';
import { Ripple } from 'primereact/ripple';
import { NavLink } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { useTranslation } from 'react-i18next';
import { bottomMenuList, menuList } from './consts';
import { breadcrumbSlice } from '../../models/breadcrumb/slice';
import { useAppDispatch } from '../../store/hooks';
import { isGreenTheme } from '../../utils/utils';
import './style.scss';

interface IMainMenuItem {
    index: number;
    head?: boolean,
    label: string;
    primeIcon?: string;
    customIcon?: string;
    to: string;
    items?: any[];
    title?:string;
    badge?: any;
    url?: string;
    target?: string;
    disabled?: boolean;
    fill?: boolean,
    iconComponent?: React.FC<React.SVGAttributes<SVGElement>>;
    langKey: string;
}

export default ({ isSmall, setIsSmall }: { isSmall: boolean, setIsSmall: (isSmall: boolean) => void }): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<any>(1);
  const { t } = useTranslation('common', { keyPrefix: 'sidebar' });
  const cn = useClassName('side-menu');
  const dispatch = useAppDispatch();
  const { sideMenuChange } = breadcrumbSlice.actions;
  // const [submenuItems, setSubmenuItems] = useState<any>([]);

  const onSwitchButtonClick = () => setIsSmall(!isSmall);

  const isItsChildActive = (index: number, item:IMainMenuItem) => {
    if (!item.items) {
      return false;
    }
    return (activeIndex >= item.index + 1
            && activeIndex <= item.index + item.items.length);
  };

  const AppMenu: React.FC<any> = (props) => {
    const onMenuItemClick = (event: any, item: IMainMenuItem) => {
      // avoid processing disabled items
      if (item.disabled) {
        event.preventDefault();
        // return true;
      }
      if (item.index === activeIndex) {
        // setActiveIndex(null);
        dispatch(sideMenuChange({
          label: item.label,
          to: item.to
        }));
      } else {
        setActiveIndex(item.index);
        dispatch(sideMenuChange({
          label: item.label,
          to: item.to
        }));
      }

      if (props.onMenuItemClick) {
        props.onMenuItemClick({
          originalEvent: event,
          item
        });
      }
    };

    const renderTitle = (titleItem: any) => (
      <span>{t(titleItem.langKey)}</span>
    );

    const printIcon = (item: IMainMenuItem) => (
      item.primeIcon
        ? <i className={`${item.primeIcon} ${cn('menu-icon')} menu-icon-color`} />
        : <span className={`${item.customIcon} ${cn('menu-icon')} menu-icon-color`} />
    );

    const renderSmallMenuItem = (item: IMainMenuItem) => printIcon(item);

    const renderLargeMenuItem = (item: IMainMenuItem) => {
      const submenuIcon = item.items
                && <i className="pi pi-fw pi-angle-right menuitem-toggle-icon" />;
      const badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

      return (
        <>
          {printIcon(item)}
          <span>{t(item.langKey)}</span>
          {submenuIcon}
          {badge}
          <Ripple />
        </>
      );
    };

    const renderLinkContent = (item: any) => {
      if (item.head) {
        if (isSmall) return ('');
        else return (renderTitle(item));
      }

      return (
        <>
          {isSmall ? (
            renderSmallMenuItem(item as IMainMenuItem)
          ) : (
            renderLargeMenuItem(item as IMainMenuItem)

          )}
        </>
      );
    };

    const getLinkClasses = (filled:boolean = false, disabled: boolean = false) =>
      `p-ripple  ${isSmall ? 'flex justify-content-center' : ''} 
            ${!filled ? cn('no-fill') : ''} ${disabled ? 'p-disabled' : ''}`;

    const renderLink = (item: IMainMenuItem) => {
      const content = renderLinkContent(item);
      const menu = useRef<any>(null);

      const random = Math.floor(Math.random() * 10000).toString();
      const id = `tooltip-target-key-${random}`;

      if (item.to) {
        return (
          <NavLink
            className={({ isActive }) =>
              (isActive ? `${getLinkClasses(item.fill, item.disabled)} side-menu__active`
                : getLinkClasses(item.fill, item.disabled))}
            to={item.to}
            onClick={(e) => onMenuItemClick(e, item)}
            end={item.to === '/'}
            id={id}
          >
            {content}
            {isSmall ? <Tooltip target={`#${id}`} content={t(item.langKey)} position="right" /> : ''}
          </NavLink>
        );
      } else if (item.items) {
        if (isSmall) {
          return (
            <>
              <a className={getLinkClasses(item.fill, item.disabled)}
                onClick={(event) => {
                  event.preventDefault();
                  menu.current.toggle(event);
                }}
              >
                {content}
              </a>
              <Menu
                className={cn('small-submenu')}
                model={
                  item.items.map((tempItem: any) => {
                    const templateItem = { ...tempItem };
                    templateItem.template = () => (
                      <NavLink
                        className={({ isActive }) =>
                          (isActive ? 'popupLink side-menu__active'
                            : `popupLink ${tempItem.disabled ? 'p-disabled' : ''}`)}
                        to={tempItem.to}
                        onClick={(e) => onMenuItemClick(e, tempItem)}
                        end
                      >
                        {t(tempItem.langKey)}
                      </NavLink>
                    );
                    return (templateItem);
                  })
                }
                popup
                ref={menu}
              />
            </>
          );
        }

        const [submenuCollapsed, setSubmenuCollapsed] = useState<boolean>(true);

        return (
          <>
            <a className={getLinkClasses(item.fill, item.disabled)}
              onClick={(event) => {
                event.preventDefault();
                if (submenuCollapsed) {
                  menu.current.style.height = 'auto';
                  setSubmenuCollapsed(!submenuCollapsed);
                } else {
                  menu.current.style.height = '0';
                  setSubmenuCollapsed(!submenuCollapsed);
                }
              }}
            >
              {content}
            </a>
            <ul className={cn('submenu')}
              ref={menu}
              style={!isItsChildActive(activeIndex, item) ? { height: '0px' }
                : { height: 'auto' }}
            >
              {
                item.items.map((tempItem) => (
                  <li className={`${getListItemClasses(tempItem)}`}>
                    {renderLink(tempItem)}
                  </li>
                ))
              }
            </ul>
          </>
        );
      } else {
        return (
          <> {content} </>
        );
      }
    };

    const getListItemClasses = (item: IMainMenuItem) => {
      let classes: string = 'side-menu__item ';
      if (props.bottom) return classes;

      if (isItsChildActive(activeIndex, item) && isSmall) classes += cn('active-parent ');

      if (isSmall && item.items) classes += cn('small-item ');

      return classes;
    };

    const getListClasses = () => (isSmall ? cn('scroll-list-minimized') : cn('scroll-list'));

    const getSizeClasses = () => (props.bottom ? cn('bottom') : cn('main'));

    const getListTitleClasses = () => (isSmall ? cn('bordered') : 'side-menu__item side-menu__title');

    return (
      <>
        <ul className={`${getListClasses()} ${getSizeClasses()}`}>
          {!!props.items?.length && props.items
            .map((item: IMainMenuItem) => (
              <li key={item.label}
                className={item.head ? getListTitleClasses()
                  : getListItemClasses(item)}
              >
                {renderLink(item)}
              </li>
            ))}
        </ul>
      </>
    );
  };

  const renderMenuButton = (icon: string) => (
    <span className={`${icon} ${cn('menu-icon menu-icon-color ')}`} />
  );

  const SideMenuButton: React.FC<{ onSwitchButtonClick: (e: any) => void }> = ({ onSwitchButtonClick }) => (
    <div
      className={`${cn('switcher')} ${isSmall ? 'justify-content-center' : ''}`}
      onClick={onSwitchButtonClick}
    >
      {isSmall
        ? renderMenuButton('icon-closed-sidebar-menu') : (
          <>
            {renderMenuButton('icon-sidebar-menu')}
            <span>{t('collapse-panel')}</span>
          </>
        )}
    </div>
  );

  const SidebarHeader: React.FC<any> = (props) => (
    <header className="sidebar-header">
      {
        isGreenTheme() ? (
          <>
            <img src="/static/images/logo_cut.png" className="logo" title="logo" alt="logo" />
            {!isSmall ? <h2>{t('dd')}</h2> : ''}
          </>
        ) : (
          <img src="/static/images/drilling-log.svg"
            style={{ height: 32, width: 32 }}
            title="logo"
            alt="logo"
          />
        )
      }
    </header>
  );

  return (
    <section
      className={`sidebar flex flex-column justify-content-between ${cn()}`}
      style={isSmall ? { minWidth: 'var(--sidebar-min-width)' } : { minWidth: 'var(--sidebar-max-width)' }}
    >
      <SidebarHeader />
      <AppMenu items={menuList}
        scrollable
      />
      <AppMenu items={bottomMenuList}
        classNames=""
        bottom
      />

      <SideMenuButton onSwitchButtonClick={onSwitchButtonClick} />
    </section>
  );
};
