import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClassName } from '../../../utils/cn';

export default () => {
  const { t } = useTranslation('common', { keyPrefix: 'auth' });
  const slides = [
    t('slide-1'),
    t('slide-2'),
    t('slide-3')
  ];

  const cn = useClassName('auth-slider');
  const [active, setActive] = useState<number>(1);
  const [intervalRef, setiIntervalRef] = useState<any>();
  const slideChangeTimeout = 5000;

  const onBadgeClick = (event: React.MouseEvent<any>, index: number) => {
    clearInterval(intervalRef);
    setActive(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((current) => (current === slides.length - 1 ? 0 : current + 1));
    }, slideChangeTimeout);
    setiIntervalRef(interval);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={cn()}>
      <h3>
        {t('dd-is')}
      </h3>
      <p>
        {slides[active]}
      </p>

      <div className={cn('badges')}>
        {slides.map((slide, index) => (
          <span key={slide}
            className={active === index ? 'active' : ''}
            onClick={(event) => onBadgeClick(event, index)}
          />
        ))}
      </div>
    </div>
  );
};
