import {
  CreateProjectFourthStepBodyType,
  CreateProjectFourthStepFormType,
  CreateProjectThirdStepBodyType,
  CreateProjectThirdStepFormType, WellConstructionStageType, WellConstructionWorkType
} from './types';
import { convertDegreesToDecimal, cutNumbers } from '../../utils/utils';
import { formatDate } from '../../utils/date';

export const well2Client = (well: any) => ({
  uid: well['уид'] || '',
  wellName: well['скважина_имя'] || '',
  wellStatus: well['статус_тип'] || '',
  globalId: well['глобальный_ид'] || '',
  isBookmarked: well.isBookmarked
});

// todo remove old stuff after checks
export const rus2eng = (wells: any[]): any[] => wells.map(well2Client);

export const createThirdStepBody2Server = (values: CreateProjectThirdStepFormType): CreateProjectThirdStepBodyType => {
  const {
    facilityId,
    facilityType,
    wellId,
    wellName,
    groundElev,
    rotaryTableElev,
    coordinateSystemType,
    // xOffset,
    // yOffset,
    convergence,
    magneticDeclinationDegree,
    magneticDeclinationMinute,
    magneticDeclinationSecond,
    // latitudeDegree,
    // latitudeMinute,
    // latitudeSecond,
    // longitudeDegree,
    // longitudeMinute,
    // longitudeSecond,
    wellAlias,

    preparePeriod,
    assemblyDatePeriod,
    equipmentPeriod,
    sitePeriod,
    powerLinePeriod,

    xoffset,
    yoffset,
    coordinateSystem,
    longitude,
    latitude
  } = values;

  const magneticDeclination = convertDegreesToDecimal(
    magneticDeclinationDegree,
    magneticDeclinationMinute,
    magneticDeclinationSecond
  );
  // const latitude = convertDegreesToDecimal(latitudeDegree, latitudeMinute, latitudeSecond);
  // const longitude = convertDegreesToDecimal(longitudeDegree, longitudeMinute, longitudeSecond);

  const stageList = [
    preparePeriod && {
      stageWorkTypeId: 1, // подготовка участка
      scheduledStartDate: preparePeriod[0],
      scheduledEndDate: preparePeriod[1]
    },
    assemblyDatePeriod && {
      stageWorkTypeId: 2, // сборка буровой
      scheduledStartDate: assemblyDatePeriod[0],
      scheduledEndDate: assemblyDatePeriod[1]
    },
    equipmentPeriod && {
      stageWorkTypeId: 3, // обкатка
      scheduledStartDate: equipmentPeriod[0],
      scheduledEndDate: equipmentPeriod[1]
    },
    sitePeriod && {
      stageWorkTypeId: 4, // обкатка
      scheduledStartDate: sitePeriod[0],
      scheduledEndDate: sitePeriod[1]
    },
    powerLinePeriod && {
      stageWorkTypeId: 5, // обкатка
      scheduledStartDate: powerLinePeriod[0],
      scheduledEndDate: powerLinePeriod[1]
    }
  ].filter(Boolean) as WellConstructionStageType[];

  const wellConstructionWorkStageList: WellConstructionWorkType[] = [
    {
      workTypeId: 1, // 1 - монтаж
      stageList
    }
  ];

  return {
    uid: wellId || null,
    facilityId,
    facilityType,
    wellName,
    groundElev,
    rotaryTableElev,
    coordinateSystemType,
    xoffset,
    yoffset,
    convergence: convergence || null,
    magneticDeclination: Number(cutNumbers(magneticDeclination, 5)),
    // latitude: Number(cutNumbers(values.latitude, 5)),
    // longitude: Number(cutNumbers(values.longitude, 5)),
    depthObsNum: 1,
    measurementId: '1',
    source: '1',
    wellAlias,
    wellConstructionWorkStageList,
    coordinateSystem,
    longitude,
    latitude
  };
};

export const createFourthStepBody2Server = (values: CreateProjectFourthStepFormType): CreateProjectFourthStepBodyType => {
  const {
    wellBoreType,
    effectiveDate,
    wellId,
    wellBoreName,
    groundElev,
    rotaryTableElev,
    coordinateX,
    coordinateY,
    coordinateZ,
    coordinateSystem,

    bottomHoleLatitude,
    bottomHoleLongitude,

    xoffset,
    yoffset,

    tvd
  } = values;

  const date = effectiveDate ? formatDate(effectiveDate, 'yyyy-MM-dd') : '';

  return {
    wellId,
    body: {
      wellboreName: wellBoreName,
      wellboreType: wellBoreType || null,
      effectiveDate: date ? `${date}T00:00:00` : null,
      groundElev,
      rotaryTableElev,

      coordinateSystem,
      coordinateX,
      coordinateY,
      coordinateZ,

      bottomHoleLatitude,
      bottomHoleLongitude,

      xoffset,
      yoffset,

      tvd
    }
  };
};
