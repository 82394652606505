import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { TechOperationDetailType, TechOperationResponseType, TechOperationType } from './types';
import {
  createTechOperation, deleteAllOperation,
  deleteTechOperation,
  getOperationDetailById,
  getOperationsByWellboreId,
  updateTechOperation
} from './actions';
import { convertTechOp, convertTechops2Client } from './converters';

const { name } = options;

type FishState = {
    isLoading: boolean
    operations: TechOperationType[],
    operationDetail: TechOperationDetailType | null,
    error: string
};

const initialState: FishState = {
  isLoading: false,
  operations: [],
  operationDetail: null,
  error: ''
};

export const techOperationsSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearOperationsList(state) {
      if (state.operations.length) {
        state.operations = [];
      }
    }
  },
  extraReducers: {
    [getOperationsByWellboreId.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getOperationsByWellboreId.fulfilled.type]: (state, action: PayloadAction<TechOperationResponseType[]>) => {
      state.isLoading = false;
      state.operations = convertTechops2Client(action.payload);
      state.error = '';
    },
    [getOperationsByWellboreId.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getOperationDetailById.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getOperationDetailById.fulfilled.type]: (state, action: PayloadAction<TechOperationDetailType>) => {
      state.isLoading = false;
      state.operationDetail = action.payload;
      if (state.operationDetail.incidentsInfo) {
        state.operationDetail.incidentsInfo = state.operationDetail.incidentsInfo.map(inc => ({
          ...inc,
          stratUnitDescriptionDto: action.payload.stratUnits?.map(strat => ({ ...strat })) || []
        }));
      }
      state.error = '';
    },
    [getOperationDetailById.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [createTechOperation.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createTechOperation.fulfilled.type]: (state, action: PayloadAction<TechOperationResponseType>) => {
      state.isLoading = false;
      state.error = '';
    },
    [createTechOperation.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [updateTechOperation.pending.type]: (state) => {
      state.isLoading = true;
    },
    [updateTechOperation.fulfilled.type]: (state, { payload }: PayloadAction<TechOperationResponseType>) => {
      const convertedPayload = convertTechOp(payload);

      state.isLoading = false;
      state.operations = state.operations.map(item => (item.id === convertedPayload.id ? convertedPayload : item));
      state.error = '';
    },
    [updateTechOperation.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [deleteTechOperation.pending.type]: (state) => {
      state.isLoading = true;
    },
    [deleteTechOperation.fulfilled.type]: (state, { payload }: PayloadAction<{ techOperationId: number }>) => {
      state.isLoading = false;
      state.operations = state.operations.filter(item => item.id !== payload.techOperationId);
      state.error = '';
    },
    [deleteTechOperation.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [deleteAllOperation.pending.type]: (state) => {
      state.isLoading = true;
    },
    [deleteAllOperation.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = '';
    },
    [deleteAllOperation.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export default techOperationsSlice.reducer;
