import request from 'utils/http/request';
import { NotificationFilter, NotificationType } from './types';
import { getUrl } from '../utils';
import options from './options';
import { IPagingSearch } from '../../interfaces';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getNotificationsListApi = async (page: number, size: number, filter: NotificationFilter): Promise<IPagingSearch<NotificationType>> => {
  return request.get(url(`notice-bell?page=${page}&size=${size}&filter=${filter}`));
};

export const postNotificationAsRead = async (uid: string): Promise<IPagingSearch<NotificationType>> => {
  return request.post(url(`notice-bell/${uid}/read`));
};
