export const PROJ_PARAM_NAME: string = 'tab';

// Техоперации
export const TechOperationTypes = [
  { id: 'DRILLING', label: 'бурение' }
];

// todo список техопераций в компоновке колонны - изменить
export const operationTypes = [
  {
    uid: '1',
    label: 'Бурение'
  }
];

export enum ProjTabs {
    project = 'project',
    construction = 'construction',
    trajectory = 'trajectory',
    stratigraphy = 'stratigraphy',
    incidents = 'incidents',
    bha = 'bha',
    fluid = 'fluid',
    techOperations = 'techops',
    ggd = 'ggd',
    report = 'report'
}

// bha editor
export interface ITrunkData {
    uid: string
    type: string
    code: string
    next: string | null
    prev: string | null
    y2: number
    h: number
    d: number
}
