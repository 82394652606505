import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import {
  closeIncidentApi,
  createFactIncidentApi,
  createPlanIncidentApi,
  deleteAllPlanIncidentsApi,
  deleteIncidentApi,
  editPlanIncidentApi,
  getCriticalityTypeApi,
  getIncidentByIntervalApi,
  getIncidentByWellIdApi,
  getIncidentResultApi,
  getIncidentSetApi,
  getIncidentSubstanceApi,
  getIncidentTypeApi,
  getResponseTypeApi,
  getUnitOfMeasureApi,
  liquidateIncidentApi
} from './api';
import { RootStateType } from '../../store';
import {
  ILiquidatedValues,
  IMainValues,
  INotLiquidatedValues
} from '../../pages/monitoring/pages/well/forms/well-supervising/modal/helper';
import { IncidentLiqForm } from '../../pages/monitoring/pages/well/forms/well-supervising/modal/LiqIncidentModal/helper';
import {
  EditPlanIncident, Effective, IncidentDetail, IncidentInterval, IncidentKind, IncidentResult
} from './types';
import {
  ProjIncidentAddForm
} from '../../pages/project-documentation/pages/well-doc/project-incident/ProjAddIncidentModal/helper';
import { isSectionPublishedApi } from '../report/api';
import { SectionName } from '../../pages/project-documentation/pages/well-doc/report/helper';

const { name } = options;

export const getResponseType = createAsyncThunk(
  `${name}/getResponseType`,
  async (_, thunkApi) => {
    try {
      return await getResponseTypeApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getIncidentSet = createAsyncThunk(
  `${name}/getIncidentSet`,
  async (_, thunkApi) => {
    try {
      return await getIncidentSetApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getIncidentType = createAsyncThunk(
  `${name}/getIncidentType`,
  async (_, thunkApi) => {
    try {
      return await getIncidentTypeApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getIncidentSubstance = createAsyncThunk(
  `${name}/getIncidentSubstance`,
  async (_, thunkApi) => {
    try {
      return await getIncidentSubstanceApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getCriticalityType = createAsyncThunk(
  `${name}/getCriticalityType`,
  async (_, thunkApi) => {
    try {
      return await getCriticalityTypeApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getIncidentResult = createAsyncThunk(
  `${name}/getIncidentResult`,
  async (_, thunkApi) => {
    try {
      return await getIncidentResultApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

type IncidentResponseCreate = {
    uniqueWellIdentifier: string;
    supervisor: string;
    wellName: string;
    incidentKind: IncidentKind;
    hseIncidentResponseCreateDtoList: {
        responseType: string;
        responseResult: string;
    }[]
};

export const createFactIncident = createAsyncThunk(
  `${name}/createFactIncident`,
  async (incident: ILiquidatedValues | INotLiquidatedValues, thunkApi) => {
    try {
      const results: IncidentResult[] = (thunkApi.getState() as any).models.incident.incidentResults;
      const wellId: string = (thunkApi.getState() as RootStateType).models.well.well.uid;
      const { wellName } = (thunkApi.getState() as RootStateType).models.well.well;
      const userName = (thunkApi.getState() as RootStateType).models.auth.user?.userName;

      if (
        results.length === 0
                || !wellId
                || !userName
      ) {
        return thunkApi.rejectWithValue('Что-то пошло не так! :(');
      }

      if ('responseType' in incident) {
        // INotLiquidatedValues
        const finalIncidentBody: Omit<IMainValues, 'liquidated'> & IncidentResponseCreate = {
          incidentDate: incident.incidentDate,
          incidentSetId: incident.incidentSetId,
          incidentTypeId: incident.incidentTypeId,
          measuredDepth: incident.measuredDepth,
          verticalDepth: incident.verticalDepth,
          // criticalityType: incident.criticalityType,
          uniqueWellIdentifier: wellId,
          supervisor: userName,
          incidentKind: IncidentKind.FACT,
          hseIncidentResponseCreateDtoList: [{
            responseType: incident.responseType,
            responseResult: results[0].response_result || ''
          }],
          wellName
        };

        return await createFactIncidentApi(finalIncidentBody);
      } else {
        // ILiquidatedValues
        const finalIncidentBody: any = {
          ...incident,
          incidentKind: IncidentKind.FACT,
          hseIncidentResponseCreateDtoList: [...incident.hseIncidentResponseCreateDtoList]
        };
        finalIncidentBody.uniqueWellIdentifier = wellId;
        finalIncidentBody.supervisor = userName;
        finalIncidentBody.hseIncidentResponseCreateDtoList.forEach((hse: any) => {
          hse.responseResult = hse.responseResult ? Effective.YES : Effective.NO;
        });
        finalIncidentBody.wellName = wellName;

        return await createFactIncidentApi(finalIncidentBody);
      }
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getIncidentByWellId = createAsyncThunk(
  `${name}/getIncidentByWellId`,
  async (wellId: string, thunkApi) => {
    try {
      return await getIncidentByWellIdApi(wellId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createPlanIncident = createAsyncThunk(
  `${name}/createPlanIncident`,
  async (incident: ProjIncidentAddForm, thunkApi) => {
    try {
      const wellId: string = (thunkApi.getState() as RootStateType).models.well.well.uid;
      const { wellName } = (thunkApi.getState() as RootStateType).models.well.well;
      const userName = (thunkApi.getState() as RootStateType).models.auth.user?.userName;

      if (
        !wellId
                || !userName
      ) {
        return thunkApi.rejectWithValue('Что-то пошло не так! :(');
      }

      incident.uniqueWellIdentifier = wellId;
      incident.supervisor = userName;
      incident.wellName = wellName;

      await createPlanIncidentApi(incident);
      thunkApi.dispatch(getIncidentByWellId(wellId));
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getIncidentByInterval = createAsyncThunk(
  `${name}/getIncidentByInterval`,
  async (body: IncidentInterval, thunkApi) => {
    try {
      return await getIncidentByIntervalApi(body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteIncident = createAsyncThunk(
  `${name}/deleteIncident`,
  async (incidentId: string, thunkApi) => {
    try {
      const wellId: string = (thunkApi.getState() as RootStateType).models.well.well.uid;
      const isPublished = await isSectionPublishedApi({
        wellId,
        wellProjectReportChapter: SectionName.INCIDENT
      });
      if (isPublished) {
        return thunkApi.rejectWithValue('Страница устарела. Обновите пожалуйста');
      }

      return await deleteIncidentApi(incidentId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteAllPlanIncidents = createAsyncThunk(
  `${name}/deleteAllPlanIncidents`,
  async (wellId: string, thunkApi) => {
    try {
      const isPublished = await isSectionPublishedApi({
        wellId,
        wellProjectReportChapter: SectionName.INCIDENT
      });
      if (isPublished) {
        return thunkApi.rejectWithValue('Страница устарела. Обновите пожалуйста');
      }

      return await deleteAllPlanIncidentsApi(wellId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const liquidateIncident = createAsyncThunk(
  `${name}/liquidateIncident`,
  async (liquidationData: IncidentLiqForm, thunkApi) => {
    try {
      const result: any = { ...liquidationData };
      result.hseIncidentResponseList.forEach((res: any) => {
        res.responseResult = res.responseResult ? Effective.YES : Effective.NO;
      });

      const incident = await liquidateIncidentApi(result);
      incident.uwi && thunkApi.dispatch(getIncidentByWellId(incident.uwi));
      return incident;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const editPlanIncident = createAsyncThunk(
  `${name}/editPlanIncident`,
  async (incidentData: EditPlanIncident, thunkApi) => {
    try {
      const wellId: string = (thunkApi.getState() as RootStateType).models.well.well.uid
                || incidentData.uniqueWellIdentifier;
      const userName = (thunkApi.getState() as RootStateType).models.auth.user?.userName;
      const isPublished = await isSectionPublishedApi({
        wellId,
        wellProjectReportChapter: SectionName.INCIDENT
      });

      if (isPublished) {
        return thunkApi.rejectWithValue('Страница устарела. Обновите пожалуйста');
      }
      if (!wellId || !userName) {
        return thunkApi.rejectWithValue('Что-то пошло не так! :(');
      }

      const requestData: any = { ...incidentData };

      const detail: Partial<IncidentDetail>[] = [{
        incidentTypeId: incidentData.incidentTypeId,
        incidentSetId: incidentData.incidentSetId,
        incidentId: incidentData.incidentId
      }];

      requestData.uniqueWellIdentifier = wellId;
      requestData.supervisor = userName;

      requestData.incidentDetail = detail;
      requestData.hseIncidentResponseList = requestData.hseIncidentResponseCreateDtoList;

      return await editPlanIncidentApi(requestData);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getUnitOfMeasure = createAsyncThunk(
  `${name}/getUnitOfMeasure`,
  async (_, thunkApi) => {
    try {
      return await getUnitOfMeasureApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);