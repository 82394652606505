import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import {
  BhaEquipmentType,
  DrillingMudNoAssembly, EquipmentFrontType, EquipmentGradeType, EquipmentMaterialType,
  EquipmentType,
  EquipmentTypeType,
  IDrillingMudResponseType, MaterialInfoType,
  TubularAssemblyType
} from './types';
import {
  createAssemblyByTubularId,
  createDrillingMud,
  createDrillingMudWithCopy,
  createEquipment, createEquipmentType,
  createNewTypeAndCatEquipment,
  deleteAllDrillingMudsById,
  deleteAssembliesByTubularId,
  deleteAssemblyById,
  deleteAssemblyDetailById,
  deleteDrillingMudById, deleteEquipment,
  duplicateAssemblyByTubularId,
  editEquipment,
  getAssembliesByTubularId,
  getAssemblyById,
  getBhaEquipmentList,
  getDrillingMudsByTubularId,
  getEquipmentList, getEquipmentMaterial,
  getEquipmentTypeList, getGradeByMaterialId, getGradeMaterialInfo,
  getRigEquipmentList,
  updateAssemblyById,
  updateAssemblyDetailById,
  updateDrillingMudByTubularId
} from './actions';
import {
  addAssemblyName, convertDownholeEquipment,
  convertEqToFront,
  convertEquipmentToBha,
  sortEquipmentsByIncrementalDepth
} from './converters';

const { name } = options;

type EquipmentState = {
  isLoading: boolean;
  typesLoading: boolean;
  assembliesLoading: boolean;
  equipmentTypeList: EquipmentTypeType[];
  equipmentsList: Record<string, string>[];
  assemblyList: TubularAssemblyType[];
  drillingMudList: IDrillingMudResponseType[];
  downholeEquipmentList: Record<string, string>[];
  downholeEquipmentLoading: boolean;
  error: string;
  bhaEquipmentList: BhaEquipmentType[];
  rigEquipmentList: EquipmentFrontType[];
  materialList: EquipmentMaterialType[];
  gradeList: EquipmentGradeType[];
  materialLoading: boolean;
};

const initialState: EquipmentState = {
  isLoading: false,
  typesLoading: false,
  assembliesLoading: false,
  equipmentTypeList: [],
  equipmentsList: [],
  assemblyList: [],
  drillingMudList: [],
  downholeEquipmentList: [],
  error: '',
  bhaEquipmentList: [],
  rigEquipmentList: [],
  downholeEquipmentLoading: false,
  materialList: [],
  gradeList: [],
  materialLoading: false
};

export const equipmentSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearEquipments(state) {
      state.equipmentsList = [];
      state.downholeEquipmentList = [];
    },
    clearEquipmentTypes(state) {
      if (state.equipmentTypeList.length) {
        state.equipmentTypeList = [];
      }
    },
    clearAssemblies(state) {
      if (state.assemblyList.length) {
        state.assemblyList = [];
      }
    },
    clearDrillingMuds(state) {
      if (state.drillingMudList.length) {
        state.drillingMudList = [];
      }
    }
  },
  extraReducers: {
    [getEquipmentList.pending.type]: (state) => {
      state.isLoading = true;
      state.downholeEquipmentLoading = true;
    },
    [getEquipmentList.fulfilled.type]: (state, { payload }: PayloadAction<EquipmentType[]>) => {
      state.downholeEquipmentList = payload.map(eq => convertDownholeEquipment(eq));
      state.isLoading = false;
      state.error = '';
      state.downholeEquipmentLoading = false;
    },
    [getEquipmentList.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.downholeEquipmentLoading = false;
    },

    [createEquipment.pending.type]: (state) => {
      state.downholeEquipmentLoading = true;
    },
    [createEquipment.fulfilled.type]: (state, { payload }: PayloadAction<EquipmentType>) => {
      state.downholeEquipmentList.push(convertDownholeEquipment(payload));
      state.error = '';
      state.downholeEquipmentLoading = false;
    },
    [createEquipment.rejected.type]: (state, { payload }: PayloadAction<string>) => {
      state.downholeEquipmentLoading = false;
      state.error = payload;
    },

    [editEquipment.pending.type]: (state) => {
      state.downholeEquipmentLoading = true;
    },
    [editEquipment.fulfilled.type]: (state, { payload }: PayloadAction<EquipmentType>) => {
      const index = state.downholeEquipmentList.findIndex(eq => eq.id === payload.id);
      if (index > -1) {
        state.downholeEquipmentList[index] = convertDownholeEquipment(payload);
      }
      state.error = '';
      state.downholeEquipmentLoading = false;
    },
    [editEquipment.rejected.type]: (state, { payload }: PayloadAction<string>) => {
      state.downholeEquipmentLoading = false;
      state.error = payload;
    },

    [deleteEquipment.pending.type]: (state) => {
      state.downholeEquipmentLoading = true;
    },
    [deleteEquipment.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.downholeEquipmentLoading = false;
      const index = state.downholeEquipmentList.findIndex(eq => eq.id === payload);
      if (index > -1) {
        state.downholeEquipmentList.splice(index, 1);
      }
      state.error = '';
    },
    [deleteEquipment.rejected.type]: (state, { payload }: PayloadAction<string>) => {
      state.downholeEquipmentLoading = false;
      state.error = payload;
    },

    [getRigEquipmentList.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getRigEquipmentList.fulfilled.type]: (state, { payload }: PayloadAction<EquipmentType[]>) => {
      state.isLoading = false;
      state.rigEquipmentList = payload.map(eq => convertEqToFront(eq));
      state.error = '';
    },
    [getRigEquipmentList.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getEquipmentTypeList.pending.type]: (state) => {
      state.typesLoading = true;
      state.equipmentTypeList = [];
    },
    [getEquipmentTypeList.fulfilled.type]: (state, { payload }: PayloadAction<EquipmentTypeType[]>) => {
      state.typesLoading = false;
      state.equipmentTypeList = payload;
      state.error = '';
    },
    [getEquipmentTypeList.rejected.type]: (state, action: PayloadAction<string>) => {
      state.typesLoading = false;
      state.error = action.payload;
    },

    [createEquipmentType.pending.type]: (state) => {
      state.typesLoading = true;
    },
    [createEquipmentType.fulfilled.type]: (state, { payload }: PayloadAction<EquipmentTypeType>) => {
      state.typesLoading = false;
      state.error = '';
      state.equipmentTypeList.push(payload);
    },
    [createEquipmentType.rejected.type]: (state, action: PayloadAction<string>) => {
      state.typesLoading = false;
      state.error = action.payload;
    },

    [getEquipmentMaterial.pending.type]: (state) => {
      state.materialLoading = true;
    },
    [getEquipmentMaterial.fulfilled.type]: (state, { payload }: PayloadAction<EquipmentMaterialType[]>) => {
      state.materialLoading = false;
      state.error = '';
      state.materialList = payload.map(el => ({ ...el, id: el.id.toString() }));
    },
    [getEquipmentMaterial.rejected.type]: (state, action: PayloadAction<string>) => {
      state.materialLoading = false;
      state.error = action.payload;
    },

    [getGradeByMaterialId.pending.type]: (state) => {
      state.materialLoading = true;
    },
    [getGradeByMaterialId.fulfilled.type]: (state, { payload }: PayloadAction<EquipmentGradeType[]>) => {
      state.materialLoading = false;
      state.error = '';
      state.gradeList = payload.map(el => ({ ...el, id: el.id.toString() }));
    },
    [getGradeByMaterialId.rejected.type]: (state, action: PayloadAction<string>) => {
      state.materialLoading = false;
      state.error = action.payload;
    },

    [getGradeMaterialInfo.pending.type]: (state) => {
      state.materialLoading = true;
    },
    [getGradeMaterialInfo.fulfilled.type]: (state, { payload }: PayloadAction<MaterialInfoType>) => {
      state.materialLoading = false;
      state.error = '';
    },
    [getGradeMaterialInfo.rejected.type]: (state, action: PayloadAction<string>) => {
      state.materialLoading = false;
      state.error = action.payload;
    },

    // сборки
    [getAssembliesByTubularId.pending.type]: (state) => {
      state.assembliesLoading = true;
      state.assemblyList = [];
    },
    [getAssembliesByTubularId.fulfilled.type]: (state, { payload }: PayloadAction<TubularAssemblyType[]>) => {
      state.assembliesLoading = false;
      state.assemblyList = sortEquipmentsByIncrementalDepth(payload);
      state.error = '';
    },
    [getAssembliesByTubularId.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.error = action.payload;
    },

    [deleteAssembliesByTubularId.pending.type]: (state) => {
      state.assembliesLoading = true;
    },
    [deleteAssembliesByTubularId.fulfilled.type]: (state) => {
      state.assembliesLoading = false;
      state.assemblyList = [];
      state.error = '';
    },
    [deleteAssembliesByTubularId.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.error = action.payload;
    },

    [getAssemblyById.pending.type]: (state) => {
      state.assembliesLoading = true;
      state.assemblyList = [];
    },
    [getAssemblyById.fulfilled.type]: (state, { payload }: PayloadAction<TubularAssemblyType>) => {
      state.assembliesLoading = false;
      state.assemblyList = sortEquipmentsByIncrementalDepth([payload]);
      state.error = '';
    },
    [getAssemblyById.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.assemblyList = [];
      state.error = action.payload;
    },

    [updateAssemblyById.pending.type]: (state) => {
      state.assembliesLoading = true;
    },
    [updateAssemblyById.fulfilled.type]: (state, { payload }: PayloadAction<TubularAssemblyType>) => {
      state.assembliesLoading = false;
      // state.assemblyList = [payload];
      state.error = '';
    },
    [updateAssemblyById.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.error = action.payload;
    },

    [createAssemblyByTubularId.pending.type]: (state) => {
      state.assembliesLoading = true;
    },
    [createAssemblyByTubularId.fulfilled.type]: (state, { payload }: PayloadAction<TubularAssemblyType>) => {
      state.assembliesLoading = false;
      // state.assemblyList.push(payload);
      state.error = '';
    },
    [createAssemblyByTubularId.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.error = action.payload;
    },

    [duplicateAssemblyByTubularId.pending.type]: (state) => {
      state.assembliesLoading = true;
    },
    [duplicateAssemblyByTubularId.fulfilled.type]: (state, { payload }: PayloadAction<TubularAssemblyType>) => {
      state.assembliesLoading = false;
      state.assemblyList.push(payload);
      state.error = '';
    },
    [duplicateAssemblyByTubularId.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.error = action.payload;
    },

    [deleteAssemblyById.pending.type]: (state) => {
      state.assembliesLoading = true;
    },
    [deleteAssemblyById.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.assemblyList = state.assemblyList.filter(assembly => assembly.id !== payload);
      state.error = '';
    },
    [deleteAssemblyById.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.error = action.payload;
    },

    [updateAssemblyDetailById.pending.type]: (state) => {
      state.assembliesLoading = true;
    },
    [updateAssemblyDetailById.fulfilled.type]: (state) => {
      state.assembliesLoading = false;
      state.error = '';
    },
    [updateAssemblyDetailById.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.error = action.payload;
    },

    // todo есть проблемы на беке, не используется
    [deleteAssemblyDetailById.pending.type]: (state) => {
      state.assembliesLoading = true;
    },
    [deleteAssemblyDetailById.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.assembliesLoading = false;
      // state.assemblyList = state.assemblyList[0].equipments.filter(assembly => assembly.id !== payload);
      state.error = '';
    },
    [deleteAssemblyDetailById.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.error = action.payload;
    },

    // буровые растворы
    [getDrillingMudsByTubularId.pending.type]: (state) => {
      state.assembliesLoading = true;
      state.drillingMudList = [];
    },
    [getDrillingMudsByTubularId.fulfilled.type]: (state, { payload }: PayloadAction<DrillingMudNoAssembly[]>) => {
      state.assembliesLoading = false;
      state.drillingMudList = payload.map(mud => addAssemblyName(state.assemblyList, mud));
      state.error = '';
    },
    [getDrillingMudsByTubularId.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.error = action.payload;
    },

    [createDrillingMud.pending.type]: (state) => {
      state.assembliesLoading = true;
    },
    [createDrillingMud.fulfilled.type]: (state, { payload }: PayloadAction<DrillingMudNoAssembly>) => {
      state.assembliesLoading = false;
      const mud = addAssemblyName(state.assemblyList, payload);
      state.drillingMudList.push(mud);
      state.error = '';
    },
    [createDrillingMud.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.error = action.payload;
    },

    [createDrillingMudWithCopy.pending.type]: (state) => {
      state.assembliesLoading = true;
    },
    [createDrillingMudWithCopy.fulfilled.type]: (state, { payload }: PayloadAction<DrillingMudNoAssembly[]>) => {
      const muds = payload.map(mud => addAssemblyName(state.assemblyList, mud));
      state.drillingMudList = state.drillingMudList.concat(muds);
      state.assembliesLoading = false;
      state.error = '';
    },
    [createDrillingMudWithCopy.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.error = action.payload;
    },

    [updateDrillingMudByTubularId.pending.type]: (state) => {
      state.assembliesLoading = true;
    },
    [updateDrillingMudByTubularId.fulfilled.type]: (state, { payload }: PayloadAction<IDrillingMudResponseType>) => {
      state.assembliesLoading = false;
      const index = state.drillingMudList.findIndex(mud => mud.id === payload.id);
      if (index > -1) {
        state.drillingMudList[index] = {
          ...state.drillingMudList[index],
          ...payload
        };
      }
      state.error = '';
    },
    [updateDrillingMudByTubularId.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.error = action.payload;
    },

    [createNewTypeAndCatEquipment.pending.type]: (state) => {
      state.assembliesLoading = true;
    },
    [createNewTypeAndCatEquipment.fulfilled.type]: (state, { payload }: PayloadAction<EquipmentTypeType>) => {
      state.assembliesLoading = false;
      state.equipmentTypeList.push(payload);
      state.error = '';
    },
    [createNewTypeAndCatEquipment.rejected.type]: (state, action : PayloadAction<string>) => {
      state.assembliesLoading = false;
    },

    [deleteDrillingMudById.pending.type]: (state) => {
      state.assembliesLoading = true;
    },
    [deleteDrillingMudById.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.drillingMudList = state.drillingMudList.filter(drillingMud => drillingMud.id !== payload);
      state.error = '';
    },
    [deleteDrillingMudById.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.error = action.payload;
    },

    [deleteAllDrillingMudsById.pending.type]: (state) => {
      state.assembliesLoading = true;
    },
    [deleteAllDrillingMudsById.fulfilled.type]: (state) => {
      state.assembliesLoading = false;
      state.drillingMudList = [];
      state.error = '';
    },
    [deleteAllDrillingMudsById.rejected.type]: (state, action: PayloadAction<string>) => {
      state.assembliesLoading = false;
      state.error = action.payload;
    },

    [getBhaEquipmentList.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getBhaEquipmentList.fulfilled.type]: (state, { payload }: PayloadAction<EquipmentType[]>) => {
      state.isLoading = false;
      state.bhaEquipmentList = payload.map(eq => convertEquipmentToBha(eq));
      state.error = '';
    },
    [getBhaEquipmentList.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export default equipmentSlice.reducer;
