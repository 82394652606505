import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { createWellProjectReport, getWellProjectReport } from './actions';
import { ProjectReportResponseType, ProjectReportType } from './types';

const { name } = options;

type ReportState = {
    error: string;
    reportsList: ProjectReportResponseType[],
    reportDetail: ProjectReportType[];
    isLoading: boolean;
};

const initialState: ReportState = {
  error: '',
  reportsList: [],
  reportDetail: [],
  isLoading: false
};

export const reportSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = '';
    },
    clearState: () => initialState
  },
  extraReducers: {
    [createWellProjectReport.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createWellProjectReport.fulfilled.type]: (state, { payload }: PayloadAction<ProjectReportResponseType>) => {
      state.isLoading = false;
      const index = state.reportsList.findIndex(report => report.wellProjectReportChapter === payload.wellProjectReportChapter);
      if (index !== -1) {
        state.reportsList[index] = payload;
      } else {
        state.reportsList.push(payload);
      }
      state.error = '';
    },
    [createWellProjectReport.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getWellProjectReport.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getWellProjectReport.fulfilled.type]: (state, { payload }: PayloadAction<ProjectReportType[]>) => {
      state.isLoading = false;
      state.reportsList = payload;
      state.error = '';
    },
    [getWellProjectReport.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export default reportSlice.reducer;