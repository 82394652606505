import {
  CreateLinkedFacilityBodyType,
  CreateLinkedFacilityFormType,
  CreateProjectSecondStepBodyType, FacilityFormBackType,
  FacilityFormType, FacilityInfoFormType, FacilitySummaryResponseType,
  FacilityType
} from './types';
import { ICreateSecondStepValues } from '../../pages/project-documentation/forms/create-modal/helper';
import { convertDecimalToDegrees, convertDegreesToDecimal, cutNumbers } from '../../utils/utils';
import { formatDate } from '../../utils/date';

export const facilityForm2Client = (facility: FacilityFormBackType): FacilityFormType => ({
  uid: `${facility.facilityType}&&${facility.facilityId}`,
  fieldId: facility.primaryFieldId,
  areaId: facility.areaId,
  areaType: facility.areaType,
  facilityName: facility.facilityShortName,
  facilityType: facility.facilityType,
  facilityId: facility.facilityId
});

export const facilityFormList2Client = (facilityList: FacilityFormBackType[]): FacilityFormType[] => facilityList.map(facility => ({
  uid: `${facility.facilityType}&&${facility.facilityId}`,
  fieldId: facility.primaryFieldId,
  areaId: facility.areaId,
  areaType: facility.areaType,
  facilityName: facility.facilityShortName,
  facilityType: facility.facilityType,
  facilityId: facility.facilityId
}));

export const facilityDetail2Client = (res: FacilityType[]) => res.map((elem) => ({
  ...elem,
  facilityName: elem.facilityName,
  uid: `${elem.facilityType || ''}&&${elem.facilityId || ''}`
}));

export const facility2StepSummary2Client = (res: FacilitySummaryResponseType): FacilityInfoFormType => {
  const {
    facilityId,
    facilityType,
    districtName,
    machineDirection,
    activeDate,
    elevation
  } = res.facility;
  let equipmentId = '';
  if (res?.facilityEquipment) {
    equipmentId = res.facilityEquipment.equipmentId;
  }

  const {
    degrees: directionDegree, minutes: directionMinute, seconds: directionSecond
  } = convertDecimalToDegrees(machineDirection);

  return {
    facilityId,
    facilityType,
    district: districtName || '',
    equipmentId,
    activeDate,
    elevation,
    directionDegree,
    directionMinute,
    directionSecond
  };
};

export const createSecondStepBody2Server = (formValues: ICreateSecondStepValues): CreateProjectSecondStepBodyType => {
  const {
    facilityId,
    facilityType,
    district,
    equipmentId,
    elevation,
    activeDate,
    directionDegree,
    directionMinute,
    directionSecond
  } = formValues;

  const date = activeDate ? formatDate(activeDate, 'yyyy-MM-dd') : '';
  const decimalDirection = convertDegreesToDecimal(directionDegree, directionMinute, directionSecond);

  return {
    equipmentPK: {
      оборудование_ид: equipmentId,
      установить_набл_номер: 0
    },
    facility: {
      куст_ид: facilityId,
      куст_тип: facilityType,
      район_имя: district || '',
      высота: Number(elevation),
      направление_движения_станка: Number(cutNumbers(decimalDirection, 5)),
      активация_дата: date ? `${date}T00:00:00` : null
    }
  };
};

export const createLinkedFacility2Server = (formValues: CreateLinkedFacilityFormType): CreateLinkedFacilityBodyType => {
  const {
    fieldId,
    areaId,
    areaType,
    facilityName,
    facilityType
  } = formValues;

  return {
    areaPk: {
      площадь_тип: areaType,
      площадь_ид: areaId
    },
    createFacilityDto: {
      первичный_месторождение_ид: fieldId,
      куст_тип: facilityType,
      куст_короткое_имя: facilityName
    }
  };
};
