import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import {
  createFasteningCalc,
  deleteFasConstruction,
  deleteFasEquipment,
  deleteFasFluid,
  getFasCentralization,
  getFasConstructions,
  getFasEquipments,
  getFasFluids,
  getFasteningCalc,
  getResearchJobData,
} from './actions';
import {
  ICementingCalc,
  IFasCentralization,
  IFasConstructionEquipment,
  IFasEquipment,
  IFasSolution,
  IJobData
} from './types';
import options from './options';

const { name } = options;

interface IFasteningStore {
  error?: string;
  isLoading: boolean;
  cementing?: ICementingCalc;
  fluids: IFasSolution[];
  jobData: IJobData[];
  equipments: IFasEquipment[];
  constructions: IFasConstructionEquipment[];
  centralization: IFasCentralization[];
}

const initialState: IFasteningStore = {
  error: undefined,
  isLoading: false,
  cementing: undefined,
  centralization: [],
  constructions: [],
  equipments: [],
  fluids: [],
  jobData: []
};

export const fasteningSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: {
    // research cementing

    [createFasteningCalc.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [createFasteningCalc.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<ICementingCalc>
    ) => {
      state.cementing = action.payload;
      state.isLoading = false;
    },
    [createFasteningCalc.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },

    // get research cementing

    [getFasteningCalc.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [getFasteningCalc.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<ICementingCalc>
    ) => {
      state.cementing = action.payload;
      state.isLoading = false;
    },
    [getFasteningCalc.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },

    // get construction equipments

    [getFasConstructions.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [getFasConstructions.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<IFasConstructionEquipment[]>
    ) => {
      state.constructions = action.payload;
      state.isLoading = false;
    },
    [getFasConstructions.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },

    // get equipments

    [getFasEquipments.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [getFasEquipments.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<IFasEquipment[]>
    ) => {
      state.equipments = action.payload;
      state.isLoading = false;
    },
    [getFasEquipments.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },

    // get fluids

    [getFasFluids.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [getFasFluids.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<IFasSolution[]>
    ) => {
      state.fluids = action.payload;
      state.isLoading = false;
    },
    [getFasFluids.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },

    // get researchJobData

    [getResearchJobData.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [getResearchJobData.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<IJobData[]>
    ) => {
      state.jobData = action.payload;
      state.isLoading = false;
    },
    [getResearchJobData.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },
    // get centralization

    [getFasCentralization.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [getFasCentralization.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<IFasCentralization[]>
    ) => {
      state.centralization = action.payload;
      state.isLoading = false;
    },
    [getFasCentralization.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },
    // delete construction

    [deleteFasConstruction.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [deleteFasConstruction.fulfilled.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      const index = state.constructions.findIndex((eq) => eq.item_uuid === payload);
      if (index > -1) {
        state.constructions.splice(index, 1);
      }
      state.isLoading = false;
    },
    [deleteFasConstruction.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
      state.isLoading = false;
    },

    // delete equipment

    [deleteFasEquipment.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [deleteFasEquipment.fulfilled.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      const index = state.equipments.findIndex((eq) => eq.item_uuid === payload);
      if (index > -1) {
        state.equipments.splice(index, 1);
      }
      state.isLoading = false;
    },
    [deleteFasEquipment.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
      state.isLoading = false;
    },

    // delete fluid

    [deleteFasFluid.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [deleteFasFluid.fulfilled.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      const index = state.fluids.findIndex((eq) => eq.item_uuid === payload);
      if (index > -1) {
        state.fluids.splice(index, 1);
      }
      state.isLoading = false;
    },
    [deleteFasFluid.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
      state.isLoading = false;
    }
  }
});

export default fasteningSlice.reducer;
