import request from 'utils/http/request';
import {
  CreateProjectFirstStepBodyType,
  FieldCreateType,
  FieldFacilitiesDetailType,
  FieldInfoResponseType,
  FieldType
} from './types';
import { getUrl } from '../utils';
import options from './options';
import { WellStatus } from '../well/types';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getFieldsDetailApi = async (status: WellStatus[]): Promise<FieldType[]> => request.post(url('fieldDetail'), { wellStatusType: status });

export const getFieldFacilitiesDetailApi = async (uid: string, status: string[]): Promise<FieldFacilitiesDetailType> => request.post(url('fieldFacilityDetail'), { fieldId: uid, wellStatusType: status });

export const getFieldsApi = (uid?: number | string): Promise<any[]> => (!uid ? request.get(url('')) : request.get(url(uid)));

export const createFieldApi = (field: FieldCreateType) => request.post(url(''), field);

export const createOrUpdateProjectFirstStepApi = (body: CreateProjectFirstStepBodyType) => request.post('api/field_info', body);

export const getFieldInfoByFieldIdApi = (fieldId: string): Promise<FieldInfoResponseType> => request.get(`api/field_info/${fieldId}`);

export const checkExistingFieldByNameApi = (fieldName: string): Promise<boolean> => request.post(url(`verify?fieldName=${fieldName}`));

export const postDraftFieldsApi = async (body: { value: string }) => request.post('api/project-doc/draft', body);

export const getDraftFieldsApi = async (): Promise<{ value: string }> => request.get('api/project-doc/draft');

export const deleteDraftFieldsApi = async (): Promise<any> => request.delete('/api/project-doc/draft');
