import { createAsyncThunk } from '@reduxjs/toolkit';
import * as techOpsApi from './api';
import options from './options';
import { TechOperationCreateFormType } from './types';
import { convertCreateTechOpsBody2Server } from './converters';

const { name } = options;

export const getOperationsByWellboreId = createAsyncThunk(
  `${name}/getOperationsByWellId`,
  async ({ wellboreId, tubularId }: { wellboreId: string, tubularId: number }, thunkApi) => {
    try {
      return await techOpsApi.getOperationsByWellboreIdApi(wellboreId, tubularId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getOperationDetailById = createAsyncThunk(
  `${name}/getOperationDetailById`,
  async (id: number, thunkApi) => {
    try {
      return await techOpsApi.getOperationDetailByIdApi(id);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createTechOperation = createAsyncThunk(
  `${name}/createTechOperation`,
  async (body: TechOperationCreateFormType, thunkApi) => {
    try {
      return await techOpsApi.createTechOperationApi(convertCreateTechOpsBody2Server(body));
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const updateTechOperation = createAsyncThunk(
  `${name}/updateTechOperation`,
  async ({ id, body }: { id: number, body: TechOperationCreateFormType }, thunkApi) => {
    try {
      return await techOpsApi.updateTechOperationApi({ ...convertCreateTechOpsBody2Server(body), techOperationId: id });
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteTechOperation = createAsyncThunk(
  `${name}/deleteTechOperation`,
  async (id: number, thunkApi) => {
    try {
      return await techOpsApi.deleteTechOperationApi(id);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteAllOperation = createAsyncThunk(
  `${name}/deleteAllOperation`,
  async (body: { wellboreId: string, tubularId?: number }, thunkApi) => {
    try {
      return await techOpsApi.deleteAllOperationApi(body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
