import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../models/auth/slice';
import adminReducer from '../models/admin/slice';
import facilityReducer from '../models/facility/slice';
import fieldReducer from '../models/field/slice';
import wellReducer from '../models/well/slice';
import wellLogReducer from '../models/well-log/slice';
import areaReducer from '../models/area/slice';
import breadcrumbReducer from '../models/breadcrumb/slice';
import trajectoryReducer from '../models/trajectory/slice';
import stratReducer from '../models/stratigraphy/slice';
import dictsReducer from '../models/dicts/slice';
import constructionReducer from '../models/construction/slice';
import equipmentReducer from '../models/equipment/slice';
import incidentReducer from '../models/incident/slice';
import calculationsReducer from '../models/calculations/slice';
import notificationReducer from '../models/notification/slice';
import hydraulicsReducer from '../models/hydraulics/slice';
import fasteningReducer from '../models/fastening/slice';
import techOperationsReducer from '../models/techops/slice';
import reportsReducer from '../models/report/slice';
import geoModelReducers from '../models/ggd/slice';
import documentationModelReducer from '../models/documentation/slice';
import schedulerReducer from '../models/scheduler/slice';
import tensionReducer from '../models/tension/slice';
import viewReducers from './view';
import eventsReducer from './events';

const modelReducers = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  field: fieldReducer,
  facility: facilityReducer,
  well: wellReducer,
  wellLog: wellLogReducer,
  area: areaReducer,
  breadcrumb: breadcrumbReducer,
  trajectory: trajectoryReducer,
  strat: stratReducer,
  dicts: dictsReducer,
  construction: constructionReducer,
  equipment: equipmentReducer,
  incident: incidentReducer,
  calculations: calculationsReducer,
  notification: notificationReducer,
  hydraulics: hydraulicsReducer,
  fastening: fasteningReducer,
  techOperations: techOperationsReducer,
  reports: reportsReducer,
  scheduler: schedulerReducer,
  geoModel: geoModelReducers,
  documentationModel: documentationModelReducer,
  tension: tensionReducer
});

const rootReducer = combineReducers({
  models: modelReducers,
  view: viewReducers,
  events: eventsReducer
});

export default rootReducer;
