import { createAsyncThunk } from '@reduxjs/toolkit';
import { getResponseErrorMessage } from 'utils/http/utils';
import * as adminApi from './api';
import options from './options';

const { name } = options;

export const register = createAsyncThunk(
  `${name}/register`,
  async (body: any, thunkApi) => {
    try {
      const newBody = body.patronymic ? {
        name: body.userName,
        password: body.password,
        patronymic: body.patronymic,
        subdivision: '',
        surname: body.surname,
        username: body.login
      } : {
        name: body.userName,
        password: body.password,
        // patronymic: body.patronymic,
        subdivision: '',
        surname: body.surname,
        username: body.login
      };
      await adminApi.createUserApi(newBody);
      return newBody.username;
    } catch (e: any) {
      return thunkApi.rejectWithValue(getResponseErrorMessage(e));
    }
  }
);
