import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';

import { IStratUnit, IStratUnitReference, lithologyDictType } from './types';
import {
  createStratUnit,
  createStratUnitReference,
  deleteStratUnit,
  deleteStratUnitReferenceByUid, getLithologyTypes,
  getStratUnitReferenceByUid,
  getStratUnitReferences, getStratUnitReferenceTypes,
  getStratUnitsByWellUid,
  patchStratUnit, patchStratUnitReferenceByUid
} from './actions';
import { IPagingSearch } from '../../interfaces';

const { name } = options;

type StratState = {
    isLoading: boolean
    error: string
    stratUnits: IPagingSearch<IStratUnit> | null
    stratUnitReferences : IStratUnitReference[]
    stratUnitReference: IStratUnitReference
    isStratUnitReferenceFormShown: boolean
    stratUnitReferenceTypes: string[]
    lithologyData: lithologyDictType[]
};

const initialState: StratState = {
  isLoading: false,
  error: '',
  stratUnits: null,
  stratUnitReferences: [],
  stratUnitReference: {
    uid: '',
    unitType: '',
    formationCode: '',
    longName: ''
  },
  isStratUnitReferenceFormShown: false,
  stratUnitReferenceTypes: [],
  lithologyData: []
};

export const stratSlice = createSlice({
  name,
  initialState,
  reducers: {
    fillStratUnits(state) {
      // if (state) return;
      state.stratUnits?.content.forEach((item: IStratUnit) => {
        // @ts-ignore
        const stratUnitReference = state.stratUnitReferences.find((stratUnitReference) => (
          stratUnitReference.uid === item.referenceStratUnitUid
        ));
        if (stratUnitReference) {
          item.referenceStratUnitCode = stratUnitReference.formationCode;
          item.referenceStratUnitLongName = stratUnitReference.longName;
        }
      });
    },
    clearStratUnits(state) {
      state.stratUnits = null;
    },
    clearStratUnitReferences(state) {
      state.stratUnitReferences = [];
    },
    clearStratUnitReferenceTypes(state) {
      state.stratUnitReferenceTypes = [];
    },
    clearStratUnitReference(state) {
      state.stratUnitReference = initialState.stratUnitReference;
    },
    isStratUnitReferenceFormShownOn(state) {
      state.isStratUnitReferenceFormShown = true;
    },
    isStratUnitReferenceFormShownOff(state) {
      state.isStratUnitReferenceFormShown = false;
    },
    clearLithologyTypes(state) {
      state.lithologyData = [];
    }
  },
  extraReducers: {
    [getStratUnitsByWellUid.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.stratUnits = action.payload;
      state.error = '';
    },
    [getStratUnitsByWellUid.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getStratUnitsByWellUid.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [deleteStratUnit.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = '';
    },
    [deleteStratUnit.pending.type]: (state) => {
      state.isLoading = true;
    },
    [deleteStratUnit.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [createStratUnit.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = '';
    },
    [createStratUnit.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createStratUnit.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [patchStratUnit.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = '';
    },
    [patchStratUnit.pending.type]: (state) => {
      state.isLoading = true;
    },
    [patchStratUnit.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getStratUnitReferences.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.stratUnitReferences = action.payload;
      state.error = '';
    },
    [getStratUnitReferences.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getStratUnitReferences.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getStratUnitReferenceByUid.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.stratUnitReference = action.payload;
      state.error = '';
    },
    [getStratUnitReferenceByUid.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getStratUnitReferenceByUid.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [createStratUnitReference.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = '';
    },
    [createStratUnitReference.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createStratUnitReference.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [deleteStratUnitReferenceByUid.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = '';
    },
    [deleteStratUnitReferenceByUid.pending.type]: (state) => {
      state.isLoading = true;
    },
    [deleteStratUnitReferenceByUid.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [patchStratUnitReferenceByUid.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = '';
    },
    [patchStratUnitReferenceByUid.pending.type]: (state) => {
      state.isLoading = true;
    },
    [patchStratUnitReferenceByUid.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getStratUnitReferenceTypes.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getStratUnitReferenceTypes.fulfilled.type]: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = '';
      state.stratUnitReferenceTypes = payload;
    },
    [getStratUnitReferenceTypes.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getLithologyTypes.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getLithologyTypes.fulfilled.type]: (state, { payload }: PayloadAction<lithologyDictType[]>) => {
      state.isLoading = false;
      state.error = '';
      state.lithologyData = payload;
    },
    [getLithologyTypes.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export default stratSlice.reducer;
