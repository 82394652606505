import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import options from './options';
import {
  DraftFormType,
  FieldInfoResponseType, FieldInfoType, FieldsFormType, FieldType
} from './types';
import {
  getFieldsDetail,
  getFieldsForm,
  getFieldFacilitiesDetail,
  createField,
  getFieldInfoByFieldId,
  createOrUpdateProjectFirstStep, checkExistingFieldByName,
  postDraftFields,
  getDraftFields,
  deleteDraftFields
} from './actions';
import { fieldInfo2Client, fieldsDetail2Client, fieldsList2Client } from './converters';

const { name } = options;

type FieldState = {
    isLoading: boolean
    fields: FieldType[],
    error: string,
    fieldsForm: FieldsFormType[],
    fieldInfo: FieldInfoType | null,
    draftForm: DraftFormType | null
};

const initialState: FieldState = {
  isLoading: false,
  fields: [],
  error: '',
  fieldsForm: [],
  fieldInfo: null,
  draftForm: null
};

export const fieldSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearFieldsForm(state) {
      state.fieldsForm = [];
    },
    clearFieldsDetail(state) {
      state.fields = [];
    },
    clearFieldInfo(state) {
      state.fieldInfo = null;
    },
    changeWellRiskFacilityCard(state, { payload: incidentId }: PayloadAction<string>) {
      if (state.fields[0]) {
        state.fields[0].facilities.forEach(facility => {
          if (facility.wellRisk > 0 && facility.wellsList) {
            facility.wellsList.forEach(well => {
              if (well.incidentInfo) {
                const index = well.incidentInfo.findIndex(incident => incident.id === incidentId);
                if (index > -1) {
                  well.incidentInfo.splice(index, 1);
                  facility.wellRisk -= 1;
                }
              }
            });
          }
        });
      }
    }
  },
  extraReducers: {
    [getFieldsDetail.fulfilled.type]: (state, { payload }: PayloadAction<FieldType[]>) => {
      state.isLoading = false;
      state.fields = fieldsDetail2Client(payload);
      state.error = '';
    },
    [getFieldsDetail.pending.type]: (state) => {
      state.isLoading = true;
      state.fields = [];
    },
    [getFieldsDetail.rejected.type]: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.fields = [];
      state.error = payload;
    },
    [getFieldsForm.pending.type]: (state: FieldState) => {
      state.isLoading = true;
    },
    [getFieldsForm.fulfilled.type]: (state: FieldState, { payload }: PayloadAction<[]>) => {
      state.isLoading = false;
      state.fieldsForm = fieldsList2Client(payload);
    },
    [getFieldsForm.rejected.type]: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = payload;
    },
    [getFieldFacilitiesDetail.pending.type]: (state: FieldState) => {
      state.isLoading = true;
    },
    [getFieldFacilitiesDetail.fulfilled.type]: (state: FieldState, { payload }: PayloadAction<FieldType>) => {
      state.isLoading = false;
      state.fields = fieldsDetail2Client([payload]);
    },
    [getFieldFacilitiesDetail.rejected.type]: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.fields = [];
      state.error = payload;
    },
    [createField.pending.type]: (state: FieldState) => {
      state.isLoading = true;
    },
    [createField.fulfilled.type]: (state: FieldState, { payload }: PayloadAction<{ fieldsList: [], newField: {} }>) => {
      state.isLoading = false;
      state.fieldsForm = fieldsList2Client(payload.fieldsList);
      state.error = '';
    },
    [createField.rejected.type]: (state: FieldState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [createOrUpdateProjectFirstStep.pending.type]: (state: FieldState) => {
      state.isLoading = true;
    },
    [createOrUpdateProjectFirstStep.fulfilled.type]: (state: FieldState) => {
      state.isLoading = false;
      state.error = '';
    },
    [createOrUpdateProjectFirstStep.rejected.type]: (state: FieldState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getFieldInfoByFieldId.pending.type]: (state: FieldState) => {
      state.isLoading = true;
    },
    [getFieldInfoByFieldId.fulfilled.type]: (state: FieldState, { payload }: PayloadAction<FieldInfoResponseType>) => {
      state.isLoading = false;
      state.fieldInfo = payload ? fieldInfo2Client(payload): null;
      state.error = '';
    },
    [getFieldInfoByFieldId.rejected.type]: (state: FieldState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [checkExistingFieldByName.pending.type]: (state: FieldState) => {
      state.isLoading = true;
    },
    [checkExistingFieldByName.fulfilled.type]: (state: FieldState) => {
      state.isLoading = false;
      state.error = '';
    },
    [checkExistingFieldByName.rejected.type]: (state: FieldState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [postDraftFields.pending.type]: (state: FieldState) => {
      state.isLoading = true;
    },
    [postDraftFields.fulfilled.type]: (state:FieldState) => {
      state.isLoading = false;
      state.error = '';
    },
    [postDraftFields.rejected.type]: (state: FieldState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getDraftFields.pending.type]: (state: FieldState) => {
      state.isLoading = true;
    },
    [getDraftFields.fulfilled.type]: (state:FieldState, { payload }: PayloadAction<{ value: string }>) => {
      state.isLoading = false;
      state.draftForm = payload.value ? JSON.parse(payload.value) : null;
      state.error = '';
    },
    [getDraftFields.rejected.type]: (state: FieldState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [deleteDraftFields.pending.type]: (state: FieldState) => {
      state.isLoading = true;
    },
    [deleteDraftFields.fulfilled.type]: (state:FieldState) => {
      state.isLoading = false;
      state.draftForm = null;
    },
    [deleteDraftFields.rejected.type]: (state: FieldState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = payload;
    }
  }
});

export default fieldSlice.reducer;
