import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportType } from 'models/trajectory/types';
import { saveAs } from 'file-saver';
import options from './options';
import {
  createHydraulicCalc,
  createHydEquipment,
  getEcdCalc,
  getHydEquipments,
  getHydEquipmentType,
  getHydraulicCalc,
  getHydraulicHandbook,
  updateEcdCalc,
  deleteHydEquipment,
  calculateEcd,
  updateHydEquipment,
  reorderHydEquipments,
  getBitCalc,
  updateBitCalc,
  calculateBit,
  calculateCut,
  updateCutCalc,
  getCutCalc,
  getPressureLoss,
  updatePressureLoss,
  calculatePressure,
  downloadReport
} from './actions';
import {
  ResearchEcpBackType,
  EcpCalcType,
  HydraulicCalcType,
  HandbookEquipmentType,
  HydEquipmentCodeType,
  HydEquipmentBackType,
  HydEquipmentType,
  CalculationEcdBackType,
  ResearchBitBackType,
  BitCalcType,
  CalculationBitBackType,
  CalculationCutBackType,
  CutCalcType,
  ResearchCutBackType,
  PressureCalcType,
  ResearchPressureBackType,
  CalculationPressureBackType
} from './types';
import {
  bitCalcBackToFront,
  calculationBitBackToFront,
  calculationCutBackToFront,
  calculationEcpBackToFront,
  calculationPressBackToFront,
  ecpCalcBackToFront,
  hydEquipmentToFront,
  pressureBackToFront, researchCutBackToFront
} from './convertors';

const { name } = options;

type HydraulicsState = {
    isLoading: boolean;
    error: string;
    graphLoading: boolean;
    equipmentType: HydEquipmentCodeType[],
    // TODO: retype
    handbookEquipments: HandbookEquipmentType[],
    allPageLoading: boolean;
    hydraulicCalc: null | HydraulicCalcType;
    ecpCalc: null | EcpCalcType;
    bitCalc: null | BitCalcType;
    cutCalc: null | CutCalcType;
    pressureCalc: null | PressureCalcType;
    equipments: HydEquipmentType[];
    reportLoading: boolean
};

const initialState: HydraulicsState = {
  isLoading: false,
  error: '',
  graphLoading: false,
  equipmentType: [],
  handbookEquipments: [],
  allPageLoading: false,
  hydraulicCalc: null,
  ecpCalc: null,
  bitCalc: null,
  cutCalc: null,
  pressureCalc: null,
  reportLoading: false,
  equipments: [] // нужно сортировать по bhaIndex,
};

export const hydraulicsSlice = createSlice({
  name,
  initialState,
  reducers: {
    cleanHydraulicCalc(state) {
      state.hydraulicCalc = null;
    }
  },
  extraReducers: {
    // new changes
    // hydraulic type (справочник типов)
    [getHydEquipmentType.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getHydEquipmentType.fulfilled.type]: (state, { payload }: PayloadAction<HydEquipmentCodeType[]>) => {
      state.isLoading = false;
      state.equipmentType = payload;
      state.error = '';
    },
    [getHydEquipmentType.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // handbook (справочник гидравлики)

    [getHydraulicHandbook.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getHydraulicHandbook.fulfilled.type]: (state, { payload }: PayloadAction<HandbookEquipmentType[]>) => {
      state.isLoading = false;
      state.handbookEquipments = payload;
      state.error = '';
    },
    [getHydraulicHandbook.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // hydraulic calculation

    [getHydraulicCalc.pending.type]: (state: HydraulicsState) => {
      state.allPageLoading = true;
    },
    [getHydraulicCalc.fulfilled.type]: (state: HydraulicsState, { payload }: PayloadAction<null | HydraulicCalcType>) => {
      if (payload) {
        state.allPageLoading = false;
        state.hydraulicCalc = payload;
      }
    },
    [getHydraulicCalc.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.allPageLoading = false;
      state.error = payload;
    },

    [createHydraulicCalc.pending.type]: (state: HydraulicsState) => {
      state.allPageLoading = true;
    },
    [createHydraulicCalc.fulfilled.type]: (state: HydraulicsState, action: PayloadAction<HydraulicCalcType>) => {
      state.allPageLoading = false;
      state.hydraulicCalc = action.payload;
    },
    [createHydraulicCalc.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.allPageLoading = false;
      state.error = payload;
    },

    // ecd calculation

    [getEcdCalc.pending.type]: (state: HydraulicsState) => {
      state.allPageLoading = true;
    },
    [getEcdCalc.fulfilled.type]: (state: HydraulicsState, action: PayloadAction<ResearchEcpBackType>) => {
      state.allPageLoading = false;
      state.ecpCalc = ecpCalcBackToFront(action.payload);
    },
    [getEcdCalc.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.allPageLoading = false;
      state.error = payload;
    },
    [getBitCalc.pending.type]: (state: HydraulicsState) => {
      state.allPageLoading = true;
    },
    [getBitCalc.fulfilled.type]: (state: HydraulicsState, action: PayloadAction<ResearchBitBackType>) => {
      state.allPageLoading = false;
      state.bitCalc = bitCalcBackToFront(action.payload);
    },
    [getBitCalc.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.allPageLoading = false;
      state.error = payload;
    },
    [getCutCalc.pending.type]: (state: HydraulicsState) => {
      state.allPageLoading = true;
    },
    [getCutCalc.fulfilled.type]: (state: HydraulicsState, action: PayloadAction<ResearchCutBackType>) => {
      state.allPageLoading = false;
      state.cutCalc = researchCutBackToFront(action.payload, state.cutCalc);
    },
    [getCutCalc.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.allPageLoading = false;
      state.error = payload;
    },
    [getPressureLoss.pending.type]: (state: HydraulicsState) => {
      state.allPageLoading = true;
    },
    [getPressureLoss.fulfilled.type]: (state: HydraulicsState, action: PayloadAction<ResearchPressureBackType>) => {
      state.allPageLoading = false;
      state.pressureCalc = pressureBackToFront(action.payload);
    },
    [getPressureLoss.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.allPageLoading = false;
      state.error = payload;
    },
    [updateEcdCalc.pending.type]: (state: HydraulicsState) => {
    },
    [updateEcdCalc.fulfilled.type]: (state: HydraulicsState, action: PayloadAction<ResearchEcpBackType>) => {
      state.ecpCalc = ecpCalcBackToFront(action.payload);
    },
    [updateEcdCalc.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    [updateBitCalc.pending.type]: (state: HydraulicsState) => {
    },
    [updateBitCalc.fulfilled.type]: (state: HydraulicsState, action: PayloadAction<ResearchBitBackType>) => {
      state.bitCalc = bitCalcBackToFront(action.payload);
    },
    [updateBitCalc.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    [updateCutCalc.pending.type]: (state: HydraulicsState) => {
    },
    [updateCutCalc.fulfilled.type]: (state: HydraulicsState, action: PayloadAction<ResearchCutBackType>) => {
      state.cutCalc = researchCutBackToFront(action.payload, state.cutCalc);
    },
    [updateCutCalc.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    [updatePressureLoss.pending.type]: (state: HydraulicsState) => {
    },
    [updatePressureLoss.fulfilled.type]: (state: HydraulicsState, action: PayloadAction<ResearchPressureBackType>) => {
      state.pressureCalc = pressureBackToFront(action.payload);
    },
    [updatePressureLoss.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },

    // hydraulic equipment

    [getHydEquipments.pending.type]: (state: HydraulicsState) => {
      state.isLoading = true;
    },
    [getHydEquipments.fulfilled.type]: (state: HydraulicsState, action: PayloadAction<HydEquipmentBackType[]>) => {
      state.equipments = action.payload.map(equipment => hydEquipmentToFront(equipment));
      state.equipments.sort((a, b) => a.bhaIndex - b.bhaIndex);
      state.isLoading = false;
    },
    [getHydEquipments.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },

    [createHydEquipment.pending.type]: (state: HydraulicsState) => {
      state.isLoading = true;
    },
    [createHydEquipment.fulfilled.type]: (state:HydraulicsState, { payload }: PayloadAction<HydEquipmentBackType>) => {
      state.isLoading = false;
      state.equipments.push(hydEquipmentToFront(payload));
    },
    [createHydEquipment.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = payload;
    },

    [deleteHydEquipment.pending.type]: (state: HydraulicsState) => {
      state.isLoading = true;
    },
    [deleteHydEquipment.fulfilled.type]: (state:HydraulicsState, { payload: id }: PayloadAction<string>) => {
      state.isLoading = false;
      const index = state.equipments.findIndex(eq => eq.itemUuid === id);
      if (index > -1) {
        state.equipments.splice(index, 1);
      }
    },
    [deleteHydEquipment.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = payload;
    },

    [calculateEcd.pending.type]: (state: HydraulicsState) => {
      state.allPageLoading = true;
    },
    [calculateEcd.fulfilled.type]: (state:HydraulicsState, { payload }: PayloadAction<CalculationEcdBackType>) => {
      state.allPageLoading = false;
      if (state.ecpCalc) {
        state.ecpCalc = {
          ...calculationEcpBackToFront(payload.result, state.ecpCalc)
        };
      }
    },
    [calculateEcd.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.allPageLoading = false;
      state.error = payload;
    },
    [calculateBit.pending.type]: (state: HydraulicsState) => {
      state.allPageLoading = true;
    },
    [calculateBit.fulfilled.type]: (state: HydraulicsState, { payload }: PayloadAction<CalculationBitBackType>) => {
      state.allPageLoading = false;
      if (state.bitCalc) {
        state.bitCalc = {
          ...calculationBitBackToFront(payload.result, state.bitCalc)
        };
      }
    },
    [calculateBit.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.allPageLoading = false;
      state.error = payload;
    },
    [calculateCut.pending.type]: (state: HydraulicsState) => {
      state.allPageLoading = true;
    },
    [calculateCut.fulfilled.type]: (state:HydraulicsState, { payload }: PayloadAction<CalculationCutBackType>) => {
      state.allPageLoading = false;
      if (state.cutCalc) {
        state.cutCalc = {
          ...calculationCutBackToFront(payload.result, state.cutCalc)
        };
      }
    },
    [calculateCut.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.allPageLoading = false;
      state.error = payload;
    },
    [calculatePressure.pending.type]: (state: HydraulicsState) => {
      state.allPageLoading = true;
    },
    [calculatePressure.fulfilled.type]: (state: HydraulicsState, { payload }: PayloadAction<CalculationPressureBackType>) => {
      state.allPageLoading = false;
      if (state.pressureCalc) {
        state.pressureCalc = {
          ...calculationPressBackToFront(payload.result, state.pressureCalc)
        };
      }
    },
    [calculatePressure.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.allPageLoading = false;
      state.error = payload;
    },
    [updateHydEquipment.pending.type]: (state: HydraulicsState) => {
      state.isLoading = true;
    },
    [updateHydEquipment.fulfilled.type]: (state: HydraulicsState, { payload }: PayloadAction<HydEquipmentBackType>) => {
      state.isLoading = false;
      const equipment = hydEquipmentToFront(payload);
      const index = state.equipments.findIndex(eq => eq.itemUuid === equipment.itemUuid);
      if (index > -1) {
        state.equipments[index] = equipment;
      }
    },
    [updateHydEquipment.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = payload;
    },

    [reorderHydEquipments.pending.type]: (state: HydraulicsState) => {
      state.isLoading = true;
    },
    [reorderHydEquipments.fulfilled.type]: (state: HydraulicsState, { payload }: PayloadAction<HydEquipmentBackType[]>) => {
      state.isLoading = false;
      const changedEquipments = payload.map(eq => hydEquipmentToFront(eq));
      changedEquipments.forEach(equipment => {
        const index = state.equipments.findIndex(eq => eq.itemUuid === equipment.itemUuid);
        if (index > -1) {
          state.equipments[index] = equipment;
        }
      });
      state.equipments.sort((a, b) => a.bhaIndex - b.bhaIndex);
    },
    [reorderHydEquipments.rejected.type]: (state: HydraulicsState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = payload;
    },
    [downloadReport.pending.type]: (state: HydraulicsState) => {
      state.reportLoading = true;
    },
    [downloadReport.fulfilled.type]: (state: HydraulicsState, { payload }: PayloadAction<ReportType>) => {
      state.reportLoading = false;
      saveAs(payload.report.data, `report_${payload.well_uuid}.xlsx`);
    },
    [downloadReport.rejected.type]: (state: HydraulicsState, action: PayloadAction<string>) => {
      state.reportLoading = false;
      state.error = action.payload;
    }
  }
});

export default hydraulicsSlice.reducer;
