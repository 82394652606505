// max количество треков на планшете
export const maxTracks = 10;

// max количество кривых на одном треке
export const maxTrackCurves = 4;

// толщина выделенной кривой
export const selectedWidth = 3;

// разница между датой начала и датой конца периода
export const dateDelta = 7200000;

// максимальный период для запроса мнемоник (24 часа)
export const maxPeriodDelta = 86400000;

// варианты цветов для линий
export const colors = [
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf'
];

// шаблон ГТИ
const gtiTemplate = [
  {
    charts: [
      { code: 'HKLD', name: 'Вес на крюке' },
      { code: 'wob', name: 'Нагрузка на долото' },
      { code: 'rop', name: 'Скорость бурения' }
    ]
  },
  {
    charts: [
      { code: 'table_torque', name: 'Крутящий момент ротора' },
      { code: 'rotor_speed', name: 'Обороты ротора' },
      { code: 'pipe_wrench_torque', name: 'Момент на ключе' },
      { code: 'akb_wrench_torque', name: 'Момент на ключе АКБ' }
    ]
  },
  {
    charts: [
      { code: 'mud_pressure', name: 'Давление на манифольде' },
      { code: 'circ_rate_in', name: 'Расход промывочной жидкости на входе' },
      { code: 'circ_rate_out', name: 'Расход промывочной жидкости на выходе' }
    ]
  },
  {
    charts: [
      { code: 'pump_cnt1', name: 'Ходы насоса 1' },
      { code: 'pump_cnt2', name: 'Ходы насоса 2' },
      { code: 'pump_cnt3', name: 'Ходы насоса 3' }
    ]
  },
  {
    charts: [
      { code: 'active_volume', name: 'Объем в активных емкостях' },
      { code: 'slugging_volume', name: 'Объем в доливных емкостях' },
      { code: 'reception_volume', name: 'Объем в приемных емкостях' }
    ]
  }
];

// шаблон MWD
const mwdTemplate = [
  {
    charts: [
      { code: 'zts_glubina', name: 'Глубина датчика телесистемы' },
      { code: 'zts_zenit', name: 'Зенитный угол телесистемы' },
      { code: 'zts_azimut', name: 'Азимут телесистемы' }
    ]
  },
  {
    charts: [
      { code: 'gk', name: 'Гамма-каротаж' },
      { code: 'zts_gk_v', name: 'Верхний датчик гамма-каротажа' },
      { code: 'zts_gk_n', name: 'Нижний датчик гамма-каротажа' }
    ]
  },
  {
    charts: [
      { code: 'zts_davl', name: 'Давление на телесистеме' },
      { code: 'zts_vk', name: 'Вибрации' },
      { code: 'zts_vnutr_davl', name: 'Внутренне давление телесистемы' },
      { code: 'zts_p_osev', name: 'Нагрузка на долото телесистемы' }
    ]
  },
  {
    charts: [
      { code: 'zts_upit', name: 'Напряжение телесистемы' },
      { code: 'zts_rnagr', name: 'Сопротивление нагрузки телесистемы' },
      { code: 'zts_temp', name: 'Температура телесистемы' }
    ]
  }
];

// dropbox с шаблонами треков
export const typeItems = [
  {
    label: 'ГТИ',
    value: 'GTI',
    template: gtiTemplate
  },
  {
    label: 'MWD',
    value: 'MWD',
    template: mwdTemplate
  },
  {
    label: 'LWD',
    value: 'LWD',
    template: [],
    disabled: true
  },
  {
    label: 'Новый',
    value: 'Custom',
    template: []
  }
];
