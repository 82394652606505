import { createContext } from 'react';

const initialState = {
  // wsClient: null,
  // connect: () => {},
  // disconnect: () => {},
  subscribe: () => {},
  unsubscribe: () => {}
};

type WSContextType = {
    // wsClient: Client | null
    // connect: any
    // disconnect: any
    subscribe: any
    unsubscribe: any
};

export default createContext<WSContextType>(initialState);
