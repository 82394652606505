import {
  TechOperationCreateBodyType, TechOperationCreateFormType,
  TechOperationResponseType,
  TechOperationType
} from './types';
import { TechOperationTypes } from '../../pages/project-documentation/pages/well-doc/helper';

export const convertTechOp = (item: TechOperationResponseType): TechOperationType => {
  const {
    techOperationId,
    wellboreId,
    tubularId,
    techOperationType,
    // techOperationTypeRusName,
    verticalSpacingFrom,
    verticalSpacingTo,
    assemblyId,
    turns,
    load,
    pumpFlow,
    speed,
    time
  } = item;

  return {
    id: techOperationId,
    wellboreId,
    tubularId,
    typeCode: techOperationType,
    typeName: TechOperationTypes.find(item => item.id === techOperationType)?.label || '-',
    tvdMinValue: verticalSpacingFrom,
    tvdMaxValue: verticalSpacingTo,
    assemblyId,
    n: turns,
    g: load,
    q: pumpFlow,
    v: speed,
    time
  };
};

export const convertTechops2Client = (data: TechOperationResponseType[]): TechOperationType[] => data.map(convertTechOp);

export const convertCreateTechOpsBody2Server = (body: TechOperationCreateFormType): TechOperationCreateBodyType => {
  const {
    wellboreId,
    tubularId,
    typeCode,
    tvdMinValue,
    tvdMaxValue,
    assemblyId,
    n,
    g,
    q,
    v,
    time
  } = body;

  return {
    wellboreId,
    tubularId,
    techOperationType: typeCode,
    verticalSpacingFrom: tvdMinValue,
    verticalSpacingTo: tvdMaxValue,
    assemblyId,
    turns: n,
    load: g,
    pumpFlow: q,
    speed: v,
    time
  };
};
