import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => (process.env.NODE_ENV === 'development'
    // ? getDefaultMiddleware().concat(logger)
    ? getDefaultMiddleware().concat()
    : getDefaultMiddleware())
});

export const setupStore = () => store;

export type RootStateType = ReturnType<typeof rootReducer>;

export type AppDispatchType = typeof store.dispatch;
