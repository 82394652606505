import React from 'react';
import { Badge } from 'primereact/badge';
import { useClassName } from '../../../utils/cn';

type IncidentContentProps = {
    wellName: string;
    payload: { incidentId: string, incidentTypeName: string } | null
};

const IncidentContent: React.FC<IncidentContentProps> = ({ payload, wellName }) => {
  const cn = useClassName('header-notification');

  return (
    <>
      <div className={cn('content-title')}>
        <Badge severity="danger" className="mr-2" />
        <h4>
                    Инцидент закрыт
        </h4>
      </div>
      <div className={cn('content-body')}>
                Инцидент <b>{payload?.incidentTypeName}</b> закрыт на скважине {wellName}
      </div>
    </>
  );
};

export default IncidentContent;