import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import * as breadcrumbApi from './api';
import { breadActions } from './slice';
import { convertFacilityBreadcrumb, convertWellBreadcrumb } from './converters';
import { buildFacilityBreadcrumb, buildWellBreadcrumb, WellBreadType } from './utils';

const { name } = options;

export const breadcrumbWell = createAsyncThunk(
  `${name}/breadcrumbWell`,
  async (options: { id: string, type: WellBreadType }, thunkApi) => {
    try {
      const { id, type } = options;
      const wellBreadcrumb = await breadcrumbApi.getBreadcrumbWell(id);
      const convertedData = convertWellBreadcrumb(wellBreadcrumb);

      thunkApi.dispatch(breadActions.originalWellBread(convertedData));
      const breads = buildWellBreadcrumb(
        convertedData,
        (thunkApi.getState() as any).models.breadcrumb.fromArea,
        type
      );

      // если скважина не привязана, то куста, месторождения и площади нету
      // из-за этого фильтруем от пустых значений
      return breads.filter(bread => !!bread.label);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const breadcrumbFacility = createAsyncThunk(
  `${name}/breadcrumbFacility`,
  async (id: string, thunkApi) => {
    try {
      const body = id.split('&&');
      const facilityBreadcrumb = await breadcrumbApi.getBreadcrumbFacility({
        куст_тип: body[0],
        куст_ид: body[1]
      });

      const converted = convertFacilityBreadcrumb({
        ...facilityBreadcrumb,
        facilityType: body[0],
        facilityId: body[1],
        куст_короткое_имя: facilityBreadcrumb['куст_короткое_имя']
      });

      return buildFacilityBreadcrumb(converted, (thunkApi.getState() as any).models.breadcrumb.fromArea);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);