import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useClassName } from 'utils/cn';
import { useTranslation } from 'react-i18next';
import './style.scss';

const NoMatch = () => {
  const cn = useClassName('no-match-page');
  const navigate = useNavigate();
  const { t } = useTranslation('common', { keyPrefix: 'page-404' });
  const toHomePage = () => { navigate('/'); };

  return (
    <div className={cn()}>
      <h2>404</h2>
      <div>
        {t('page-does-not-exists')}
      </div>
      <Button onClick={toHomePage}>
        {t('to-main-page')}
      </Button>
    </div>
  );
};

export default NoMatch;
