import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CalculationTorqueDragType, TorqueDragType } from './types';
import { calculateTorqueDrag, getTorqueDrag, updateTorqueDrag } from './actions';
import options from './options';

const { name } = options;

type TensionStateType = {
    isLoading: boolean;
    error: string;
    torqueDrag: null | TorqueDragType;
    calculation: CalculationTorqueDragType | null;
};

const initialState: TensionStateType = {
  isLoading: false,
  error: '',
  torqueDrag: null,
  calculation: null
};

export const tensionSlice = createSlice({
  name,
  initialState,
  reducers: {
    cleanCalculation(state) {
      state.calculation = null;
    }
  },
  extraReducers: {
    [getTorqueDrag.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getTorqueDrag.fulfilled.type]: (state, { payload }: PayloadAction<TorqueDragType[]>) => {
      state.isLoading = false;
      state.error = '';
      if (payload[0]) {
        state.torqueDrag = payload[0];
      }
    },
    [getTorqueDrag.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [updateTorqueDrag.pending.type]: (state) => {
    },
    [updateTorqueDrag.fulfilled.type]: (state, { payload }: PayloadAction<unknown>) => {
      state.error = '';
    },
    [updateTorqueDrag.rejected.type]: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    [calculateTorqueDrag.pending.type]: (state) => {
      state.isLoading = true;
    },
    [calculateTorqueDrag.fulfilled.type]: (state, { payload }: PayloadAction<CalculationTorqueDragType>) => {
      state.error = '';
      state.isLoading = false;
      state.calculation = payload;
    },
    [calculateTorqueDrag.rejected.type]: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});

export default tensionSlice.reducer;
