import { getUrl } from '../utils';
import options from './options';
import request from '../../utils/http/request';
import { IStratUnit, IStratUnitBasic, lithologyDictType } from './types';
import { IPagingSearch } from '../../interfaces';

const { serviceUrl, apiUrl } = options;

const ddApiUrl = getUrl(apiUrl);
const url = getUrl(serviceUrl);

// strat-unit-controller
export const getStratUnitsByWellUidApi = async (wellUid: string, page: number, pageSize: number): Promise<IPagingSearch<IStratUnit>> => request.get(ddApiUrl(`well/${wellUid}/unit?page=${page}&size=${pageSize}`));

export const createStratUnitApi = async (wellUid: string, body: any): Promise<[]> => request.post(url(`well/${wellUid}/unit`), body);

export const deleteStratUnitApi = async (wellUid: string, stratUnitUid: string): Promise<string> => {
  await request.delete(url(`well/${wellUid}/unit/${stratUnitUid}`));
  return stratUnitUid;
};

export const patchStratUnitApi = async (wellUid: string, stratUnitUid: string, body: IStratUnitBasic): Promise<[]> => request.patch(url(`well/${wellUid}/unit/${stratUnitUid}`), body);

// strat-unit-reference-controller
export const getStratUnitReferencesApi = async (): Promise<[]> => request.get(url('reference/unit'));

export const getStratUnitReferenceByUidApi = async (stratUnitUid: string): Promise<[]> => request.get(url(`reference/unit/${stratUnitUid}`));

export const createStratUnitReferenceApi = async (body: any): Promise<[]> => request.post(url('reference/unit'), body);

export const deleteStratUnitReferenceByUidApi = async (uid: string): Promise<string> => {
  await request.delete(url(`reference/unit/${uid}`));
  return uid;
};

export const patchStratUnitReferenceByUidApi = async (uid: string, body: any): Promise<[]> => request.patch(url(`reference/unit/${uid}`), body);

export const getStratUnitReferenceTypesApi = async (): Promise<[]> => request.get(url('reference/unit/type'));

// литология
export const getLithologyTypesApi = async (): Promise<lithologyDictType[]> => request.get(url('lithology'));
