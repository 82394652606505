import { useContext, useEffect, useRef } from 'react';
import { FormikProps, useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { useAppSelector } from 'store/hooks';
import { selectAuthState } from 'models/auth/selectors';
import { useTranslation } from 'react-i18next';
import { createContent } from '../../../components/Messages/Messages';
import { useClassName } from '../../../utils/cn';
import Auth from '../../../contexts/auth';
import { initValues, IValues, validationSchema } from './helper';

export default () => {
  const { t } = useTranslation('common', { keyPrefix: 'auth' });
  const cn = useClassName('sign-in-form');
  const message = useRef<any>(null);
  const { onLogin } = useContext(Auth);

  const { signInError, isLoading } = useAppSelector(selectAuthState);

  const formik: FormikProps<IValues> = useFormik<IValues>({
    initialValues: initValues,
    validationSchema,
    onSubmit: (values: IValues) => {
      onLogin(values);
    }
  });

  // использование Messages
  useEffect(() => {
    if (signInError) {
      message.current.show({
        severity: 'error',
        content: createContent(
          '',
          signInError
        ),
        sticky: true,
        closable: false
      });
    } else {
      message.current.clear();
    }
  }, [signInError]);

  return (
    <form className="form sign-in-form" onSubmit={formik.handleSubmit}>
      <h1>{t('auth')}</h1>
      <Messages ref={message} />
      <div className={cn('form-group')}>
        <label>
          {t('login')}
          <InputText
            id="username"
            name="username"
            className={formik.touched.username
                            && !!formik.errors.username ? 'inline-input p-invalid' : 'inline-input'}
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={isLoading}
          />
        </label>
        {
          formik.touched.username
                    && Boolean(formik.errors.username)
                    && <small className="p-error block">{t(formik.errors.username ?? '')}</small>
        }

      </div>
      <div className={cn('form-group')}>
        <label>
          {t('pas')}
          <Password
            id="password"
            name="password"
            className="inline-input"
            inputClassName={formik.touched.password && !!formik.errors.password ? 'p-invalid' : ''}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            feedback={false}
            toggleMask
            disabled={isLoading}
          />
        </label>
        {
          formik.touched.password
                    && Boolean(formik.errors.password)
                    && <small className="p-error block">{t(formik.errors.password ?? '')}</small>
        }

      </div>

      <div className={cn('button-group')}>
        <Button label={t('sign')} type="submit" disabled={!formik.isValid} loading={isLoading} />
        <a>{t('forget-pas')}</a>
      </div>
    </form>
  );
};
