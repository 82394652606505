import React from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'primereact/badge';

import { useClassName } from '../../../utils/cn';
import { NotificationPayloadType } from './index';

type TroubleContentPropsType = {
    wellName: string
    payload: NotificationPayloadType
};

export const TroubleContent: React.FC<TroubleContentPropsType> = ({ wellName, payload }) => {
  const cn = useClassName('header-notification');
  const { t } = useTranslation('common', { keyPrefix: 'header' });

  const getProbClass = (prob: number) => {
    if (prob > 0.5) {
      return cn('danger');
    } else {
      return '';
    }
  };

  const getTimeClass = (time: number) => {
    if (time <= 15) {
      return cn('danger');
    } else {
      return '';
    }
  };

  return (
    <>
      <h4 className={cn('content-title')}>
        <Badge severity="danger" className="mr-2" />
        {payload.trouble} {t('on-well')} {wellName}
      </h4>
      <div className={cn('content-body')}>
        <span style={{ color: 'var(--brand-primary)', fontWeight: 700 }}>{t('recommendations')}</span>
        {payload.recommend || t('no-recommend')}.
        {t('probability')}
        <span className={getProbClass(payload.prob)} style={{ fontWeight: 700 }}>{Math.floor(payload.prob * 100)}%</span>.
        {t('duration-time')}
        <span className={getTimeClass(payload.time)} style={{ fontWeight: 700 }}>{payload.time}</span>
        {t('minutes')}
      </div>
    </>
  );
};
