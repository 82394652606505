import { createAsyncThunk } from '@reduxjs/toolkit';
import * as tensionApi from './api';
import options from './options';
import { EditTorqueDragType } from './types';

const { name } = options;

export const getTorqueDrag = createAsyncThunk(
  `${name}/getTorqueDrag`,
  async (hydraulicId: string, thunkApi) => {
    try {
      return await tensionApi.getTorqueDragApi(hydraulicId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

type UpdateTorqueDragParams = {
    torqueId: string;
    body: EditTorqueDragType;
};

export const updateTorqueDrag = createAsyncThunk(
  `${name}/updateTorqueDrag`,
  async (params: UpdateTorqueDragParams, thunkApi) => {
    try {
      return await tensionApi.updateTorqueDragApi(params.torqueId, params.body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const calculateTorqueDrag = createAsyncThunk(
  `${name}/calculateTorqueDrag`,
  async (hydraulicId: string, thunkApi) => {
    try {
      return await tensionApi.calculateTorqueDragApi(hydraulicId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);