import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { NotificationType } from './types';
import { getNotificationsList } from './actions';
import { IPagingSearch } from '../../interfaces';
import { notifications2Client } from './converters';

const { name } = options;

type NotificationState = {
    isLoading: boolean
    notifications: NotificationType[],
    error: string
};

const initialState: NotificationState = {
  isLoading: false,
  notifications: [],
  error: ''
};

export const notificationSlice = createSlice({
  name,
  initialState,
  reducers: {
    addNewMessage(state, { payload }: PayloadAction<NotificationType>) {
      state.notifications.unshift(payload);
    }
  },
  extraReducers: {
    [getNotificationsList.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getNotificationsList.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<NotificationType>>) => {
      state.isLoading = false;
      state.notifications = notifications2Client(payload.content);
      state.error = '';
    },
    [getNotificationsList.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export default notificationSlice.reducer;
