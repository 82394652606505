import homeEn from './en/home.json';
import monitoringEn from './en/monitoring.json';
import commonEn from './en/common.json';
import widgetsEn from './en/widgets.json';
import projectDocEn from './en/projdoc.json';
import messagesEn from './en/messages.json';
import calculationsEn from './en/calculations.json';
import schedulerEn from './en/scheduler.json';
// ru
import homeRu from './ru/home.json';
import monitoringRu from './ru/monitoring.json';
import commonRu from './ru/common.json';
import widgetsRu from './ru/widgets.json';
import projectDocRu from './ru/projdoc.json';
import messagesRu from './ru/messages.json';
import calculationsRu from './ru/calculations.json';
import schedulerRu from './ru/scheduler.json';

export default {
  ru: {
    home: homeRu,
    monitoring: monitoringRu,
    common: commonRu,
    widgets: widgetsRu,
    projdoc: projectDocRu,
    messages: messagesRu,
    calculations: calculationsRu,
    scheduler: schedulerRu
  },
  en: {
    home: homeEn,
    monitoring: monitoringEn,
    common: commonEn,
    widgets: widgetsEn,
    projdoc: projectDocEn,
    messages: messagesEn,
    calculations: calculationsEn,
    scheduler: schedulerEn
  }
};
