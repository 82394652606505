const options = {
  name: 'trajectory',
  apiUrl: '/dd/trajectory',
  ddProxyUrl: '/dd-proxy/trajectory',
  serviceUrl: '/trajectory',
  calcUrl: '/calculations/calc_trajectory',
  proxyCalcUrl: '/dd-proxy/calculations/calc_trajectory',
  calcTrajectoryUrl: '/calc_trajectory'
};

export const convergenceFactorGraphColors = [
  '#4A0085',
  '#FF6B00',
  '#430000'
];

export const trajectoryGraphColors = [
  '#008850',
  '#0051CA',
  '#C22121',
  '#4A0085',
  '#FF6B00',
  '#57DB8A'
];

export default options;
