import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'primereact/button';

import { Lottie } from 'components/lottie/Lottie';
import { useClassName } from '../../utils/cn';
import animationData from './error-boundary.json';

type InfoErrorBoundaryPropsType = {
    errorMessage: string
    onHomeButtonClick: any
    onReloadButtonClick?: any
};

// const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData,
//     rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice'
//     }
// };

export const InfoErrorBoundary: React.FC<InfoErrorBoundaryPropsType> = ({
  errorMessage, onHomeButtonClick, onReloadButtonClick
}) => {
  const cn = useClassName('error-boundary');
  const { t } = useTranslation('common', { keyPrefix: 'error-boundary' });

  return (
    <div className={cn()}>
      <div className={cn('container')}>
        <h1>{t('fail-message')}</h1>
        <div>{errorMessage}</div>
        <div className={cn('animation')}>
          <Lottie
            animationData={animationData}
            height={243}
            width={282}
          />
        </div>
        <div className={cn('btn-group')}>
          <Button onClick={onHomeButtonClick} className="p-button-sm left">{t('to-main-page')}</Button>
          <Button onClick={onReloadButtonClick} className="p-button-sm">{t('reload')}</Button>
        </div>
      </div>
    </div>
  );
};
