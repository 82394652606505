import { getUrl } from '../utils';
import {
  CreateProjectReportBodyType, IsPublishedBodyType, ProjectReportResponseType, ProjectReportType
} from './types';
import request from '../../utils/http/request';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const createProjectReportApi = async (body: CreateProjectReportBodyType): Promise<ProjectReportResponseType> => request.post(url('createWellProjectReport'), body);

export const getProjectReportApi = async (wellId: string): Promise<ProjectReportType[]> => request.get(url(wellId));

export const isSectionPublishedApi = async (body: IsPublishedBodyType): Promise<boolean> => request.post(url('isPublishedByWellIdAndChapter'), body);